import { choicesFromEnum } from '../../@helpers/choices-from-enum';

export enum RefCurrency{
  EUR='EUR',
  GBP='GBP',
  CAD='CAD',
  AUD='AUD',
  CHF='CHF',
  USD='USD',
  CNY='CNY',
  JPY='JPY',
  BGN='BGN',
}

export const RefCurrencyChoices = choicesFromEnum(RefCurrency);