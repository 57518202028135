import { FileField, SimpleShowLayout, useGetOne, useRecordContext } from "react-admin";
import { AdminResourceName } from '../../resource-name.enum';

export const ShowLink = (props: any) => {
  const { data, isLoading, error } = useGetOne(AdminResourceName.USER_FILE, { id: props.record.senderFile.id })
  if (isLoading || error || !data) return null;
  // console.log('useGetOne=>data')
  // console.log(data)
  return (
    <SimpleShowLayout record={data}>
      <FileField label="" source="url" title="fileName" download="true"/>
    </SimpleShowLayout>
  )
}

export const FileData = (props: any) => {
  const record = useRecordContext();
  // console.log('FileData=>record')
  // console.log(record)
  if (!record || !record.senderFile) return (<span>Empty</span>);
  return (<ShowLink record={record}/>)

}