import {
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput
} from 'react-admin';
import { transformNumberToString } from '../../@helpers/transform-number-to-string';
import {
  DefaultDurations,
  ISavingPlan,
  SavingPlanType,
  SavingTypeChoices,
  validateSavingPlanForm
} from '../saving-plan';
import { useEffect, useState } from 'react';
import { AdminResourceName } from '../../resource-name.enum';
import { useFormContext } from 'react-hook-form';

export const transformFormData = ({
                                    title,
                                    assetId,
                                    yearPercentage,
                                    minimumAmount,
                                    duration,
                                    type,
                                    bonusPercent,
                                  }: ISavingPlan) => {
  const data = {
    title,
    assetId,
    yearPercentage,
    minimumAmount,
    ...(type === SavingPlanType.Flexible ? {} : { duration }),
    type,
    ...(type === SavingPlanType.Combo ? { bonusPercent } : {}),
  }
  return data;
}

const FormChildren = (props: any) => {
  const [type, setType] = useState(SavingPlanType.Limited)
  const { setValue } = useFormContext();
  const handleTypeChange = (event: any) => {
    setType(event.target.value);
    setValue('duration', DefaultDurations[event.target.value as SavingPlanType]);
  }
  useEffect(() => {
  }, [type])
  return (
    <>
      <SelectInput label="Type" source="type" choices={SavingTypeChoices} defaultValue={SavingTypeChoices[0].id}
                   onChange={handleTypeChange}/>
      <TextInput label="Title" name="title" source="title"/>
      <ReferenceInput source="assetId" reference={AdminResourceName.ASSET}>
        <AutocompleteInput name="assetId" helperText={false} label="Asset"
                           optionText={(choice: any) => `${choice.title}: ${choice.shortcode}`} optionValue="id"
                           filterToQuery={(value: any) => ({ title: value })}/>
      </ReferenceInput>
      <NumberInput label="Interest (APY)" source="yearPercentage" min={0} parse={transformNumberToString}/>
      <NumberInput label="Initial Bonus" source="bonusPercent" min={0} parse={transformNumberToString}
                   disabled={type !== SavingPlanType.Combo}/>
      <NumberInput label="Duration" source="duration" min={1} step={1}
                   disabled={type === SavingPlanType.Flexible}/>
      <NumberInput label="Minimum amount" source="minimumAmount" min={0} parse={transformNumberToString}/>
    </>
  )
}


export const CreateSavingForm = (props: any) => {
  return (
    <SimpleForm {...props} validate={validateSavingPlanForm} defaultValues={{ duration: 1 }}>
      <FormChildren/>
    </SimpleForm>
  )
}

export const SavingPlanCreate = () => {

  return (
    <Create transform={transformFormData} redirect="show">
      <CreateSavingForm/>
    </Create>
  )
}