import { Layout } from 'react-admin';
import { CustomMenu } from './main-menu';
import CustomAppBar from './app-bar';

const MyLayout = (props: any) => <Layout
    {...props}
    appBar={CustomAppBar}
    // sidebar={MySidebar}
    menu={CustomMenu}
/>;

export default MyLayout;
