import { Create, SimpleForm, TextInput, ValidateForm } from 'react-admin';
import TinyRichTextEditor from '../../@shared/tiny-rich-text-editor';

export const formValidator: ValidateForm = (data) => {
  const { title, content } = data
  const errors: Record<string, string> = {}
  if (!title) errors['title'] = `The title is required`;
  if (!content) errors['content'] = `The content is required`
  return errors;
}

export const NewsCreate = () => {
  return (
    <Create>
      <SimpleForm warnWhenUnsavedChanges validate={formValidator}>
        <TextInput label="News title" name="title" source="title"/>
        <TinyRichTextEditor name="content" source="content"/>
      </SimpleForm>
    </Create>
  )
}