import { BooleanInput, Create, NumberInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import { DeliveryMethodChoices } from "./deliveryMethodChoices";
import { formatDeliveryFee, parseDeliveryFee } from "./intercashDeliveryEdit";

export const IntercashDeliveryCreate = () => {
  return (
    <Create>
      <SimpleForm warnWhenUnsavedChanges>
        <SelectInput label="Delivery Method" name='deliveryMethod' source='deliveryMethod'
                     choices={DeliveryMethodChoices} defaultValue={DeliveryMethodChoices[0]}/>
        <TextInput label="Delivery title" source="title"/>
        <TextInput label="Delivery description" source="description"/>
        <NumberInput label="Delivery Fee" source="deliveryFee" min={0} defaultValue={0} format={formatDeliveryFee}
                     parse={parseDeliveryFee}/>
        <BooleanInput label="Is Enabled" source="isEnabled" defaultValue={true}/>
        {/*<TextInput label="icon" name='icon' source='icon'/>*/}
      </SimpleForm>
    </Create>
  )
}
