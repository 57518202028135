import { AuthTokenProvider } from './auth-token.provider';
import { createSession, enableTwoFA, get2faQrCode, getSessionPermission, validateSession } from './session.service';
import { UserIdentity } from 'react-admin';
import { UserProvider } from './user.provider';

const tokenProvider = new AuthTokenProvider();
const userProvider = new UserProvider();

const authProvider = {
  // authentication
  login: async (params: any) => {
    const { username, password, token2fa } = params;
    const { adminId, token, isTwoFactorAuthEnabled } = await createSession(username, password, token2fa);
    if (!isTwoFactorAuthEnabled) {
      tokenProvider.preLoginToken = token.accessToken;
      return Promise.reject({ adminId, isTwoFactorAuthEnabled, token: token.accessToken })
    }
    tokenProvider.token = token.accessToken;
    userProvider.user = { adminId, isTwoFactorAuthEnabled };
  },
  checkError: (error: Error) => {
    console.log('checkError');
    console.log(error);
    return Promise.resolve();
  },
  checkAuth: async (params: any) => {
    await validateSession(tokenProvider.token);
  },
  logout: async () => {
    tokenProvider.clear();
    userProvider.clear();
  },
  getIdentity: () => {
    const identity: UserIdentity = {
      id: userProvider.user,
    };

    return Promise.resolve(identity);
  },
  // authorization
  getPermissions: async () => {
    const role = await getSessionPermission(tokenProvider.token);
    return role.role;
  },

  getQRCode: async () => {
    return get2faQrCode(tokenProvider.preLoginToken);
  },

  enableTwoFA: async (data: { token: string }) => {
    const result = await enableTwoFA(tokenProvider.preLoginToken, data)
    tokenProvider.clear();
    tokenProvider.token = result.token.accessToken;
    return Promise.resolve(result.token.accessToken);
  },
};

export default authProvider;
