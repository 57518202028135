export enum AdminResourceName {
  AUTH = 'auth',
  ALL = 'all',
  ASSET = 'asset',
  ASSET_ALIAS = 'asset_alias',
  USER = 'user',
  BUSINESS_ACCOUNT = 'business_account',
  ROLE = 'role',
  ADMIN = 'admin',
  TRANSACTION_ALL = 'transaction/all',
  TRANSACTION_APPROVAL = 'transaction/approval',
  API_LOG = 'api_log',
  SAVING_PLAN = 'saving_plan',
  USER_SAVING = 'user_saving',
  BANNER = 'banner',
  APP_LISTS = 'app_lists',
  USER_DELETION = 'user_deletion',
  DOCUMENT = 'document',
  NEWS = 'news',
  LOCALES = 'locales',
  TRANSLATIONS = 'translations',
  TRANSLATIONS_IMPORT = 'translations/import',
  ICON = 'icon',
  INTERCASH_DELIVERY = 'intercash_delivery',
  USER_REPORT = 'users_report',
  BALANCES_REPORT = 'balances_report',
  USER_FILE = 'user_file',
  WALLET = 'wallet',
  CARD = 'card',
  CARD_SUSPEND = 'card/suspend',
  CARD_TRANSACTIONS = 'card/transactions',
  BALANCE = 'balance',
  COUNTRY = 'country',
  SYSTEM_SETTINGS = 'system_settings',
  FIREBLOCKS_BALANCE = 'fb_balance',
  IBAN = 'iban',
  REFERRAL_PROGRAM = 'referral_program',
  NOTIFICATION='notification',
  KRAKEN_BALANCE = 'kraken_balance',
  REFERRAL_PROGRAM_STATISTICS = 'referral_statistics',
  REFERRAL_PROGRAM_STATISTICS_TOTAL_AMOUNT = 'referral_statistics/rewards/total',
  LTV_GRADATION = 'ltv_gradation',
  CREDIT_ASSET = 'credit_asset',
  CREDIT_PERIOD = 'credit_period',
  CREDIT_PLAN = 'credit_plan',
  //sub-resources
  CREATE_ACCRUAL_TRANSACTION = 'create_accrual_transaction',
  HANDLE_APPROVAL_TRANSACTION = 'handle_transaction',
  HANDLE_USER_DELETION = 'handle_user_deletion',
  BALANCE_RESERVE = 'balance_reserve',
  REFERRAL_PROGRAM_SOCIAL_LINKS = 'referral_program_social_links',
}

export const ADMIN_PREFIX = 'admin-api';
