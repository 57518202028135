import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  List,
  Pagination,
  TextField,
} from 'react-admin';
import { AssetIcon } from './assetIcon';
import { AssetService } from './assetService';
import { AliasServices } from '../asset-alias';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { ListActions } from '../list-actions';


export const AssetList = () => {
  return (
    <List
      actions={<ListActions createButton={<CreateButton/>}/>}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id"/>
        <TextField source="title"/>
        <TextField source="shortcode"/>
        <TextField source="contractAddress"/>
        <TextField source="baseCurrency"/>
        <TextField source="decimals"/>
        {
          AliasServices.map(service => {
            return <AssetService key={service.id} label={service.name} reference="asset_alias" target="assetId"
                                 filterTarget="serviceName"
                                 filterValue={service.id} textField="serviceCode"/>
          })
        }
        <TextField source="color"/>
        <AssetIcon label="Icon"/>
        <TextField source="assetType"/>
        <BooleanField source="isDarkText"/>
        <TextField source="addressType"/>
        <TextField source="blockExplorer"/>
        <BooleanField source="isIbanSupported"/>
        <DateField source="createdAt"/>
        <DateField source="updatedAt"/>
      </Datagrid>
    </List>
  );
}