import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';


export const BalanceReserveShow=(props:any)=>{
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField label="Reserve id" source="id"/>
        <TextField label="Reserve code" source="code"/>
        <TextField label="Reserve description" source="description"/>
        <TextField label="Reserve value" source="value"/>
        <DateField label="Created at" source="createdAt"/>
        <DateField label="Updated at" source="updatedAt"/>
      </SimpleShowLayout>
    </Show>
  );
}