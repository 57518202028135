import {
  CreateButton,
  DateField,
  List,
  Pagination,
  ShowButton,
  TextField,
  usePermissions,
  FunctionField,
} from 'react-admin';
import { ROWS_PER_PAGE } from '../../pagination/pagination.const';
import { GridComponent } from '../../grid/grid-component';
import { ListActions } from '../../list-actions';
import { LtvGradation } from '../interfaces';

const LtvListFields =[
  <TextField key="id" source="id" />,
  <TextField key="type" source="type" />,
  <FunctionField key="minValue" label="Minimal value" render={(record:LtvGradation)=>{return `${record.minValue}%`}}/>,
  <FunctionField key="maxValue" label="Maximal value" render={(record:LtvGradation)=>{return `${record.maxValue}%`}}/>,
  <DateField key="createdAt" source="createdAt" showTime/>,
  <ShowButton key="showBtn" />,
]

export const LtvList = ()=>{
  const { isLoading } = usePermissions();
  if (isLoading) return null;
  return(
    <List
      actions={<ListActions createButton={<CreateButton/>} />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
    >
      <GridComponent bulkActionButtons={false} gridChildren={LtvListFields} />
    </List>
  )
}