import { Button, useListContext } from 'react-admin';

export const FilterButton = () => {
  const { showFilter } = useListContext();
  return (
    <Button
      size="small"
      color="primary"
      label="Filter"
      // @ts-ignore
      onClick={() => showFilter("main")}
    />
  );
};