import { DateField, ImageField, Show, SimpleShowLayout, TextField, useRecordContext } from "react-admin";
import { FileDescription } from "./file-description";

export const BannerTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>"Banner: {record.id}"</span>;
}

export const BannerShow = () => {
  return (<Show title={<BannerTitle/>}>
    <SimpleShowLayout>
      <TextField label="Visualization position" source="visualisationPosition"/>
      <TextField source="id"/>
      <TextField source="bannerName"/>
      <TextField source="fileName"/>
      <DateField source="createdAt"/>
      <DateField source="updatedAt"/>
      <ImageField source="bannerUrl" title="banner"/>
      <FileDescription/>
    </SimpleShowLayout>
  </Show>)
}