import { Edit, SimpleForm, TextInput } from "react-admin";
import TinyRichTextEditor from '../../@shared/tiny-rich-text-editor';
import { formValidator } from './news-create';

export const NewsEdit = () => {
  return (
    <Edit>
      <SimpleForm warnWhenUnsavedChanges validate={formValidator}>
        <TextInput label="News Title" name='title' source='title'/>
        <TinyRichTextEditor name='content' source='content' />
      </SimpleForm>
    </Edit>
  )
}
