import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext
} from 'react-admin';
import { ISavingPlan, ReturnButton, SavingPlanType } from '../saving-plan';

export const SavingPlanTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>"Saving Plan: {record.title} : {record.id}"</span>;
}

export const SavingPlanShow = () => {
  return (
    <Show title={<SavingPlanTitle/>}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <TextField label="Title" source="title"/>
        <TextField label="Initial Bonus" source="bonusPercent"/>
        <TextField label="Interest (APY)" source="yearPercentage"/>
        <FunctionField label="Duration"
                       render={(record: ISavingPlan) => (record.type === SavingPlanType.Flexible ? 'Flexible' : record.duration)}/>
        <TextField source="minimumAmount"/>
        <ReferenceField label="Asset" source="assetId" reference="asset" link="show">
          <TextField source="shortcode"/>
        </ReferenceField>
        <TextField label="Type" source="type"/>
        <DateField source="createdAt" showTime/>
        <DateField source="updatedAt" showTime/>
        <ReturnButton/>
      </SimpleShowLayout>

    </Show>
  )
}