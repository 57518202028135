import { DateField, List, Pagination, ReferenceField, ShowButton, TextField } from 'react-admin';
import { ListActions } from '../list-actions';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { GridComponent } from '../grid/grid-component';
import { AdminResourceName } from '../../resource-name.enum';
import { FilterForm } from './filter-form';
import { Filter, FilterButton } from '../filter';


export const ibanFields = [
  <TextField key='id' source="id"/>,
  <TextField key='iban' source="iban"/>,
  <TextField key='state' source="state"/>,
  <ReferenceField key='account' label="account" source="accountId" reference={AdminResourceName.BUSINESS_ACCOUNT} link="show">
    <TextField source="accountNumber" />
  </ReferenceField>,
  <DateField key='createdAt' label="Created date" source="createdAt" showTime/>,
  <ShowButton key='button'/>,
]

export const IbanList =()=>{
  return(
    <List
      actions={<ListActions  filterButton={<FilterButton/>} form={<Filter formFields={FilterForm} />}/>}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <GridComponent gridChildren={ibanFields}  bulkActionButtons={false}/>
    </List>
  )
}