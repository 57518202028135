import {
  BooleanInput,
  DateInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { AdminTitle } from '../admin';
import { AdminRole } from '../role';
import { AdminResourceName } from '../../resource-name.enum';
import { CustomToolbar } from '../toolbar';

export interface Role {
  id: string;
  description: string;
}

const TwoFactorBooleanField = (props: any) => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <BooleanInput {...props} name={props.source} defaultValue={record.isTwoFactorAuthEnabled}
                  disabled={!record.isTwoFactorAuthEnabled}/>
  )
}

export const AdminEdit = () => {
  const { permissions } = usePermissions();
  return (
    <Edit title={<AdminTitle/>}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<CustomToolbar saveButton={true}/>}>
        <TextInput name="id" disabled source="id"/>
        <TextInput name="email" source="email"/>
        <TextInput name="password" label="Password" source="password" disabled={permissions !== AdminRole.SUPER_ADMIN}/>
        <ReferenceInput label="Role" source="roleId" reference={AdminResourceName.ROLE}>
          <SelectInput name="roleId" optionText="roleType" optionValue="id"
                       disabled={permissions !== AdminRole.SUPER_ADMIN}/>
        </ReferenceInput>
        {permissions === AdminRole.SUPER_ADMIN &&
        <TwoFactorBooleanField label="Two factor auth" source="isTwoFactorAuthEnabled"/>}
        <DateInput name="createdAt" source="createdAt"/>
        <DateInput name="updatedAt" source="updatedAt"/>
      </SimpleForm>
    </Edit>
  )
}