import { Form, SelectInput, TextInput, useRecordContext, useRedirect, useUpdate } from 'react-admin';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { TransactionSubStatuses } from './transaction-sub-status.enum';
import { SubmitButton } from '../user';
import { AdminResourceName } from '../../resource-name.enum';

interface ServerError {
  statusCode: '',
  message: '',
  body: { path: '', timestamp: '', statusCode: '', message: '' }
}

const emptyError: ServerError = {
  statusCode: '',
  message: '',
  body: { path: '', timestamp: '', statusCode: '', message: '' }
}

export function HandleTransaction(props: any) {
  const record = useRecordContext();
  const redirect = useRedirect()
  const [showDialog, setShowDialog] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState(emptyError);
  const [update] = useUpdate(AdminResourceName.TRANSACTION_APPROVAL, undefined, {
    onSuccess: (data) => {
      setShowDialog(false);
      redirect('show', AdminResourceName.TRANSACTION_ALL, record.id, data)
    }, onError: (error: ServerError) => {
      setShowError(true);
      setErrorText(error);
    }
  });
  const handleClick = () => {
    setShowDialog(true);
    setShowError(false);
    setErrorText(emptyError);
  };

  const handleCloseClick = () => {
   setShowDialog(false)
  };

  const handleSubmit = async (values: any) => {
    // console.log('handleSubmit=>values')
    // console.log(values)
    const dataToUpdate = {
      payload: {
        txSubStatus: values.payload.txSubStatus,
        decisionReason: values.payload.decisionReason
      }
    }
    await update(
      AdminResourceName.TRANSACTION_APPROVAL,
      {
        id: record.id,
        data: dataToUpdate,
        previousData: record,
      }
    );


  }

  return (<>
      <Button onClick={handleClick}>
        <span>Make Decision</span>
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        // onClose={handleCloseClick}
        aria-label="Handle Transaction"
      >
        {!showError && <DialogTitle>Update Transaction</DialogTitle>}
        {showError && <DialogTitle>Update Transaction Error</DialogTitle>}
        <Form onSubmit={handleSubmit}>
          {!showError && <DialogContent>
              <SelectInput label="New Status" helperText={false} source="payload.txSubStatus" name="payload.txSubStatus"
                           choices={TransactionSubStatuses}/>
              <TextInput
                  label="Decision reason"
                  source="payload.decisionReason"
                  name="payload.decisionReason"
                  fullWidth
              />
          </DialogContent>}
          {showError && <DialogContent>
              <p key="statusCode"><span>status code:{errorText.body.statusCode}</span></p>
              <p key="message"><span>message: {errorText.body.message}</span></p>
              <p key="path"><span>path: {errorText.body.path}</span></p>
              <p key="timestamp"><span>timestamp: {errorText.body.timestamp}</span></p>
          </DialogContent>}
          <DialogActions>
            <Button
              onClick={handleCloseClick}
            >
              {!showError && <span>Cancel</span>}
              {showError && <span>Close</span>}
            </Button>
            {!showError && <SubmitButton>Save</SubmitButton>}
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}