import { AutocompleteInput, ReferenceInput, TextInput, useListContext } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from 'react';
import { AdminResourceName } from '../../resource-name.enum';

const formDefaultValues = {
  id: '',
  response: '',
  method: '',
  url: '',
  userId: '',
};

export const ApiLogFilterForm = () => {
  const { displayedFilters, filterValues, setFilters, hideFilter } = useListContext();

  const form = useForm({
    defaultValues: filterValues,
  });
  useEffect(() => {
    // reset the entire form after component mount or form defaultValues is ready
    form.reset(formDefaultValues);
  }, [form.reset]);

  if (!displayedFilters.main) return null;

  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      // @ts-ignore
      setFilters(values);
    } else {
      hideFilter('main');
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    // @ts-ignore
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="flex-end" mb={1}>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="id"
              label="Log Id"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="response"
              label="Response contains"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <TextInput label="Method" helperText={false} source="method" />
          </Box>
          <Box component="span" mr={2}>
            <TextInput label="Url" helperText={false} source="url" />
          </Box>
          <Box component="span" mr={2}>
            <ReferenceInput name="User" source="userId" reference={AdminResourceName.USER}>
              <AutocompleteInput helperText={false} label="User" optionText="email" optionValue="id" />
            </ReferenceInput>
          </Box>
          <Box component="span" mr={2} mb={1.5}>
            <Button variant="outlined" color="primary" type="submit">
              Filter
            </Button>
          </Box>
          <Box component="span" mb={1.5}>
            <Button variant="outlined" onClick={resetFilter}>
              Close
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};
