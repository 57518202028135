import {
  CreateButton,
  DateField,
  List,
  ReferenceField,
  TextField,
  EditButton,
  usePermissions,
  FunctionField,
  useRedirect,
  ShowButton,
  Pagination,
  useRecordContext,
  useGetOne,
} from 'react-admin';
import { Button } from '@mui/material';
import { TransactionFilterButton, TransactionFilterForm } from '../transaction';
import { AdminResourceName } from '../../resource-name.enum';
import { RoleAction, RolePermissions } from '../role';
import {
  CreditCollateralTypes,
  CreditTypes,
  FiatTransactionType,
  ITransactionListModel,
  ITransactionModel
} from './domain/transaction.model';
import { fullName, getProviderNumber } from '../../@helpers/wallet.helpers';
import { owner } from '../../@helpers/account.helpers';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { GridComponent } from '../grid/grid-component';
import { ListActions } from '../list-actions';
import { config } from '../../config';

export function receiverName(record: ITransactionModel) {
  const { receiverId, payload } = record;
  if (receiverId) {
    return (
      <ReferenceField label="Receiver" source="receiverId" reference={AdminResourceName.BUSINESS_ACCOUNT} link="show">
        <FunctionField render={(record: any) => fullName(owner(record))}/>
      </ReferenceField>)
  }
  if (payload && payload?.order?.transactionType === FiatTransactionType.Payout) {
    return payload?.order?.payee?.individual?.firstName ? <TextField label="Receiver"
                                                                     record={{ name: [payload?.order?.payee?.individual?.firstName, payload?.order?.payee?.individual?.lastName].join(' ') }}
                                                                     source="name"/> : null;
  }
  return null
}

const DataFromWalletField = (props: any) => {
  const redirect = useRedirect();
  const { data, isLoading } = useGetOne(AdminResourceName.WALLET, {
    id: props.source,
    meta: {
      [props.source]: props.value
    },
  })
  const handleClick = () => {
    redirect('show', AdminResourceName.WALLET, data.id, data);
  }
  if (!data) return null;
  if (isLoading) return (<span>loading...</span>)
  return (<Button onClick={handleClick} children={<span>{getProviderNumber(data)}</span>}/>)
}

export const TransactionAddressField = (props: any) => {
  const record = useRecordContext();
  if (!record) return null;
  if ((!record.receiverId && props.source === 'destination') || (!record.senderId && props.source === 'source')) return (
    <span>{record[props.source]}</span>)
  return (<DataFromWalletField source="address" value={record[props.source]}/>)
}

export const AmountField = (props: any) => {
  const record = useRecordContext();
  const amount = record?.sign === '-' ? `${[record.sign, record.amount].join('')} ${record.asset}` : `${record.amount} ${record.asset}`
  return (
    <TextField {...props} record={{ amount }} source="amount"/>
  )
}

export const TransactionListButton = (props: any) => {
  const record = useRecordContext();
  const { id, transactionId, ...rest } = record
  const { btnType, resource, ...restProps } = props;
  if (btnType === 'edit') {
    return (<EditButton {...restProps} key="edit" resource={resource}
                        record={{ id: transactionId, ...rest }}/>)
  }
  return (<ShowButton {...restProps} key="show" resource={resource}
                      record={{ id: transactionId, ...rest }}/>)
}

export const SourceDestDataField = (props: any) => {
  const record = useRecordContext();
  if (!record) return null;
  if (record.sign === '-') {
    return (<DataFromWalletField {...props} source="address" value={record.source}/>)
  }
  if (record.sign === '+') {
    return (<DataFromWalletField {...props} source="address" value={record.destination}/>)
  }
  return null;
}

const TransactionListFields = (isCreateTransactionAllowed: boolean) => {
  const fields = [
    <TextField key="id" label="id" source="transactionId"/>,
    <FunctionField key="type" label="type" render={(record: ITransactionListModel) => {
      switch (record.type) {
        case 'TRANSFER': {
          return record.sign === '-' ? 'OUTGOING' : 'INCOMING'
        }
        case 'CREDIT': {
          if (CreditTypes.includes(record.payload?.type)) {
            return 'CREDIT';
          }
          if (CreditCollateralTypes.includes(record.payload?.type)) {
            return 'COLLATERAL';
          }
          return record.type
        }
        default: {
          return record.type
        }
      }
    }}/>,
    <AmountField key="amount" label="Amount"/>,
    <SourceDestDataField key="account" label="Account"/>,
    <ReferenceField key="user" label="User" source="userId" reference={AdminResourceName.BUSINESS_ACCOUNT}
                    link="show">
      <FunctionField render={(record: any) => fullName(owner(record))}/>
    </ReferenceField>,
    <TextField key="provider" source="provider"/>,
    <TextField key="state" source="state"/>,
    <DateField key="createdAt" source="createdAt" showTime/>,
    <TransactionListButton key="showTx" btnType="show" resource={AdminResourceName.TRANSACTION_ALL}/>
  ];
  if (isCreateTransactionAllowed) {
    fields.push(<TransactionListButton key="edit" btnType="edit" resource={AdminResourceName.TRANSACTION_ALL}/>)
  }
  return fields
}

export const TransactionList = () => {
  const { isLoading, permissions } = usePermissions();
  if (isLoading) return null;
  const createAccrualTransactionPermission = !!(RolePermissions[permissions].find(({ action, resource }) => {
    return (resource === AdminResourceName.CREATE_ACCRUAL_TRANSACTION && action === RoleAction.CREATE) ||
      (resource === AdminResourceName.ALL &&
        (action === RoleAction.CREATE || action === RoleAction.ALL)
      )
  }));
  return (
    <List
      actions={<ListActions filterButton={<TransactionFilterButton/>}
                            createButton={createAccrualTransactionPermission && <CreateButton/>}
                            form={<TransactionFilterForm/>} maxExportResults={config.EXPORT_LIMIT}/>}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}
    >
      <GridComponent bulkActionButtons={false}
                     gridChildren={TransactionListFields(createAccrualTransactionPermission)}/>
    </List>
  )
}
