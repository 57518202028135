import {
  ArrayField,
  Datagrid,
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext
} from 'react-admin';

export const ReferralProgramTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>"Referral program: {record.title}"</span>;
};

export const ReferralProgramShow =()=>{

  return (<Show
    title={<ReferralProgramTitle/>}
  >
    <SimpleShowLayout>
      <TextField source="id"/>
      <TextField source="title"/>
      <TextField source="description"/>
      <TextField source="state"/>
      <TextField source="userTypeAffected"/>
      <TextField source="accessibilityAsset"/>
      <TextField source="accessibilityAmount"/>
      <TextField source="maxActivationsAllowed"/>
      <ArrayField source="rewardAssetChoices">
        <Datagrid bulkActionButtons={false}>
          <TextField source="rewardAsset"/>
          <TextField source="rewardFollowerAmount"/>
          <TextField source="rewardOwnerAmount"/>
        </Datagrid>
      </ArrayField>
      <TextField source="rewardTriggerAsset"/>
      <TextField source="rewardTriggerAmount"/>
      <DateField label="Start Date" source="startDateAt" showTime/>
      <DateField label="Created At" source="createdAt" showTime/>
      <DateField label="Updated At" source="updatedAt" showTime/>
    </SimpleShowLayout>
  </Show>)
}