import { choicesFromEnum } from '../../@helpers/choices-from-enum';

export enum DocumentType {
  termsAndConditions = 'Terms & Conditions',
  privacyPolicy = 'Privacy Policy',
  savingsTermsConditions = 'Savings Terms & Conditions',
  feeSchedule = 'Fee Schedule',
  referralProgram='Referral Terms & Conditions',
  creditTermsAndConditions = 'Credit Terms & Conditions',
}

export const DocumentSystemCode = {
  [DocumentType.termsAndConditions]:'DOCUMENT_LINK_TERMS_AND_CONDITIONS',
  [DocumentType.privacyPolicy]:'DOCUMENT_LINK_PRIVACY_POLICY',
  [DocumentType.savingsTermsConditions]:'DOCUMENT_LINK_SAVING_TERMS_AND_CONDITIONS',
  [DocumentType.feeSchedule]:'DOCUMENT_LINK_FEE_SCHEDULE',
  [DocumentType.referralProgram]:'DOCUMENT_LINK_REFERRAL_PROGRAM_TERMS',
  [DocumentType.creditTermsAndConditions]:'DOCUMENT_LINK_CREDIT_TERMS_AND_CONDITIONS',
} as const;


export const DocumentTypeChoices = choicesFromEnum(DocumentType);