import { FieldTypes } from '../../filter';
import { AdminResourceName } from '../../../resource-name.enum';

export const FilterForm = {
  id: { type: FieldTypes.textInput, name: 'id', label: 'Credit ID' },
  collateralWalletId: { type: FieldTypes.textInput, name: 'collateralWalletId', label: 'Collateral ID' },
  userNameOrEmail: { type: FieldTypes.textInput, name: 'userNameOrEmail', label: 'User name/Email' },
  collateralCurrency: {
    type: FieldTypes.autoCompleteInput,
    name: 'collateralCurrency',
    label: 'Collateral Currency',
    reference: AdminResourceName.ASSET,
    source: 'shortcode',
    perPage: 100,
    filter: {},
    filterToQuery: (value: any) => ({ shortcode: value }),
    optionText: 'shortcode',
    optionValue: 'shortcode',
  },
  creditCurrency: {
    type: FieldTypes.autoCompleteInput,
    name: 'creditCurrency',
    label: 'Credit Currency',
    reference: AdminResourceName.ASSET,
    source: 'shortcode',
    perPage: 100,
    filter: {},
    filterToQuery: (value: any) => ({ shortcode: value }),
    optionText: 'shortcode',
    optionValue: 'shortcode',
  },
  interestApr: { type: FieldTypes.textInput, name: 'interestApr', source: 'interestApr', label: 'Interest APR%' },
  daysLeft: { type: FieldTypes.textInput, name: 'daysLeft', label: 'Days left' },
  startDate: { type: FieldTypes.dateInput, name: 'startDate', label: 'Start date' },
  maturityDate: { type: FieldTypes.dateInput, name: 'maturityDate', label: 'Maturity date' },
}