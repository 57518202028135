import { DateInput, DeleteButton, Edit, SaveButton, SimpleForm, TextInput, useRecordContext } from 'react-admin';
import { Toolbar } from '@mui/material';

export const TranslationTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>"Translation : {record.key} : {record.localeCode}"</span>;
}

export const TranslationEditToolBar = (props: any) => {
  return (
    <Toolbar {...props} sx={{ justifyContent: 'space-between' }}>
      <SaveButton/>
      <DeleteButton/>
    </Toolbar>
  )
}

export const TranslationEdit = (props: any) => {
  return (
    <Edit title={<TranslationTitle/>} mutationMode="pessimistic">
      <SimpleForm toolbar={<TranslationEditToolBar/>}>
        <TextInput key="key" label="Key" source="key" multiline={true}/>
        <TextInput key="text" label="Text" source="text"/>
        <TextInput key="platform" label="Platform" source="platform" disabled/>
        <TextInput key="locale" label="Locale" source="localeCode" disabled/>
        <DateInput name="createdAt" source="createdAt" disabled/>
        <DateInput name="updatedAt" source="updatedAt" disabled/>
      </SimpleForm>
    </Edit>
  );
};
