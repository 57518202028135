import { SavingPlanType } from './interfaces';

export const DefaultDurations = {
  [SavingPlanType.Limited]: 1,
  [SavingPlanType.Flexible]: 0,
  [SavingPlanType.Combo]: 240,
};

export const SavingPlanTypeTextAlias = {
  [SavingPlanType.Combo]: 'Combo',
  [SavingPlanType.Flexible]: 'Flexible',
  [SavingPlanType.Limited]: 'Common'
} as const

export const SavingTypeChoices = Object.values(SavingPlanType).map((key) => ({
  id: key,
  name: SavingPlanTypeTextAlias[key]
}))