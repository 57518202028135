import { Menu, MenuItemLink, useResourceDefinitions } from 'react-admin';
import DefaultIcon from '@mui/icons-material/ViewList';
import { AdminResource, CREDIT_RESOURCES } from '../resources';
import SubMenu from './sub-menu.component';
import { useLocation, matchRoutes } from 'react-router-dom';

export const CustomMenu = (props: any) => {
  const resources = useResourceDefinitions();
  // const [open] = useSidebarState();
  const location = useLocation();
  const creditSubmenuNames = CREDIT_RESOURCES.map(({ name }) => name);
  const matchCreditRoutes = matchRoutes(creditSubmenuNames.map((name) => ({ path: `/${name}/*` })), location);
  return (
    <Menu {...props}>
      {Object.values(resources).filter((res) => !creditSubmenuNames.includes(res.name)).map((resource: AdminResource) => (
        <MenuItemLink
          key={resource.name}
          to={{
            pathname: resource.name,
            search: 'filter=%7B%7D',
          }}
          primaryText={resource.options?.label || resource.name}
          leftIcon={resource.icon ? <resource.icon className={'menu-icon'}/> : <DefaultIcon/>}
          onClick={props.onMenuClick}
          className={resource.options?.className || ''}
          // sidebarIsOpen={open}
        />

      ))}
      <SubMenu {...props} primaryText="Credits" leftIcon={<DefaultIcon/>}
               isDropdownOpen={!!matchCreditRoutes}>
        {CREDIT_RESOURCES.map(resource => (
          <MenuItemLink
            key={resource.name}
            to={{
              pathname: resource.name,
            }}
            primaryText={resource.options?.label || resource.name}
            leftIcon={resource.icon ? <resource.icon className={'menu-icon'}/> : <DefaultIcon/>}
            onClick={props.onMenuClick}
            className={resource.options?.className || ''}
            // sidebarIsOpen={open}
          />
        ))}
      </SubMenu>
    </Menu>
  );
};
