import {
  BooleanField,
  CreateButton,
  Datagrid,
  EditButton,
  EmailField,
  List,
  ReferenceField,
  SimpleShowLayout,
  TextField,
  usePermissions
} from 'react-admin';
import { AdminRole } from '../role';
import { AdminResourceName } from '../../resource-name.enum';
import { ListActions } from '../list-actions';

const RoleField = ({ label, source, reference }: any) => {
  return (
    <ReferenceField label={label} source={source} reference={reference} link={'show'}>
      <SimpleShowLayout>
        <TextField label="Role" source="roleType"/>
        <TextField source="description"/>
      </SimpleShowLayout>
    </ReferenceField>
  )
}


export const AdminList = () => {
  const { permissions } = usePermissions();
  return (
    <List
      actions={<ListActions createButton={<CreateButton/>}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id"/>
        <EmailField source="email"/>
        {permissions === AdminRole.SUPER_ADMIN &&
        <RoleField label="Role data" source="roleId" reference={AdminResourceName.ROLE}/>
        }
        {permissions === AdminRole.SUPER_ADMIN &&
        <BooleanField label="Two factor auth" source="isTwoFactorAuthEnabled"/>
        }
        <TextField source="createdAt"/>
        <TextField source="updatedAt"/>
        <EditButton/>
      </Datagrid>
    </List>
  )
}