import { DateField, ReferenceField, Show, SimpleShowLayout, TextField } from "react-admin"
import { AdminResourceName } from '../../resource-name.enum'

export const ApiLogShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id"/>
        <TextField source="method"/>
        <TextField source="url"/>
        <TextField source="queryString"/>
        <TextField source="body"/>
        <TextField source="headers"/>
        <TextField source="contentType"/>
        <TextField source="response"/>
        <TextField source="status"/>
        {/*<TextField source="userId"/>*/}
        <ReferenceField label="User" source="userId" reference={AdminResourceName.USER}>
          <TextField source="email"/>
        </ReferenceField>
        <ReferenceField label="Admin" source="userId" reference="admin">
          <TextField source="email"/>
        </ReferenceField>
        <TextField source="ip"/>
        <TextField source="duration"/>
        <DateField source="createdAt" showTime/>
        <DateField source="updatedAt" showTime/>
      </SimpleShowLayout>
    </Show>
  )
}