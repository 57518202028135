import { choicesFromEnum } from "../../@helpers/choices-from-enum";

enum KycStatusType {
  unverified = 'UNVERIFIED',
  pending = 'PENDING',
  approved = 'APPROVED',
  rejected = 'REJECTED',
}

const KycStatusTypes = choicesFromEnum(KycStatusType);

const InternalKycStatusEditChoices = [...Object.values(KycStatusType).filter((value)=>value!==KycStatusType.unverified)].map((value)=>{
  return {id:value, name: value}
})

const BooleanStateTypes = choicesFromEnum({false:'false',true:'true'})

export { KycStatusTypes, KycStatusType, BooleanStateTypes,InternalKycStatusEditChoices }