import {
  BooleanField,
  DateField,
  DateInput,
  NullableBooleanInput,
  TextField,
  TextInput,
  DateTimeInput,
  SelectInput, AutocompleteInput, ReferenceInput,
} from 'react-admin';
import { Box, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export enum FieldTypes {
  text = 'text',
  boolean = 'boolean',
  date = 'date',
  textInput = 'textInput',
  booleanInput = 'booleanInput',
  dateInput = 'dateInput',
  dateTimeInput = 'dateTimeInput',
  selectInput = 'selectInput',
  autoCompleteInput = 'autoCompleteInput',
}

export interface IFieldOption {
  type: FieldTypes;
  choices?: { id: string, name: string }[];
  label?: string;
}

export interface IFormFields {
  [key: string]: IFieldOption;
}

export const FieldComponent = {
  text: (props: any) => <TextField {...props} source={props.source}/>,
  boolean: (props: any) => (
    <BooleanField {...props} source={props.source}/>
  ),
  date: (props: any) => <DateField {...props} showTime/>,
  textInput: (props: any) => (
    <TextInput
      {...props}
      source={props.source}
      label={props.label || props.source}
      name={props.name || props.source}
      key={props.name || props.source}
      InputProps={{
        endAdornment: (
          // @ts-ignore
          <InputAdornment position="end">
            <SearchIcon color="disabled"/>
          </InputAdornment>
        ),
      }}
    />
  ),
  dateInput: (props: any) => (
    <DateInput
      {...props}
      source={props.source}
      label={props.label || props.source}
      name={props.name || props.source}
      key={props.name || props.source}
    />
  ),
  dateTimeInput: (props: any) => (
    <DateTimeInput
      {...props}
      source={props.source}
      label={props.label || props.source}
      name={props.name || props.source}
      key={props.name || props.source}
    />
  ),
  booleanInput: (props: any) => (
    <NullableBooleanInput
      {...props}
      source={props.source}
      label={props.label || props.source}
      name={props.name || props.source}
      key={props.name || props.source}
    />
  ),
  selectInput: (props: any) => (
    <SelectInput {...props}
                 choices={props.choices}
                 label={props.label || props.source}
                 name={props.name || props.source}
                 key={props.name || props.source}
    />
  ),
  autoCompleteInput: (props: any) => (
    <ReferenceInput {...props}
                    name={props.name || props.source}
                    key={props.name || props.source}
                    label={props.label || props.source}>
      <AutocompleteInput
        optionText={props?.optionText}
        optionValue={props?.optionValue}
        filterToQuery={props?.filterToQuery || undefined}
      />
    </ReferenceInput>
  )
};

export const GenerateForm = (props: any) => {
  const { formFields, ...rest } = props;
  const fields = Object.entries(formFields as IFormFields);
  if (fields.length === 0) return null;
  return fields.map(([key, { type, ...restOptions }], index) => {

    return (
      <Box key={index} component="span" mr={2}>
        {FieldComponent[type]({
          ...rest,
          ...restOptions,
          source: key,
          label: restOptions?.label || key,
          ...(restOptions.choices ? { choices: restOptions.choices } : {})
        })}
      </Box>
    );
  });
};