export function sanitizeData(data:any):any{
  const sanitizedData:any = {};
  for (const key in data) {
    if (typeof data[key] === "string" && data[key].trim().length === 0) continue;
    sanitizedData[key] = data[key];
  }
  return sanitizedData;
}

export function replaceNullByEmptyValue(data:any):any{
  const replaced:any = {}
  for (const k in data){
    if(data[k]===null){
      replaced[k]=''
    } else if(typeof data[k] ==='object'){
      replaced[k]= replaceNullByEmptyValue(data[k])
    } else{
      replaced[k]=data[k]
    }
  }
return replaced;
}