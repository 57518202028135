import { useListContext } from 'react-admin';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import { GenerateForm } from './generate-filter-form';

export const Filter = (props: any) => {
  const { formFields, ...rest } = props;
  const { displayedFilters, filterValues, setFilters, hideFilter } =
    useListContext();
  const form = useForm({
    defaultValues: Object.keys(formFields).reduce((acc, key) => {
      return { ...acc, [key]: "" };
    }, {}), //props.filterValues,
  });

  if (!displayedFilters.main) return null;

  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      // @ts-ignore
      setFilters(values);
    } else {
      hideFilter("main");
    }
  };

  const resetFilter = () => {
    // form.reset(formDefaultValues);
    setFilters({}, []);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="center" flexWrap="wrap" mb={1}>
          {GenerateForm({ ...rest, formFields })}
          <Box component="span" mr={2} mb={1.5}>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
            >filter</Button>
          </Box>
          <Box component="span" mb={1.5}>
            <Button color="primary" variant="outlined" onClick={resetFilter} >close</Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};