import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from 'react-admin';
import { IconTitle, ShowContent, createIconFields } from '../icon';
import { config } from '../../config';
import { AdminResourceName } from '../../resource-name.enum';

export const IconEdit = () => {
  const iconFields = [
    ...createIconFields,
    <ShowContent key="show_content" label="Last content"/>,
  ];

  if (
    config.MODULES.IS_INTERCASH_CARD_INCLUDED
  ) {
    iconFields.push(<ReferenceInput key="cardDeliverySettingId" source="cardDeliverySettingId"
                                    reference={AdminResourceName.INTERCASH_DELIVERY} perPage={100}>
      <SelectInput label="DeliveryType" optionText="deliveryMethod" optionValue="id"/>
    </ReferenceInput>)
  }

  return (
    <Edit title={<IconTitle/>}>
      <SimpleForm warnWhenUnsavedChanges>
        {iconFields}
      </SimpleForm>
    </Edit>
  )
}