import { FunctionField, List, ReferenceField, TextField } from 'react-admin';
import { ListActions } from '../list-actions';
import { GridComponent } from '../grid/grid-component';
import { AdminResourceName } from '../../resource-name.enum';
import { ColoredInput } from './colored-input';

const balanceFields =[
  <TextField key='id' source="id"/>,
  <FunctionField key="asset" render ={(record:any)=>{
    if(record.assetId ==='Not exist for current application') return 'Not exist for current application';
    return(<ReferenceField key="assetId" reference={AdminResourceName.ASSET} source="assetId" link="show">
      <TextField key="title" source="title"/>
    </ReferenceField>)
  }}/>,
  <ColoredInput key='total' source="total"/>,
]

export const FBBalanceList =()=>{
  return(
    <List
      actions={<ListActions />}
      pagination={false}>
      <GridComponent gridChildren={balanceFields}  bulkActionButtons={false}/>
    </List>
  )
}