import { choicesFromEnum } from '../../@helpers/choices-from-enum';

export enum SocialLinkCode {
  REFERRAL_PROGRAM_SOCIAL_LINK_FACEBOOK = 'REFERRAL_PROGRAM_SOCIAL_LINK_FACEBOOK',
  REFERRAL_PROGRAM_SOCIAL_LINK_X = 'REFERRAL_PROGRAM_SOCIAL_LINK_X',
  REFERRAL_PROGRAM_SOCIAL_LINK_INSTAGRAM = 'REFERRAL_PROGRAM_SOCIAL_LINK_INSTAGRAM',
  REFERRAL_PROGRAM_SOCIAL_LINK_YOUTUBE = 'REFERRAL_PROGRAM_SOCIAL_LINK_YOUTUBE',
  REFERRAL_PROGRAM_SOCIAL_LINK_LINKEDIN = 'REFERRAL_PROGRAM_SOCIAL_LINK_LINKEDIN',
  REFERRAL_PROGRAM_SOCIAL_LINK_WHATSAPP = 'REFERRAL_PROGRAM_SOCIAL_LINK_WHATSAPP',
  // REFERRAL_PROGRAM_SOCIAL_LINK_DISCORD = 'REFERRAL_PROGRAM_SOCIAL_LINK_DISCORD',
  REFERRAL_PROGRAM_SOCIAL_LINK_REDDIT = 'REFERRAL_PROGRAM_SOCIAL_LINK_REDDIT',
  REFERRAL_PROGRAM_SOCIAL_LINK_TWITCH = 'REFERRAL_PROGRAM_SOCIAL_LINK_TWITCH',
}

export const SocialLinkCodeChoices = choicesFromEnum(SocialLinkCode);