import { SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

export const PayloadShow = (props: any) => {
  const record = useRecordContext();
  const { payload } = record
  const entries = Object.entries(payload || {});
  if (entries.length === 0) return null;
  return (
    <SimpleShowLayout>
      {entries.map(([key, value]) => {
        const record = { [key]: value }
        return <TextField key={key} label={key} record={record} source={key}/>
      })}
    </SimpleShowLayout>

  )
}