import { useRecordContext } from "react-admin";
import { config } from "../../config";

export const DeliveryIcon = (props: any) => {
  const record = useRecordContext();
  if (!record || record?.icons.length === 0) return null;

  const urls: string[] = record.icons.map(({ id }: { id: string }) => {
    return `${config.API_HOST}/assets/EUR/icons/delivery/${id}?format=png&size=40`

  })
  // console.log('url')
  // console.log(url)
  return (<>
      {urls.map((url, index) => {
        return <img key={index} alt="image" src={url} title={url} style={{ borderRadius: "50%" }}/>
      })}
    </>

  )
}