import { FieldTypes } from '../filter';
import { UserTypeEnum } from '../notification';

export const FilterForm = {
  id: { type: FieldTypes.textInput, name: 'id' },
  userType: {
    type: FieldTypes.selectInput,
    name: 'userType',
    choices: [{ id: UserTypeEnum.INDIVIDUAL, name: UserTypeEnum.INDIVIDUAL }, {
      id: UserTypeEnum.ORGANIZATION,
      name: UserTypeEnum.ORGANIZATION
    }]
  },
  email: { type: FieldTypes.textInput, name: 'email' },
  userName: { type: FieldTypes.textInput, name: 'userName' },
  legalName: { type: FieldTypes.textInput, name: 'legalName' },
}