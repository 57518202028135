import {
  List,
  Datagrid,
  DateField,
  TextField,
  Pagination,
  CreateButton
} from 'react-admin';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { ListActions } from '../list-actions';

export const AppListsList = () => {
  return (
    <List
      actions={<ListActions createButton={<CreateButton/>}/>}
      emptyWhileLoading pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id"/>
        <TextField source="listName"/>
        <TextField source="listValue"/>
        <DateField source="createdAt" showTime/>
        <DateField source="updatedAt" showTime/>
      </Datagrid>
    </List>
  )
}