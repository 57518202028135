import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
  Pagination,
  CreateButton
} from 'react-admin';
import { AdminResourceName } from '../../resource-name.enum';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { ListActions } from '../list-actions';
import { ISavingPlan, SavingPlanType } from './interfaces';

export const SavingPlanList = () => {
  return (
    <List
      actions={<ListActions createButton={<CreateButton/>}/>}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id"/>
        <TextField label="Title" source="title"/>
        <TextField label="Initial Bonus" source="bonusPercent"/>
        <TextField label="Interest (APY)" source="yearPercentage"/>
        <FunctionField label="Duration"
                       render={(record: ISavingPlan) => (record.type === SavingPlanType.Flexible ? 'Flexible' : record.duration)}/>
        <TextField source="minimumAmount"/>
        <ReferenceField label="Asset" source="assetId" reference={AdminResourceName.ASSET} link="show">
          <TextField source="shortcode"/>
        </ReferenceField>
        <TextField label="Type" source="type"/>
        <DateField source="createdAt" showTime/>
        <DateField source="updatedAt" showTime/>
      </Datagrid>
    </List>
  );
};
