export enum DeliveryMethodEnum {
  standard = 'Standard',
  courier = 'Courier',
}

export const DeliveryMethodChoices = Object.values(DeliveryMethodEnum).map((method) => {
  return {
    id: method,
    name: method,
  }
})