import {
  ArrayInput,
  choices,
  DateTimeInput,
  Edit, maxLength,
  minLength, minValue, number,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { CustomToolbar } from '../toolbar';
import { ReferralProgramTitle } from './show';
import { choicesFromEnum } from '../../@helpers/choices-from-enum';
import { TransformCreateFormData } from './create';

export enum ReferralProgramAccessibilityAsset {
  EUR = 'EUR'
}

export enum ReferralProgramState {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export enum ReferralProgramUserType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
  ALL = 'ALL',
}

export enum ReferralRewardAsset {
  EUR = 'EUR',
  WLXT_ERC20 = 'WLXT_ERC20',
  EURST_ERC20 = 'EURST_ERC20',
  BTC = 'BTC',
  ETH = 'ETH',
  USDT_BEP20 = 'USDT_BEP20',
  USDC_BEP20 = 'USDC_BEP20',
}

export const ReferralRewardAssetChoices = choicesFromEnum(ReferralRewardAsset);
export const ReferralProgramUserTypeChoices = choicesFromEnum(ReferralProgramUserType);
export const ReferralProgramStateChoices = choicesFromEnum(ReferralProgramState);
export const ReferralProgramAccessibilityAssetChoices = choicesFromEnum(ReferralProgramAccessibilityAsset)

const rewardAssetChoicesValidate = (value: any) => {
  if (value.length === 0) {
    return 'There are must be at least one asset selected'
  }
  const checkedValue = new Set();
  const duplicates = new Set();
  for (const val of value.map((v: any) => v.rewardAsset)) {
    if (checkedValue.has(val)) {
      duplicates.add(val)
    } else {
      checkedValue.add(val);
    }
  }
  if (checkedValue.has('')) {
    return 'The empty asset data not allowed'
  }
  if (value.length !== 0 && Array.from(duplicates).length === 0) {
    return null;
  }
  return `The duplication for rewards not allowed for asset. Please remove duplicates: ${Array.from(duplicates).join(', ')}`;
}

const amountRequired = (value: any[],filedKey:string,message:string)=>{
  const result: any[] = value.map((val: any) => {
    return val[filedKey] ? undefined : message||'The amount is required'
  })
  const firstError = result.filter((el: any) => !!el)
  return firstError.length === 0 ? null : firstError[0]
}
const amountIsNumber = (value: any[],filedKey:string,message:string)=>{
  const result = value.map((val: any) => {
    return isNaN(Number(val[filedKey])) ? message : undefined
  })
  const firstError = result.filter((el: any) => !!el)
  return firstError.length === 0 ? null : firstError[0]
}

const amountIsPositive = (value: any[],filedKey:string,message:string)=>{
  const result: any[] = value.map((val: any) => {
    return Number(val[filedKey]) < 0 ? message : undefined
  })
  const firstError = result.filter((el: any) => !!el)
  return firstError.length === 0 ? null : firstError[0]
}

const rewardFollowerAmountRequired = (value: any[], values: any) => {
  return amountRequired(value,'rewardFollowerAmount','The follower reward amount is required');
}

const rewardFollowerAmountNumber = (value: any[], values: any) => {
  return amountIsNumber(value,'rewardFollowerAmount','The follower reward amount must be a number');
}

const rewardFollowerAmountMinValue = (value: any[], values: any) => {
  return amountIsPositive(value,'rewardFollowerAmount','The follower reward amount must be a positive number');
}

const rewardOwnerAmountRequired = (value: any, values: any) => {
  return amountRequired(value,'rewardOwnerAmount','The owner reward amount is required');
}
const rewardOwnerAmountNumber = (value: any, values: any) => {
  return amountIsNumber(value,'rewardOwnerAmount','The owner reward amount must be a number');
}

const rewardOwnerAmountMinValue = (value: any, values: any) => {
  return amountIsPositive(value,'rewardOwnerAmount','The owner reward amount must be a positive number');

}
const rewardAssetChoicesRequired = (value: any, values: any) => {
  if (!values?.rewardAssetChoices || values?.rewardAssetChoices.length === 0) {
    return 'The reward assets empty value not allowed'
  }
  return null
}


export const CreateProgramValidate = {
  title: [required(), minLength(3), maxLength(40)],
  description: [required(), minLength(3), maxLength(40)],
  state: [required(), choices([ReferralProgramState.ACTIVE, ReferralProgramState.DEACTIVATED], 'Please choose one of the values')],
  userTypeAffected: [required(), choices([ReferralProgramUserType.ALL, ReferralProgramUserType.INDIVIDUAL, ReferralProgramUserType.COMPANY], 'Please choose one of the')],
  accessibilityAsset: [required(), choices([ReferralProgramAccessibilityAsset.EUR], 'Please choose one of the values')],
  choices: [required(), choices(Object.values(ReferralRewardAsset), 'The reward asset must be from allowed choices')],
  rewardAssetChoices: [rewardAssetChoicesRequired, rewardAssetChoicesValidate, rewardFollowerAmountRequired, rewardFollowerAmountNumber, rewardFollowerAmountMinValue,rewardOwnerAmountRequired,rewardOwnerAmountNumber,rewardOwnerAmountMinValue],
  amount: [required('The amount is required'), number('The value must be a number'), minValue(0, 'The value must be a positive')],
  rewardTriggerAsset: [required(), choices([ReferralProgramAccessibilityAsset.EUR], 'Please choose one of the values')],
}

export const ReferralProgramEdit = () => {
  return (
    <Edit title={<ReferralProgramTitle/>} transform={TransformCreateFormData} mutationMode="pessimistic">
      <SimpleForm warnWhenUnsavedChanges toolbar={<CustomToolbar saveButton={true} deleteButton={true}/>}>
        <TextInput name="id" disabled source="id"/>
        <TextInput name="title" source="title" validate={CreateProgramValidate.title}/>
        <TextInput name="description" source="description" validate={CreateProgramValidate.description}/>
        <SelectInput name="state" source="state" choices={ReferralProgramStateChoices}
                     validate={CreateProgramValidate.state}/>
        <SelectInput name="userTypeAffected" source="userTypeAffected" choices={ReferralProgramUserTypeChoices}
                     validate={CreateProgramValidate.userTypeAffected}/>
        <SelectInput disabled name="accessibilityAsset" source="accessibilityAsset"
                     choices={ReferralProgramAccessibilityAssetChoices}
                     validate={CreateProgramValidate.accessibilityAsset}/>
        <TextInput name="accessibilityAmount" source="accessibilityAmount" validate={CreateProgramValidate.amount}/>
        <TextInput disabled name="maxActivationsAllowed" source="maxActivationsAllowed"/>
        <ArrayInput name="rewardAssetChoices" source="rewardAssetChoices"
                    validate={CreateProgramValidate.rewardAssetChoices} defaultValue={[{rewardAsset:'',rewardFollowerAmount:'',rewardOwnerAmount:''}]}>
          <SimpleFormIterator>
            <SelectInput source="rewardAsset" choices={ReferralRewardAssetChoices} validate={CreateProgramValidate.choices}/>
            <TextInput source="rewardFollowerAmount" validate={CreateProgramValidate.amount}
                       helperText={false}/>
            <TextInput source="rewardOwnerAmount" validate={CreateProgramValidate.amount}
                       helperText={false}/>
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput disabled name="rewardTriggerAsset" source="rewardTriggerAsset"
                     choices={ReferralProgramAccessibilityAssetChoices}
                     validate={CreateProgramValidate.rewardTriggerAsset}/>
        <TextInput name="rewardTriggerAmount" source="rewardTriggerAmount" validate={CreateProgramValidate.amount}/>
        <DateTimeInput name="startDateAt" source="startDateAt"/>
        <DateTimeInput disabled name="createdAt" source="createdAt"/>
        <DateTimeInput disabled name="updatedAt" source="updatedAt"/>
      </SimpleForm>
    </Edit>
  )
}