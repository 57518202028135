import { Create, SelectInput, SimpleForm, TextInput, useGetList } from 'react-admin';
import { choicesFromEnum } from '../../@helpers/choices-from-enum';
import { Platforms } from './filter-form';
import { AdminResourceName } from '../../resource-name.enum';
import { Card } from '@mui/material';
import { removeEmpty } from '../../@helpers/remove-empty-from-object';

export const transformFormData = (data: Record<string, any>) => {
  return removeEmpty(data);

}

export const validateForm = (data: Record<string, any>) => {
  const error: Record<string, any> = {};
  const { platform, key, ...locales } = data;
  if (!platform) {
    error.platform = 'The platform must be defined';
  }
  if (!key) {
    error.key = 'The key must be defined';
  }
  if (Object.keys(locales).length === 0) {
    error.key = 'No one text defined';
  }
  if (Object.entries(locales).filter(([_, val]) => !!val.text).length === 0) {
    error[`${Object.keys(locales)[0]}.text`] = 'There is must be at least one text to be defined';
  }
  return error;
}

export const TranslationCreate = (props: any) => {
  const response = useGetList(AdminResourceName.LOCALES, {});

  return (
    <Create transform={transformFormData}>
      <SimpleForm validate={validateForm}>
        <SelectInput
          choices={choicesFromEnum(Platforms)}
          helperText={'Choose platform'}
          required={true}
          source="platform"
          label="Select platform"
        />

        <TextInput
          style={{ width: '100%' }}
          key={`key`}
          label="Key"
          source={`key`}
          required={true}
        />

        {response.data?.map((locale: any) => (
          <Card key={locale.name} title={locale.name} about={locale.code}
                style={{ width: '100%', marginBottom: '10px' }}>
            <h3>{locale.name}</h3>

            <TextInput
              style={{ width: '100%' }}
              key={`${locale.code}.text`}
              label="Text"
              source={`${locale.code}.text`}
              required={false}
            />
          </Card>
        ))}
      </SimpleForm>
    </Create>
  );
};
