import { BooleanInput, DateInput, Edit, SimpleForm, TextInput, useRecordContext } from 'react-admin';

export const LocaleEdit = (props: any) => {
  // const record = useRecordContext();
  // if (!record) return null;

  return (
    <Edit>
      <SimpleForm>
        <TextInput label="Code" source="code" />
        <TextInput label="Name" source="name" />
        <TextInput label="Flag" source="flag" />
        <BooleanInput label="Is active" source="isActive"  />
        <DateInput name="createdAt" source="createdAt" disabled />
        <DateInput name="updatedAt" source="updatedAt" disabled />
      </SimpleForm>
    </Edit>
  );
};
