import {
  CreateButton,
  DateField,
  List,
  NumberField,
  Pagination,
  ShowButton,
  TextField,
  usePermissions,
  FunctionField,
} from 'react-admin';
import { ListActions } from '../../list-actions';
import { ROWS_PER_PAGE } from '../../pagination/pagination.const';
import { GridComponent } from '../../grid/grid-component';

const PeriodsListFields = [
  <NumberField key="id" source="id"/>,
  <TextField key="title" source="title"/>,
  <NumberField key="duration" source="duration"/>,
  <TextField key="aprValue" source="aprValue"/>,
  <FunctionField key="allowedRefMinAmount"
                 label="allowedRefMinAmount"
                 render={(record: any) => `${record.allowedRefMinAmount} ${record.refAsset}`}/>,
  <DateField key="createdAt" source="createdAt" showTime/>,
  <ShowButton key="showBtn"/>,
]

export const PeriodList = () => {
  const { isLoading } = usePermissions();
  if (isLoading) return null;

  return (
    <List
      actions={<ListActions createButton={<CreateButton/>}/>}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}
    >
      <GridComponent bulkActionButtons={false} gridChildren={PeriodsListFields}/>
    </List>
  )
}