import { FunctionField, List, ReferenceField, TextField } from 'react-admin';
import { ListActions } from '../list-actions';
import { GridComponent } from '../grid/grid-component';
import { AdminResourceName } from '../../resource-name.enum';
import { ColoredInput } from '../fireblocks-balance';


const balanceFields =[
  <TextField label="Asset Id (service)" key="id" source="id"/>,
  <FunctionField label='Application Asset (shortcode)' key="asset" render ={(record:any)=>{
    if(record.assetId ==='Not exist for current application') return 'Not exist for current application';
    return(<ReferenceField key="assetId" reference={AdminResourceName.ASSET} source="assetId" link="show">
      <TextField key="title" source="title"/>
    </ReferenceField>)
  }}/>,
  <ColoredInput label="Total balance (service)" key='total' source="total"/>,
]

export const KrakenBalanceList =()=>{
  return(
    <List
      actions={<ListActions />}
      pagination={false}>
      <GridComponent gridChildren={balanceFields}  bulkActionButtons={false}/>
    </List>
  )
}