import { AutocompleteInput, Create, ReferenceInput, SelectInput, SimpleForm, useGetList, useGetOne } from 'react-admin';
import { useEffect, useState } from 'react';
import { AdminResourceName } from '../../../resource-name.enum';
import { CreditAssetType, CreditAssetTypeChoices } from './constants';


const CreditAssetsInput = (props: {
  filter: Record<string, any>
}) => {
  const { filter, ...rest } = props;
  return (
    <ReferenceInput {...rest} name="shortcode" source="shortcode" reference={AdminResourceName.ASSET} perPage={100}
                    filter={filter}>
      <AutocompleteInput helperText={false} label="Asset" optionText="shortcode" optionValue="shortcode"
                         filterToQuery={(value: any) => ({ shortcode: value })}
                         fullWidth={true}/>
    </ReferenceInput>
  )
}


export const CreditAssetCreate = () => {
  const [filter, setFilter] = useState({})
  const { data, isLoading } = useGetOne<{ id: string, CREDIT: string[], COLLATERAL: string[] }>(
    `${AdminResourceName.CREDIT_ASSET}/allowed`,
    { id: 'shortcodes' }
  )
  const { data: listData, isLoading: isListLoading } = useGetList<{
    id: string,
    type: CreditAssetType,
    shortcode: string
  }>(AdminResourceName.CREDIT_ASSET)

  useEffect(() => {
  }, [filter]);

  if (!data || isLoading) return null;
  if (!listData || isListLoading) return null;

  const handleChangeType = (event: any) => {
    setFilter({
      shortcodes: data[event.target.value as CreditAssetType]
    })
  }

  const formValidation = ({ type, shortcode }: any) => {
    const errors: { type?: string, shortcode?: string } = {};
    const isExisted = listData.find((creditAsset) => {
      return creditAsset.type === type && creditAsset.shortcode === shortcode
    })
    if (isExisted) {
      errors.shortcode = `The asset ${type} ${shortcode} is already existed!`;
    }
    if (!data[type as CreditAssetType]?.includes(shortcode)) {
      errors.shortcode = `The asset ${shortcode} not allowed for type ${type}`
    }
    if (!type) {
      errors.type = 'The type can`t be empty!'
    }
    if (!shortcode) {
      errors.shortcode = 'The shortcode can`t be empty!'
    }
    return errors
  }

  return (
    <Create redirect="list">
      <SimpleForm validate={formValidation}>
        <SelectInput name="type" source="type" choices={CreditAssetTypeChoices} fullWidth={true}
                     onChange={handleChangeType}/>
        <CreditAssetsInput filter={filter}/>
      </SimpleForm>
    </Create>
  )
}