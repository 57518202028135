import { ListActions } from '../list-actions';
import {
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  Pagination,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { FieldTypes, Filter, FilterButton } from '../filter';
import { AdminResourceName } from '../../resource-name.enum';
import { clearHtmlTags } from '../../@helpers/clear-html-tags.helper';
import { limitStringLength } from '../../@helpers/limit-string-length';
import { STRING_LENGTH_LIMIT, STRING_LENGTH_LIMIT_POSTFIX } from './notification.constants';

const FilterForm = {
  id: { type: FieldTypes.textInput, name: 'id' },
  type: { type: FieldTypes.textInput, name: 'type' },
  email: { type: FieldTypes.textInput, name: 'email' },
  userName: { type: FieldTypes.textInput, name: 'userName' },
  dateFrom: { type: FieldTypes.dateTimeInput, name: 'dateFrom' },
  dateTo: { type: FieldTypes.dateTimeInput, name: 'dateTo' },
}

export const NotificationList = () => {
  return (
    <List
      actions={<ListActions form={<Filter formFields={FilterForm}/>} filterButton={<FilterButton/>}
                            createButton={<CreateButton/>}
                            withoutExport={true}/>}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id"/>
        <FunctionField label="Title" render={(record: Record<string, any>) => {
          return limitStringLength(clearHtmlTags(record.title), STRING_LENGTH_LIMIT, STRING_LENGTH_LIMIT_POSTFIX)
        }}/>
        <FunctionField label="Content" render={(record: Record<string, any>) => {
          return limitStringLength(clearHtmlTags(record.content), STRING_LENGTH_LIMIT, STRING_LENGTH_LIMIT_POSTFIX)
        }}/>
        <FunctionField label="Text" render={(record: Record<string, any>) => {
          return limitStringLength(clearHtmlTags(record.text), STRING_LENGTH_LIMIT, STRING_LENGTH_LIMIT_POSTFIX)
        }}/>
        <FunctionField label="Recipients" render={(record: Record<string, any>) => {
          if (record.userList.length > 0) {
            return (<ReferenceArrayField source="userList" reference={AdminResourceName.USER}>
              <SingleFieldList linkType={false}>
                <TextField source="email"/>
              </SingleFieldList>
            </ReferenceArrayField>)
          }
          return 'All users'
        }}/>
        <DateField source="createdAt" showTime/>
        <EditButton/>
      </Datagrid>
    </List>
  )
}