import { DateField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';
import { AdminResourceName } from '../../resource-name.enum';

export const IbanTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>"Iban: {record.iban}"</span>;
}

const IbanFields = [
  <TextField key='id' source="id"/>,
  <TextField key='iban' source="iban"/>,
  <TextField key='state' source="state"/>,
  <TextField key='swiftCode' source="swiftCode"/>,
  <TextField key='bankCountry' source="bankCountry"/>,
  <TextField key='bankAddress' source="bankAddress"/>,
  <TextField key='bankName' source="bankName"/>,
  <TextField key='accountNumber' source="accountNumber"/>,
  <TextField key='sortCode' source="sortCode"/>,
  <ReferenceField key='account' label="account" source="accountId" reference={AdminResourceName.BUSINESS_ACCOUNT} link="show">
    <TextField source="accountNumber" />
  </ReferenceField>,
  <DateField key='createdAt' label="Created date" source="createdAt" showTime/>,
  <DateField key='updatedAt' label="Updated date" source="updatedAt" showTime/>,
];

export const IbanShow = ()=>{
  return (
    <Show title={<IbanTitle/>}>
      <SimpleShowLayout>
        {IbanFields}
      </SimpleShowLayout>
    </Show>
  )
}