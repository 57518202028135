import { Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';
import { transformNumberToString } from '../../../@helpers/transform-number-to-string';

export const PeriodEdit = () => {

  return (
    <Edit redirect="list">
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput name="title" source="title"/>
        <NumberInput name="duration" source="duration" min={1} step={1}/>
        <NumberInput name="aprValue" source="aprValue" min={0} step={0.01} parse={transformNumberToString}/>
        <TextInput name="allowedRefMinAmount" source="allowedRefMinAmount"/>
      </SimpleForm>
    </Edit>
  )
}