import { AppNamesEnum } from '../config';

import WallexNeobankingLogo from '../images/wallex-neobanking/logo.svg';
import WallexBgLogo from '../images/wallex-bg/logo.svg';
import WallexCustodyProLogo from '../images/wallex-custody-pro/logo.svg';
import WallexTokashLogo from '../images/wallex-tokash/logo.svg';
import WallexEqapitalLogo from '../images/wallex-eqapital/logo.svg';
import WallexBitgeneraLogo from '../images/wallex-bitgenera/logo.svg';
import WallexBebankerLogo from '../images/wallex-bebanker/logo.svg';
import WallexPrimeLogo from '../images/wallex-prime/logo.svg';

export const getAdminLogo = (appName: string) => {
  switch (appName) {
    case AppNamesEnum.WALLEX_NEOBANKING:
      return WallexNeobankingLogo;
    case AppNamesEnum.WALLEX_CUSTODY_PRO:
      return WallexCustodyProLogo;
    case AppNamesEnum.WALLEX_TOKASH:
      return WallexTokashLogo;
    case AppNamesEnum.WALLEX_BG:
      return WallexBgLogo;
    case AppNamesEnum.WALLEX_EQAPITAL:
      return WallexEqapitalLogo;
    case AppNamesEnum.WALLEX_BITGENERA:
      return WallexBitgeneraLogo;
    case AppNamesEnum.WALLEX_BEBANKER:
      return WallexBebankerLogo;
    case AppNamesEnum.WALLEX_PRIME:
      return WallexPrimeLogo;
    default:
      return WallexNeobankingLogo;
  }
}

export const getAdminColors = (appName: string) => {
  const colors = {
    text: '#111314',
    contrast: "#fff",
    primary: '#1E55B2',
    sectionBackground: '#1E55B2',
    sectionText: '#fff',
    toImportant: (color: string) => `${color} !important`
  }

  switch (appName) {
    case AppNamesEnum.WALLEX_NEOBANKING:
      colors.sectionBackground = '#d4e5ff';
      colors.sectionText = '#1E55B2';
      return colors;
    case AppNamesEnum.WALLEX_CUSTODY_PRO:
      return colors;
    case AppNamesEnum.WALLEX_TOKASH:
      colors.sectionBackground = '#00c08b';
      colors.primary = '#00c08b';
      return colors;
    case AppNamesEnum.WALLEX_BG:
      colors.sectionBackground = '#8acbff';
      colors.sectionText = '#fff';
      colors.primary = '#055fc5';
      return colors;
    case AppNamesEnum.WALLEX_EQAPITAL:
      colors.sectionBackground = '#5F4CD1';
      colors.primary = '#5F4CD1';
      return colors;
    case AppNamesEnum.WALLEX_BITGENERA:
      colors.sectionBackground = '#111314';
      colors.primary = '#111314';
      return colors;
    case AppNamesEnum.WALLEX_BEBANKER:
      colors.sectionBackground = '#26a9e0';
      colors.sectionText = '#000';
      colors.primary = '#26a9e0';
      return colors;
    case AppNamesEnum.WALLEX_PRIME:
      colors.sectionBackground = '#010110';
      colors.primary = '#010110';
      return colors;
    default:
      return colors;
  }
}
