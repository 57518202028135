import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const SocialLinkShow=(props:any)=>{
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField label="Link id" source="id"/>
        <TextField label="Link code" source="code"/>
        <TextField label="Link description" source="description"/>
        <TextField label="Link value" source="value"/>
        <DateField label="Created at" source="createdAt"/>
        <DateField label="Updated at" source="updatedAt"/>
      </SimpleShowLayout>
    </Show>
  )
}