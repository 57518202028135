import {
  DateField,
  EmailField,
  FunctionField,
  List,
  Pagination,
  TextField,
  usePermissions,
} from 'react-admin';
import { CountryShow } from '../country';
import { AccountTotalBalance, IdTypeEnum } from '../balance';
import { UserFilterButton, UserFilterForm } from '../user';
import { DeleteUserButton } from './delete-user-button';
import { RoleAction, RolePermissions } from '../role';
import { AdminResourceName } from '../../resource-name.enum';
import { fullName } from '../../@helpers/wallet.helpers';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { GridComponent } from '../grid/grid-component';
import { ListActions } from '../list-actions';

export function account(record: any) {
  if (record.individualAccount) {
    return record.individualAccount;
  }
  return record.businessAccounts[0];
}

const UserDeletionListFields = (isUserDeletionPermitted: boolean) => {
  const userDeletionFields = [
    <TextField key="id" source="id"/>,
    <FunctionField key="userName" label="User Name" render={(record: any) => fullName(record)}/>,
    <FunctionField key="LegalName" label="Legal Name" render={(record: any) => account(record)?.legalName}/>,
    <CountryShow key="country" label="Country"/>,
    <EmailField key="email" label="User email" source="email"/>,
    <AccountTotalBalance key="balance" label="Balance" idType={IdTypeEnum.USER}/>,
    <DateField key="deletionRequestedAt" label="Deletion request date" source="deletionRequestedAt" showTime/>,
  ];
  if (isUserDeletionPermitted) {
    userDeletionFields.push(<DeleteUserButton key="deleteBtn"/>);
  }
  return userDeletionFields
}

export const UserDeletionList = () => {
  const { isLoading, permissions } = usePermissions();
  if (isLoading) return null;
  const handleUserDeletion = !!(RolePermissions[permissions].find(({ action, resource }) => {
    return (resource === AdminResourceName.HANDLE_USER_DELETION && action === RoleAction.EDIT) ||
      (resource === AdminResourceName.ALL &&
        (action === RoleAction.EDIT || action === RoleAction.ALL)
      )
  }));
  return (
    <List actions={<ListActions filterButton={<UserFilterButton/>} form={<UserFilterForm/>}/>}
          sort={{ field: 'createdAt', order: 'DESC' }}
          pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <GridComponent gridChildren={UserDeletionListFields(handleUserDeletion)} rowClick="show"
                     bulkActionButtons={false}/>
    </List>
  )
}
