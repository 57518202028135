import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  TextField,
  Datagrid,
  Pagination,
  List,
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  AutocompleteArrayInputProps, useCreateContext, Confirm, useRedirect, useGetList
} from 'react-admin'
import { useState } from 'react';
import { AdminResourceName } from '../../resource-name.enum';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { choicesFromEnum } from '../../@helpers/choices-from-enum';
import { useNotify } from 'ra-core';
import TinyRichTextEditor from '../../@shared/tiny-rich-text-editor';
import { limitStringLength } from '../../@helpers/limit-string-length';
import { clearHtmlTags } from '../../@helpers/clear-html-tags.helper';
import { STRING_LENGTH_LIMIT_POSTFIX } from './notification.constants';

export enum UserTypeEnum {
  ALL = 'all',
  ORGANIZATION = 'organization',
  INDIVIDUAL = 'individual',
}

export const UserTypeChoices = choicesFromEnum(UserTypeEnum);

export const ConfirmNotification = (props: any) => {
  const { values, filter, ...rest } = props
  const { data,total, isLoading, } = useGetList(AdminResourceName.USER, {
    filter,
  })
   return (
    <Confirm
      content={<>
        <p>The data to submit:</p>
        <p>Title: {values.title}</p>
        <p>Subtitle: {values.content}</p>
        <p>Text: {limitStringLength(clearHtmlTags(values?.text||''), 255, STRING_LENGTH_LIMIT_POSTFIX)}</p>
        <p>Total recipients: {total}</p>
      </>}
      loading={isLoading}
      {...rest}
    />
  )
}

const UserChoseArrayInput = (props: any) => {
  const { filter, filterToQuery, onChange, ...rest } = props

  return (<ReferenceArrayInput {...rest} name="userList" label="Recipient list" source="userList"
                               reference={AdminResourceName.USER}
                               filter={filter} perPage={23}>
    <AutocompleteArrayInput
      optionText="email" optionValue="id" debounce={400}
      onChange={onChange} filterToQuery={filterToQuery} fullWidth/>
  </ReferenceArrayInput>)
}

export const CreateNotificationForm = () => {
  const [open, setOpen] = useState(false);
  const { save } = useCreateContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const [submitValues, setSubmitValues] = useState({})
  const [filterDefaultValues, setFilterDefaultValues] = useState({})
  const [emailFilter, setEmailFilter] = useState({})

  // useEffect(() => {
  //   });

  const handleCountryChange = (value: any) => {
    const { country, ...rest } = filterDefaultValues as Record<string, any>;
    setFilterDefaultValues({
      ...rest,
      ...(value?.length > 0 ? { country: value } : {}),
    })
    const { ids, ...restOfRest } = rest
    const filter = {
      ...restOfRest,
      ...(value?.length > 0 ? { country: value } : {})
    }
    setEmailFilter(filter)
  }
  const handleUserTypeChange = (e: any) => {
    const { userType, ...rest } = filterDefaultValues as Record<string, any>;
    setFilterDefaultValues({
      ...rest,
      ...(e.target.value === UserTypeEnum.ALL ? {} : { userType: e.target.value }),
    })
    const { ids, ...restOfRest } = rest
    setEmailFilter({
      ...restOfRest,
      ...(e.target.value === UserTypeEnum.ALL ? {} : { userType: e.target.value }),
    })
  }

  const handleEmailChange: AutocompleteArrayInputProps['onChange'] = (value, records) => {
    const { ids, ...rest } = filterDefaultValues as Record<string, any>;
    setFilterDefaultValues({
      ...rest,
      ...(value.length !== 0 ? { ids: value } : {}),
    })
    setEmailFilter(rest);
  }
  const handleSubmit = (values: any) => {
    setSubmitValues(values);
    setOpen(true);
  }
  const handleConfirm = () => {
    // @ts-ignore
    save(submitValues, {
      onSuccess: () => {
        setOpen(false);
        redirect('list', AdminResourceName.NOTIFICATION)
      },
      onError: (error) => {
        setOpen(false);
        notify(error.message, { type: 'error' })
      }
    })
  };

  const handleDialogClose = () => setOpen(false);

  const filterToQuery = (searchText: string) => {
    return {
      ...emailFilter,
      email: searchText
    }
  };

  return (
    <SimpleForm warnWhenUnsavedChanges onSubmit={handleSubmit}>
      <TextInput name="title" label="Title" source="title" validate={[required()]} fullWidth/>
      <TextInput name="content" label="Subtitle" source="content" validate={[required()]} multiline fullWidth/>
      <TinyRichTextEditor name="text" label="Text" source="text"/>
      <ReferenceArrayInput name="country" label="Country" source="country" reference={AdminResourceName.COUNTRY}
                           perPage={400}>
        <AutocompleteArrayInput optionText="name" optionValue="cca3Code" onChange={handleCountryChange} debounce={400}
                                fullWidth/>
      </ReferenceArrayInput>
      <SelectInput name="userType" label="Account type" source="userType" choices={UserTypeChoices}
                   defaultValue={UserTypeEnum.ALL}
                   validate={required()} onChange={handleUserTypeChange} fullWidth/>
      <UserChoseArrayInput filter={emailFilter} onChange={handleEmailChange} filterToQuery={filterToQuery}/>
      <List resource={AdminResourceName.USER} filter={filterDefaultValues} exporter={false}
            pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>} disableSyncWithLocation={true}>
        <Datagrid bulkActionButtons={false}>
          <TextField label="Id" source="id"/>
          <TextField label="Email" source="email"/>
          <TextField label="Country" source="profile.countryIsoCode"/>
        </Datagrid>
      </List>
      <ConfirmNotification
        isOpen={open}
        title={`Create push notification`}
        values={submitValues}
        filter={filterDefaultValues}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </SimpleForm>
  )
}

export const NotificationCreate = () => {
  return (
    <Create title="Create Push Notification">
      <CreateNotificationForm/>
    </Create>
  )
}