import {
  Datagrid,
  DateInput,
  Edit,
  List,
  Pagination,
  required,
  SimpleForm,
  TextField,
  TextInput,
  useEditContext,
} from 'react-admin';
import { NotificationTitle } from './show';
import { CustomToolbar } from '../toolbar';
import { AdminResourceName } from '../../resource-name.enum';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import TinyRichTextEditor from '../../@shared/tiny-rich-text-editor';


const EditForm = (props: any) => {
  const { record, isLoading } = useEditContext();
  if (isLoading) return null;


  return (
    <SimpleForm {...props} record={record} warnWhenUnsavedChanges toolbar={<CustomToolbar saveButton={true}/>}>
      <TextInput name="id" label="Id" disabled source="id" fullWidth/>
      <TextInput name="title" label="Title" source="title" validate={[required()]} fullWidth/>
      <TextInput name="content" label="Subtitle" source="content" validate={[required()]} multiline fullWidth/>
      <TinyRichTextEditor name="text" label="Text" source="text" />
      <List resource={AdminResourceName.USER}
            filterDefaultValues={record.userList.length > 0 ? { ids: record.userList } : undefined} exporter={false}
            pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
        <Datagrid bulkActionButtons={false}>
          <TextField label="Id" source="id"/>
          <TextField label="Email" source="email"/>
          <TextField label="Country" source="profile.countryIsoCode"/>
        </Datagrid>
      </List>
      <DateInput disabled name="createdAt" source="createdAt"/>
      <DateInput disabled name="updatedAt" source="updatedAt"/>
    </SimpleForm>
  )
}

export const NotificationEdit = () => {
  return (
    <Edit title={<NotificationTitle/>} mutationMode="pessimistic">
      <EditForm/>
    </Edit>
  )
}