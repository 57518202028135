import { SelectInput, TextInput, useGetList, useListContext } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from 'react';
import { choicesFromEnum } from '../../@helpers/choices-from-enum';
import { AdminResourceName } from '../../resource-name.enum';

const formDefaultValues = {
  id: '',
  name: '',
};

export enum Platforms {
  WEB = 'web',
  IOS = 'ios',
  ANDROID = 'android',
}

export const TranslationFilterForm = () => {
  const { displayedFilters, filterValues, setFilters, hideFilter } = useListContext();

  const locales = useGetList(AdminResourceName.LOCALES, {});

  const localeChoices = locales.data?.map((locale: any) => ({
    id: locale.code,
    name: locale.name,
  }));

  const form = useForm({
    defaultValues: filterValues,
  });

  useEffect(() => {
    form.reset(formDefaultValues);
  }, [form.reset]);

  if (!displayedFilters.main) return null;

  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      // @ts-ignore
      setFilters(values);
    } else {
      hideFilter('main');
    }
  };

  const resetFilter = () => {
    form.reset(formDefaultValues);
    setFilters({}, []);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="flex-end" mb={1}>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="key"
              label="Search by key"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="text"
              label="Search by text"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <SelectInput
              choices={choicesFromEnum(Platforms)}
              helperText={false}
              source="platform"
              label="Select platform"
            />
          </Box>
          <Box component="span" mr={2}>
            <SelectInput
              choices={localeChoices}
              helperText={false}
              source="localeCode"
              label="Select locale"
            />
          </Box>

          <Box component="span" mr={2} mb={1.5}>
            <Button variant="outlined" color="primary" type="submit">
              Filter
            </Button>
          </Box>
          <Box component="span" mb={1.5}>
            <Button variant="outlined" onClick={resetFilter}>
              Close
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};
