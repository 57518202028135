import { choicesFromEnum } from '../../@helpers/choices-from-enum';

enum TransactionType {
  transfer = 'TRANSFER',
  outgoing_transfer = 'OUTGOING_TRANSFER',
  incoming_transfer = 'INCOMING_TRANSFER',
  exchange = 'EXCHANGE',
  saving = 'SAVING',
  topUp = 'TOP_UP',
  fee='FEE',
  Credit='CREDIT',
  Collateral='COLLATERAL',
}

const TransactionTypes = choicesFromEnum(TransactionType);

export { TransactionTypes, TransactionType }