import { AutocompleteInput, useGetList } from 'react-admin';
import { AdminResourceName } from '../../resource-name.enum';

export const CountrySelect = (props: any) => {
  const { data, isLoading, error } = useGetList(AdminResourceName.COUNTRY);
  const source = props.source;
  const label = props.label;
  const defaultValue = props.defaultValue;

  if (!data || isLoading || error) return null;
  return (
      <AutocompleteInput
        source={source}
        label={label}
        choices={data}
        optionText="name"
        optionValue="cca3Code"
        defaultValue={defaultValue || ''}
        helperText={false}
        filterToQuery={(value: any) => ({ title: value })}
      />
  );
};
