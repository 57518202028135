import { SEND_RECEIVE_SUPPORTED_FIATS } from '../components/reports/assets.enum';
import { FiatShortCode } from '@fintech-solutions/core';
import { User } from '../components/business-account';

export function organizationName(record:any):any{
  const account = record.account;
  return account?.legalName;
}

export  function owner(record: any): any {
  const account = record.account;
  return account.singleOwner || account.users.map((u: any) => u.user)?.[0];
}

export function fullName(owner: User | undefined): string {
  if (!owner) {
    return '';
  }
  return [owner.profile.firstName, owner.profile.lastName].filter(v => !!v).join(' ');
}
export function accountNumber(record: any): string {
  if (SEND_RECEIVE_SUPPORTED_FIATS.includes(record.asset)) {
    return record.account.accountNumber;
  }
  return record.address
}

export function getProviderNumber(record: any): string {
  if (Object.values(FiatShortCode).includes(record.asset)){
    return record?.iban?.iban || '';
  }
  return record.address;
}