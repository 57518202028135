import { CreateButton, EditButton, List, Pagination, TextField } from 'react-admin';
import { ListActions } from '../list-actions';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { GridComponent } from '../grid/grid-component';
import { config } from '../../config';
import { TranslationFilterForm } from './filter-form';
import { TransactionFilterButton } from '../transaction';
import { AdminResourceName } from '../../resource-name.enum';
import { ImportButton } from './import';

const TranslationListFields = () => {
  return [
    <TextField key="key" label="Key" source="key"/>,
    <TextField key="text" label="Text" source="text"/>,
    <TextField key="platform" label="Platform" source="platform"/>,
    <TextField key="locale" label="Locale" source="localeCode"/>,
    <EditButton key="editBtn"/>,
  ];
};

export const TranslationList = (props: any) => {
  return (
    <List
      actions={
        <ListActions
          filterButton={<TransactionFilterButton/>}
          createButton={<CreateButton label="Create translation" resource={AdminResourceName.TRANSLATIONS}/>}
          importComponent={<ImportButton dialogTitle="Import Translations"/>}
          form={<TranslationFilterForm/>}
          maxExportResults={config.EXPORT_LIMIT}
        />
      }
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}
      {...props}
    >
      <GridComponent gridChildren={TranslationListFields()} bulkActionButtons={false} rowClick="show"/>
    </List>
  );
};
