import {
  Datagrid,
  DateField,
  List,
  Pagination,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import { AdminResourceName } from '../../resource-name.enum';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';

export const NotificationTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>"Notification: {record.title}"</span>;
}

const NotificationShowLayout =(props:any)=>{
  const record = useRecordContext();

  return (<SimpleShowLayout {...props}>
    <TextField label="Id" source="id"/>
    <TextField label="Title" source="title"/>
    <RichTextField label="Subtitle" source="content" defaultValue=""/>
    <RichTextField label="Text" source="text" defaultValue=""/>
    {/*<ReferenceArrayField label="Recipient list" source="userList" reference={AdminResourceName.USER}>*/}
    {/*  <Datagrid bulkActionButtons={false}>*/}
    {/*    <FunctionField label="Account id"*/}
    {/*                   render={(record: any) => record.individualAccId ? record.individualAccId : record.currentAccountId}/>*/}
    {/*    <TextField label="Email" source="email"/>*/}
    {/*    <TextField label="Country code" source="profile.countryIsoCode"/>*/}
    {/*  </Datagrid>*/}
    {/*</ReferenceArrayField>*/}
    <List resource={AdminResourceName.USER}
           filterDefaultValues={record?.userList.length > 0 ? { ids: record.userList } : undefined} exporter={false}
           pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid bulkActionButtons={false}>
        <TextField label="Id" source="id"/>
        <TextField label="Email" source="email"/>
        <TextField label="Country" source="profile.countryIsoCode"/>
      </Datagrid>
    </List>
    <DateField label="Created At" source="createdAt" showTime/>
    <DateField label="Updated At" source="updatedAt" showTime/>
  </SimpleShowLayout>)
}

export const NotificationShow = () => {
  return (<Show
    title={<NotificationTitle/>}
  >
    <NotificationShowLayout/>
  </Show>)
}