import { AdminResource } from "../resources";
import { IResourcePermission, RoleAction } from "../components/role";
import { AdminResourceName } from "../resource-name.enum";

export function filterContentByPermissions(contentArray: AdminResource[], permissions: IResourcePermission[]): AdminResource[] {
  return contentArray.filter((props) => {
    return !!permissions.find(({ action, resource }) => {
      return resource === props.name || resource === AdminResourceName.ALL
    })
  });
}

export function excludeContentByActionPermission(contentArray: AdminResource[], permissions: IResourcePermission[]): AdminResource[] {
  return contentArray.map((props) => {
    const actions = permissions.find(({ resource }) => (resource === props.name || resource === AdminResourceName.ALL))?.action;
    return excludeSubContentByAction(props, actions);
  })
}

export function excludeSubContentByAction(resource: AdminResource, actions: RoleAction | RoleAction[] | undefined): AdminResource {
  const { list, show, create, edit, ...restProps } = resource;
  if (!actions) return restProps;
  if (actions.includes(RoleAction.READ)) {
    return { ...restProps, list, show };
  }
  if (actions.includes(RoleAction.EDIT)) return { ...restProps, list, show, edit, };
  if (actions === RoleAction.ALL || actions.includes(RoleAction.CREATE)) return resource;
  return restProps;
}

export function removeEmptyContentResource(contentArray: AdminResource[]): AdminResource[] {
  return contentArray.filter(({ list, show, edit, create }) => list || show || edit || create)
}

