export enum SavingPlanType {
  Limited = 'LIMITED',
  Flexible = 'FLEXIBLE',
  Combo = 'COMBO',
}

export interface ISavingPlan extends Record<string, any> {
  id: string;
  title: string | null;
  yearPercentage: string;
  duration: number | null;
  minimumAmount: string;
  assetId: string;
  bonusPercent: string | null;
  type: SavingPlanType;

  createdAt: Date;
  updatedAt: Date;
}
