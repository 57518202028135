import { RichTextInput } from 'ra-input-rich-text';
import { Edit, SelectInput, SimpleForm } from 'react-admin'
import { DocumentTypeChoices } from '../document';

export const DocumentEdit = () => {
  return (
    <Edit>
      <SimpleForm warnWhenUnsavedChanges>
        <SelectInput name="type" source="type" choices={DocumentTypeChoices}/>
        <RichTextInput source="content"/>
      </SimpleForm>
    </Edit>
  )
}