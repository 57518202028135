import { BooleanField, CreateButton, Datagrid, DateField, List, Pagination, TextField } from 'react-admin';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { ListActions } from '../list-actions';

export const IntercashDeliveryList = () => {
  return (
    <List
      actions={<ListActions createButton={<CreateButton/>}/>}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id"/>
        <TextField label="Delivery Method" source="deliveryMethod"/>
        <TextField label="Delivery Fee" source="deliveryFee"/>
        <TextField label="Delivery title" source="title"/>
        <TextField label="Delivery description" source="description"/>
        <BooleanField label="Is Enabled" source="isEnabled"/>
        <DateField label="Created date" source="createdAt" showTime/>
        <DateField label="Updated date" source="updatedAt" showTime/>
      </Datagrid>
    </List>
  )
}