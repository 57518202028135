export enum LtvGradeType{
  Low='low',
  Medium='medium',
  High = 'high',
}

export interface LtvGradation {
id:number;
type:LtvGradeType;
minValue:number;
maxValue:number;
createdAt: Date;
updatedAt: Date;
}