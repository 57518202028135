import { Create, SelectInput, SimpleForm, TextInput } from "react-admin"
import { AppListNames } from "./appListNames.constants";

export const AppListsCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <SelectInput source="listName" choices={AppListNames}/>
        <TextInput name="listValue" source="listValue"/>
      </SimpleForm>
    </Create>
  )
}