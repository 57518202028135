import { BooleanField, DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const TranslationShow = (props: any) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField key="key" label="Key" source="key" />
        <TextField key="text" label="Text" source="text" />
        <TextField key="platform" label="Platform" source="platform"/>
        <TextField key="locale" label="Locale" source="localeCode"/>
        <DateField key="createdAt" source="createdAt" />
        <DateField key="updatedAt" source="updatedAt" />
      </SimpleShowLayout>
    </Show>
  );
};
