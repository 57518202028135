import { Edit, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin"
import { AssetAliasTitle } from "./assetAliasShow";
import { AliasServices } from "./assetAlias.constants";

export const AssetAliasEdit = () => {
  return (
    <Edit title={<AssetAliasTitle/>}>
      <SimpleForm warnWhenUnsavedChanges>
        <SelectInput name="serviceName" source="serviceName" choices={AliasServices}/>
        <TextInput name="serviceCode" source="serviceCode"/>
        <ReferenceInput source="assetId" reference="asset" perPage={100}>
          <SelectInput label="Asset" optionText="title" optionValue="id"/>
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}