import { choicesFromEnum } from '../../@helpers/choices-from-enum';

export enum BusinessAccountType{
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export const AccountTypeChoices = choicesFromEnum(BusinessAccountType);

export enum OrganisationUserRole{
  director = 'I am the only director',
  shareholder = 'I am a shareholder',
  neitherBoth = 'I am neither a director, not a shareholder.',
}

export const OrganisationUserRoleChoices = choicesFromEnum(OrganisationUserRole);