export enum FiatTransactionType {
  Payin = 'Payin',
  Payout = 'Payout',
}

export interface ITransactionModel {
  id: string;
  type: string;
  sendAsset: string;
  sendAmountGross: string;
  sendAmountNet: string;
  receivedAsset: string;
  receivedAmountGross: string;
  receivedAmountNet: string;
  fee: string;
  state: string;
  savingId: string | null;
  beneficiary: string | null;
  reference: string | null;
  payload: any | null;
  source: string;
  destination: string;
  foundsSource: string | null;
  senderId: string | null;
  receiverId: string | null;
  initiatorId: string | null;
  boundedTransactionId: string | null;
  isForTopUp: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface ITransactionListModel {
  id: string;
  userId: string;
  type: string;
  sign: string;
  asset: string;
  amount: string;
  fee: string;
  beneficiary: string | null;
  reference: string | null;
  state: string;
  source: string;
  destination: string;
  foundsSource: string | null;
  senderId: string | null;
  initiatorId: string | null;
  boundedTransactionId: string | null;
  isForTopUp: boolean;
  payload: Record<string, any> | null;
  createdAt: Date;
  updatedAt: Date;
}

export enum CreditTxType {
  CollateralBlocked = 'collateralBlocked',
  CollateralReturned = 'collateralReturned',
  CreditAccrued = 'creditAccrued',
  CreditPaid = 'creditPaid',
}

export const CreditTypes = [CreditTxType.CreditPaid, CreditTxType.CreditAccrued];
export const CreditCollateralTypes = [CreditTxType.CollateralBlocked, CreditTxType.CollateralReturned];
