import { BooleanInput, Edit, NumberInput, SelectArrayInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { AssetTitle } from './assetShow';
import { AssetAddressTypes, AssetTypes } from './asset.constants';
import { AssetIcon } from './assetIcon';
import { TopUpTypeChoices } from './top-up-types.enum';
import { TransferTypeChoices } from './transfer-type.enum';

export const AssetEdit = () => {
  return (
    <Edit title={<AssetTitle />}>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput name="id" disabled source="id" />
        <TextInput name="title" source="title" />
        <TextInput name="shortcode" source="shortCode" />
        <TextInput name="contractAddress" source="contractAddress" />
        <TextInput name="baseCurrency" source="baseCurrency" />
        <NumberInput name="decimals" source="decimals" />
        <TextInput name="color" source="color" />
        {/*TODO: create color view*/}
        <TextInput name="icon" source="icon" multiline fullWidth />
        <AssetIcon label="Icon" />
        <TextInput name="blockExplorer" source="blockExplorer" />
        <SelectInput source="assetType" choices={AssetTypes} />
        <BooleanInput label="isDarkText" source="isDarkText" />
        <BooleanInput label="isIbanSupported" source="isIbanSupported" />
        <BooleanInput label="isExchangeSupported" source="isExchangeSupported" disabled/>
        <SelectInput source="addressType" choices={AssetAddressTypes} />
        <SelectArrayInput source="availableTopUpTypes" choices={TopUpTypeChoices} />
        <SelectArrayInput source="availableTransferTypes" choices={TransferTypeChoices} />
      </SimpleForm>
    </Edit>
  );
};
