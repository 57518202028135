import { TopToolbar } from 'react-admin';
import { CustomExportButton } from '../export';
import { Box } from '@mui/material';

export const ListActions = (props:any)=>{
  const { filterButton, createButton, withoutExport, form, importComponent, ...rest} = props;
  return(
    <Box width="100%">
      <TopToolbar>
        {filterButton}
        {createButton}
        {importComponent}
        {!withoutExport &&<CustomExportButton {...rest}  />}
      </TopToolbar>
      {form}
    </Box>
  )
}