import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

const CreditAssetShowFields = [
  <TextField key="id" source="id"/>,
  <TextField key="type" source="type"/>,
  //TODO: ReferenceField not worked
  // <ReferenceField
  //   key="assetTitle"
  //   label="Title"
  //   source="shortcode"
  //   reference={AdminResourceName.ASSET}
  //   link="show"
  // >
  <TextField key="shortcode" source="shortcode"/>,
  // </ReferenceField>,
  // <ReferenceField
  //   key="shortcode2"
  //   label="Asset"
  //   source="shortcode"
  //   reference={AdminResourceName.ASSET}
  //   link="show"
  // >
  //   <TextField source="shortcode"/>
  // </ReferenceField>,
  <DateField key="createdAt" source="createdAt" showTime/>,
  <DateField key="updatedAt" source="updatedAt" showTime/>,
]

export const CreditAssetShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        {CreditAssetShowFields}
      </SimpleShowLayout>
    </Show>
  )
}