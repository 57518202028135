import { Loading, TextInput, useAuthProvider } from 'react-admin';
import { Box, Button, Card, CardContent, SxProps, Typography } from '@mui/material';
import { HtmlHTMLAttributes, useEffect } from 'react';
import { required, useNotify, useSafeSetState } from 'ra-core';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const TwoFactorPage = (props: TwoFactorProps) => {
  const navigate = useNavigate();
  const notify = useNotify();
  const authProvider = useAuthProvider();
  const [data, setData] = useSafeSetState({ secret: '', qrCodeUrl: '' });
  const [isLoading, setIsLoading] = useSafeSetState(true)
  const [error, setError] = useSafeSetState();

  const form = useForm({
    defaultValues: { token: '' },
  });
  useEffect(() => {
    // reset the entire form after component mount or form defaultValues is ready
    form.reset({ token: '' });
  }, [form.reset]);

  useEffect(() => {
    authProvider.getQRCode()
      .then((data: any) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setError(error);
        setIsLoading(false);
      })
  }, []);

  const onSubmit = (values: any) => {
    authProvider.enableTwoFA(values).then(() => {
      navigate('/');
    }).catch(() => {
      notify('The wrong token. try again', { type: 'error' });
    })
  }

  if (isLoading) return (<Loading/>);
  if (error) return (<div>Error</div>);

  return (
    <CustomCard {...props}>
      <CardContent className={TwoFAClasses.cardContent}>
        <Typography gutterBottom variant="h6" component="div">
          For further work, you need to activate two-factor authentication on your account
        </Typography>
        <Typography gutterBottom variant="body2" component="div">
          Please use Authenticator. Scan QR-code or use secret below.
        </Typography>
        <Typography variant="body2" color="text.secondary" component="div">
          {data?.secret}
        </Typography>
        <Typography variant="body2" component="div">
          {data?.qrCodeUrl && <img src={data.qrCodeUrl}/>}
        </Typography>
        {data?.secret &&
        <FormProvider {...form} >
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Box display="flex" alignItems="center" flexDirection="column" p={2}>
                    <Box component="span" mb={1}>
                        <TextInput
                            resettable
                            label="2fa token"
                            helperText={false}
                            source="token"
                            validate={required()}/>
                    </Box>
                    <Box component="span">
                        <Button variant="contained" color="primary" type="submit">
                            Activate 2fa
                        </Button>
                    </Box>
                </Box>
            </form>
        </FormProvider>
        }
      </CardContent>
    </CustomCard>
  )
}

export interface TwoFactorProps extends HtmlHTMLAttributes<HTMLDivElement> {
  className?: string;
  sx?: SxProps;
}

const PREFIX = 'RaTwoFA';
export const TwoFAClasses = {
  cardContent: `${PREFIX}-card-content`,
};

const CustomCard = styled(Card, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  minWidth: 300,
  marginTop: '3em',
  marginBottom: '3em',
  overflow: 'scroll',

  [`& .${TwoFAClasses.cardContent}`]: {
    margin: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

TwoFactorPage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}