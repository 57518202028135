import { Create, SimpleForm, TextInput,SelectInput } from 'react-admin'
import { SocialLinkCodeChoices } from './constants';

export const SocialLinkCreate=()=>{
  return (
    <Create>
      <SimpleForm>
        <SelectInput name="code" label="Social link code" source="code" choices={SocialLinkCodeChoices} defaultValue={SocialLinkCodeChoices[0]} />
        <TextInput name="description" label="Description" source="description"/>
        <TextInput name="value"  label="Value, the link itself" source="value"/>
        </SimpleForm>
    </Create>
  )
}