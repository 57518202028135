import { Button } from '@mui/material';
import {
  List,
  DateField,
  ReferenceField,
  TextField,
  ShowButton,
  FunctionField,
  useRedirect,
  CreateButton,
  usePermissions,
  Pagination,
  useRecordContext,
} from 'react-admin';
import { TransactionFilterButton } from '../transaction';
import { AdminResourceName } from '../../resource-name.enum';
import { WalletFilterForm } from '../wallets';
import { WalletBalance } from './wallet-balance';
import { fullName, getProviderNumber, organizationName } from '../../@helpers/wallet.helpers';
import { RoleAction, RolePermissions } from '../role';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { owner } from '../../@helpers/account.helpers';
import { GridComponent } from '../grid/grid-component';
import { uriStringify } from '../../@helpers/uri-stringify';
import { ListActions } from '../list-actions';
import { config } from '../../config';

const SeeTransactionsButton = (props: any) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  if(!record) return null;
  const handleSeeTransactionClick = () => {
    redirect('list', `${AdminResourceName.TRANSACTION_ALL}?filter=${uriStringify({
      asset: record.asset,
      accountId: record.accountId
    })}`);
  }
  return (
    <Button onClick={handleSeeTransactionClick} children={<span>See transactions</span>}/>
  )
}

const WalletsListFields =()=>{
  return [
    <TextField key="id" source="id"/>,
    <WalletBalance key="balance" label="balance"/>,
    <TextField key="asset" source="asset"/>,
    <FunctionField key="providerNumber" label="Provider number" render={(record: any) =>
      getProviderNumber(record)
    }/>,
    <ReferenceField key="AccountNumber" label="Account number" source="accountId" reference={AdminResourceName.BUSINESS_ACCOUNT}
                    link="show">
      <TextField source="accountNumber"/>
    </ReferenceField>,
    <ReferenceField key="ownerEmail" label="Email" source="accountId" reference={AdminResourceName.BUSINESS_ACCOUNT}
                    link="show">
      <FunctionField render={(record: any) => owner(record)?.email}/>
    </ReferenceField>,
    <ReferenceField key="ownerName" label="Client's name" source="accountId" reference={AdminResourceName.BUSINESS_ACCOUNT}
                    link="show">
      <FunctionField render={(record: any) => fullName(owner(record))}
      />
    </ReferenceField>,
    <FunctionField key="legalName" label="Legal name"
                   render={(record: any) => organizationName(record)}
    />,
    <DateField key="createdAt" source="createdAt" showTime/>,
    <SeeTransactionsButton key="seeTransactionsBtn" label="See transactions" />,
    <ShowButton key="showBtn" />,
  ]
}

export const WalletList = () => {
  const {isLoading,permissions} = usePermissions();
  if(isLoading) return null;
  const createAccrualTransactionPermission = RolePermissions[permissions].find(({ action, resource }) => {
    return (resource === AdminResourceName.TRANSACTION_ALL && action === RoleAction.CREATE) ||
      (resource === AdminResourceName.ALL &&
        (action === RoleAction.CREATE || action === RoleAction.ALL)
      )
  });

  return (
    <List
      actions={<ListActions filterButton={<TransactionFilterButton/>} createButton={createAccrualTransactionPermission && <CreateButton label="Create transaction" resource={AdminResourceName.TRANSACTION_ALL}/>} form={<WalletFilterForm/>} maxExportResults={config.EXPORT_LIMIT}/>}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}
    >
      <GridComponent bulkActionButtons={false} gridChildren={WalletsListFields()}/>
    </List>
  )
}