
export enum AppNamesEnum {
  WALLEX_NEOBANKING = 'Neobanking admin panel',
  WALLEX_CUSTODY_PRO = 'Custody PRO admin panel',
  WALLEX_BG = 'Wallex.BG admin panel',
  WALLEX_TOKASH = 'Tokash admin panel',
  WALLEX_EQAPITAL = 'Eqapital admin panel',
  WALLEX_BEBANKER = 'BeBanker admin panel',
  WALLEX_PRIME = 'Wallex Prime admin panel',
  WALLEX_BITGENERA = 'Bitgenera admin panel'
}
export const config = {
  API_HOST: process.env.REACT_APP_API_HOST || 'http://localhost:3000',
  APP_NAME: process.env.REACT_APP_NAME || AppNamesEnum.WALLEX_NEOBANKING,
  MODULES: {
    IS_INTERCASH_CARD_INCLUDED: process.env.REACT_APP_INCLUDE_MODULE_INERCASH_CARDS ? JSON.parse(process.env.REACT_APP_INCLUDE_MODULE_INERCASH_CARDS) : false,
    IS_SAVING_INCLUDED: process.env.REACT_APP_INCLUDE_MODULE_SAVING ? JSON.parse(process.env.REACT_APP_INCLUDE_MODULE_SAVING) : false,
    IS_IBAN_SERVICE_INCLUDED: process.env.REACT_APP_INCLUDE_IBAN_SERVICE_MODULE?JSON.parse(process.env.REACT_APP_INCLUDE_IBAN_SERVICE_MODULE) : false,
    IS_REFERRAL_PROGRAM_INCLUDED:process.env.REACT_APP_REFERRAL_PROGRAM_INCLUDED?JSON.parse(process.env.REACT_APP_REFERRAL_PROGRAM_INCLUDED): false,
  },
  EXPORT_LIMIT: getExportLimit(),
  TINYMCE_API_KEY: process.env.REACT_APP_TINYMCE_API_KEY || 'ue85fa9mihbq3gvjq9wl3k9o3wlz46km2tao5agzgdudh7u3',
}

function getExportLimit(){
  const envVar = Number(process.env.REACT_APP_EXPORT_LIMIT);
  if(isNaN(envVar)){
    return 20000;
  }
  return envVar;
}
