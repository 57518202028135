import { TextField, useGetOne, useRecordContext } from 'react-admin';
import { IdTypeEnum } from '../balance';
import { AdminResourceName } from '../../resource-name.enum';

export const  WalletBalance = (props:any)=>{
  const record = useRecordContext();
  // console.log('WalletBalance=>record')
  // console.log(record)
  const { data, isLoading, error } = useGetOne(`${AdminResourceName.BALANCE}/${IdTypeEnum.WALLET}`, {
    id: record?.id
  })
  if(!record) return null;
  if (isLoading || error || !data?.balance) return null

  return(
    <TextField record={data} source='balance'/>
  )
}