import { Create, SelectInput, SimpleForm, TextInput, useGetList } from "react-admin";
import { AdminResourceName } from '../../resource-name.enum';

export const AdminCreate = () => {
  const { data, total, isLoading, error } = useGetList(
    AdminResourceName.ROLE
  );

  return (
    <Create>
      <SimpleForm>
        <TextInput name="email" source="email"/>
        <TextInput name="password" source="password"/>
        <TextInput name="firstName" source="firstName"/>
        <TextInput name="lastName" source="lastName"/>
        {!isLoading &&
        !error &&
        <SelectInput name="roleId" label="Role" source="roleId" choices={data} optionText="roleType"
                     optionValue="id"/>}
      </SimpleForm>
    </Create>
  )
};