import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  Pagination,
  ReferenceField,
} from 'react-admin';
import {
  AmountField,
  SourceDestDataField, TransactionFilterButton, TransactionFilterForm,
  TransactionListButton
} from '../transaction';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { AdminResourceName } from '../../resource-name.enum';
import { fullName } from '../../@helpers/wallet.helpers';
import { owner } from '../../@helpers/account.helpers';
import { ListActions } from '../list-actions';
import { config } from '../../config';

export const TransactionHandlingList = () => {
  return (
    <List
      actions={<ListActions filterButton={<TransactionFilterButton/>} form={<TransactionFilterForm/>}
                            maxExportResults={config.EXPORT_LIMIT}/>}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid bulkActionButtons={false}>
        <TextField label="id" source="transactionId"/>
        <FunctionField key="type" label="type" render={(record: any) => {
          return record.type !== 'TRANSFER' ? record.type : record.sign === '-' ? 'OUTGOING' : 'INCOMING'
        }}/>
        <AmountField key="amount" label="Amount"/>
        <FunctionField key="fee" label="Fee" render={(record: any)=>{
          return `-${record.fee} ${record.asset}`
        }}/>
        <TextField source="state"/>
        <TextField label="Status" source="payload.txSubStatus"/>
        <SourceDestDataField key="account" label="Account"/>
        <ReferenceField label="User" source="userId" reference={AdminResourceName.BUSINESS_ACCOUNT} link="show">
          <FunctionField render={(record: any) => fullName(owner(record))}/>
        </ReferenceField>
        <DateField source="createdAt" showTime/>
        <TransactionListButton key="showTx" btnType="show" resource={AdminResourceName.TRANSACTION_APPROVAL}/>
      </Datagrid>
    </List>
  )
}