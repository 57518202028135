import {
  DateField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
  usePermissions,
  useRecordContext
} from "react-admin"

export const NewsTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>"News: {record.title}"</span>;
}

export const NewsShow = () => {
  const { permissions } = usePermissions();
  return (
    <Show title={<NewsTitle/>}>
      <SimpleShowLayout>
        <TextField label="News Id" source="id"/>
        <TextField label="News title" source="title"/>
        <RichTextField label="News content" source="content"/>
        <DateField label="Created date" source="createdAt" showTime/>
        <DateField label="Updated date" source="updatedAt" showTime/>
      </SimpleShowLayout>
    </Show>
  )
}