import { ListActions } from '../list-actions';
import {
  List,
  Datagrid,
  Pagination,
  TextField,
  useRecordContext,
  useGetOne,
  FunctionField, DateField
} from 'react-admin';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { AdminResourceName } from '../../resource-name.enum';
import { IReferralsData, IReferrerData } from './interfaces';
import { FilterButton } from '../filter';
import { Filter } from '../filter';
import { FilterForm } from './filter-form';

export const ReferrersExpandedData = () => {
  const record = useRecordContext();
  const {
    data,
    isError,
    isLoading
  } = useGetOne<IReferrerData>(AdminResourceName.REFERRAL_PROGRAM_STATISTICS, { id: record?.id })
  if (isError) {
    return null;
  }
  if (!data || isLoading) return null;
  return (
    <Datagrid data={data.referrals} bulkActionButtons={false}>
      <TextField label="Referral Id" source="id"/>
      <FunctionField label="Referrer email" render={(_: any) => record.email}/>
      <TextField label="Referral's email" source="email"/>
      <DateField showTime={true} label="Referral's join date" source="createdAt"/>
      <FunctionField label="Referrer's reward"
                     render={({ referrerReward }: IReferralsData) => referrerReward?.transactionId ? `${referrerReward.amount} ${referrerReward.asset}` : 'No reward accrued'}/>
      <FunctionField label="Referral's reward"
                     render={({ referralReward }: IReferralsData) => referralReward?.transactionId ? `${referralReward?.amount} ${referralReward?.asset}` : 'No reward accrued'}/>
      <DateField showTime={true} label="Reward's date" source="rewardDate"/>

    </Datagrid>
  );
}

const RewardsTotalAmount = (props: any) => {
  const record = useRecordContext();
  const {
    data,
    isLoading,
    error
  } = useGetOne(AdminResourceName.REFERRAL_PROGRAM_STATISTICS_TOTAL_AMOUNT, { id: record.id })
  if (isLoading || error
  ) return null;
  return (
    <TextField {...props} record={data} source="total"/>
  )
}

export const ReferrersStatisticsList = () => {

  return (
    <List
      actions={<ListActions form={<Filter formFields={FilterForm} />} filterButton={<FilterButton/>} withoutExport={true}/>}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid bulkActionButtons={false} expand={<ReferrersExpandedData/>} expandSingle={true}>
        <TextField label="Id" source="id"/>
        <TextField label="Referrer email" source="email"/>
        <TextField label="User name" source="userName"/>
        <TextField label="Client type" source="userType"/>
        <TextField label="Legal name" source="legalName"/>
        <TextField label="Referral code" source="referralCode"/>
        <TextField label="Referrals number" source="referralsNumber"/>
        <RewardsTotalAmount label="Referrer's total reward amount EUR"/>
      </Datagrid>
    </List>
  )
}