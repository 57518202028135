import { useState } from 'react';
import {
  Form,
  NumberInput,
  SaveButton,
  TextInput,
  useCreate,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useFormState } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
import { AdminResourceName } from '../../resource-name.enum';
import { CreateTxMode } from '../transaction';

export const SubmitButton = (props: any) => {
  const { isDirty, isValid } = useFormState();
  return (
    <SaveButton disabled={!isDirty || !isValid} {...props} />
  );
}

export function AccountCreateTransaction(props: any) {
  const { accountId } = props
  const record = useRecordContext();
  const refresh = useRefresh;
  const [showDialog, setShowDialog] = useState(false);
  const [create] = useCreate();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleSubmit = async (values: any) => {
    const data = {
      amount: values.amount,
      source: values.source,
      asset: values.asset,
      receiverId: values.receiverId,
      reference: values.reference,
      mode: CreateTxMode.ALL,
    }
    await create(
      AdminResourceName.TRANSACTION_ALL,
      { data }
    );
    setShowDialog(false);
    refresh();
  }

  return (
    <>
      <Button onClick={handleClick}>
        <span>Create Transaction</span>
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Transaction"
      >
        <DialogTitle>Create Transaction</DialogTitle>
        <Form onSubmit={handleSubmit}>
          <DialogContent>
            <NumberInput
              label="Amount to send"
              source="amount"
              name="amount"
              fullWidth
              min={0}
            />
            <TextInput
              label="Source address"
              source="source"
              name="source"
              fullWidth
            />
            <TextInput
              label="Asset"
              source="asset"
              name="asset"
              fullWidth
              disabled
              defaultValue={record.asset}
            />
            <TextInput
              label="Reference"
              source="reference"
              name="reference"
              fullWidth
              defaultValue={record.reference}
            />
            <TextInput
              label="Receiver account"
              source="receiverId"
              name="receiverId"
              fullWidth
              defaultValue={accountId}
              disabled
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseClick}
            >
              <span>Cancel</span>
            </Button>
            <SubmitButton>Save</SubmitButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
