import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  useEditContext,
  useGetList,
  useGetOne
} from 'react-admin';
import { CreditAssetType, CreditAssetTypeChoices } from './constants';
import { AdminResourceName } from '../../../resource-name.enum';


const EditAssetsInput = (props: {
  data: Record<string, any>
}) => {
  const { record } = useEditContext();
  const { data, ...rest } = props;

  return (
    <ReferenceInput {...rest} name="shortcode" source="shortcode" reference={AdminResourceName.ASSET} perPage={100}
                    filter={{
                      shortcodes: data[record.type],
                    }}>
      <AutocompleteInput helperText={false} label="Asset" optionText="shortcode" optionValue="shortcode"
                         filterToQuery={(value: any) => ({ shortcode: value })}
                         fullWidth={true}/>
    </ReferenceInput>
  )
}

export const CreditAssetEdit = () => {
  const { data: listData, isLoading: isListLoading } = useGetList<{
    id: string,
    type: CreditAssetType,
    shortcode: string
  }>(AdminResourceName.CREDIT_ASSET)
  const { data, isLoading } = useGetOne<{ id: string, CREDIT: string[], COLLATERAL: string[] }>(
    `${AdminResourceName.CREDIT_ASSET}/allowed`,
    { id: 'shortcodes' }
  )
  if (!data || isLoading) return null;
  if (!listData || isListLoading) return null;

  const formValidation = ({ type, shortcode }: any) => {
    const errors: { type?: string, shortcode?: string } = {};
    const isExisted = listData.find((creditAsset) => {
      return creditAsset.type === type && creditAsset.shortcode === shortcode
    })
    if (isExisted) {
      errors.shortcode = `The asset ${type} ${shortcode} is already existed!`;
    }
    if (!data[type as CreditAssetType]?.includes(shortcode)) {
      errors.shortcode = `The asset ${shortcode} not allowed for type ${type}`
    }
    if (!type) {
      errors.type = 'The type can`t be empty!'
    }
    if (!shortcode) {
      errors.shortcode = 'The shortcode can`t be empty!'
    }
    return errors
  }

  return (
    <Edit redirect="list" mutationMode="pessimistic">
      <SimpleForm warnWhenUnsavedChanges validate={formValidation}>
        <SelectInput name="type" source="type" choices={CreditAssetTypeChoices} fullWidth={true}
                     disabled={true}/>
        <EditAssetsInput data={data}/>
      </SimpleForm>
    </Edit>
  )
}