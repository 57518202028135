import {
  Pagination,
  usePermissions,
  List,
  TextField,
  FunctionField,
  ReferenceField,
  ShowButton,
  DateField, useGetOne
} from 'react-admin';
import { GridComponent } from '../../grid/grid-component';
import { ListActions } from '../../list-actions';
import { ROWS_PER_PAGE } from '../../pagination/pagination.const';
import { CreditPlanModel } from './interfaces';
import { differenceInCalendarDays } from 'date-fns';
import { AdminResourceName } from '../../../resource-name.enum';
import { fullName } from '../../../@helpers/wallet.helpers';
import { owner } from '../../../@helpers/account.helpers';
import { BusinessAccountModel } from '../../business-account';
import { FilterForm } from './filter-form';
import { Filter, FilterButton } from '../../filter';
import { uriStringify } from '../../../@helpers/uri-stringify';
import { TransactionType } from '../../transaction';

const CreditsListFields = [
  <TextField key="id" source="id"/>,
  <ReferenceField key="collateralWallet" label="Collateral WalletId" reference={AdminResourceName.WALLET}
                  source="collateralWalletId" link="show">
    <TextField source="id"/>
  </ReferenceField>,
  <TextField key="title" source="title"/>,
  <FunctionField key="duration" label="Duration (days)" render={(record: CreditPlanModel) => {
    return differenceInCalendarDays(new Date(record.maturityDate), new Date(record.createdAt))
  }}/>,
  <FunctionField key="interest" label="Interest" render={(record: CreditPlanModel) => {
    return `${Number(record.aprValue) * 100}%`
  }}/>,
  <FunctionField key="collateralAmountAsset" label="Collateral Currency" render={(record: CreditPlanModel) => {
    const { collateralAmount, id } = record;
    return (
      <ReferenceField record={record}
                      key="collateralAsset"
                      label="Collateral Currency"
                      reference={AdminResourceName.WALLET}
                      source="collateralWalletId"
                      link={(record, reference) => {
                        return `/${AdminResourceName.TRANSACTION_ALL}?filter=${uriStringify({
                          asset: record.collateralWallet.asset,
                          creditId: id,
                          type: TransactionType.Collateral
                        })}`

                      }}>
        <FunctionField render={(wallet: any) => `${collateralAmount} ${wallet.asset}`}/>
      </ReferenceField>
    )
  }}/>,
  <FunctionField key="creditAmountAsset" label="Credit Currency" render={(record: CreditPlanModel) => {
    const { creditAmount, id } = record;
    return (
      <ReferenceField record={record}
                      key="creditAsset"
                      label="Credit Currency"
                      reference={AdminResourceName.WALLET}
                      source="creditWalletId"
                      link={(record, reference) => {
                        return `/${AdminResourceName.TRANSACTION_ALL}?filter=${uriStringify({
                          asset: record.creditWallet.asset,
                          creditId: id,
                          type: TransactionType.Credit
                        })}`

                      }}
      >
        <FunctionField render={(wallet: any) => `${creditAmount} ${wallet.asset}`}/>
      </ReferenceField>
    )
  }}/>,
  <FunctionField key="left" label="Days left" render={(record: CreditPlanModel) => {
    const left = differenceInCalendarDays(new Date(record.maturityDate), new Date())
    if (record.closeDate) {
      return 'Closed'
    }
    return left < 0 ? 'Expired' : left;
  }}/>,
  <DateField key="startDate" label="Start Date" source="createdAt" showTime/>,
  <DateField key="finishDate" label="Finish Date" source="closeDate" showTime/>,
  <DateField key="maturityDate" label="Maturity Date" source="maturityDate" showTime/>,
  <ReferenceField key="clientsName" label="Client`s Name" reference={AdminResourceName.BUSINESS_ACCOUNT}
                  source="accountId" link="show">
    <FunctionField render={(record: BusinessAccountModel) => fullName(owner(record))}/>
  </ReferenceField>,
  <ShowButton key="showButton"/>,
]

export const CreditPlanList = () => {
  const { isLoading } = usePermissions();
  const { data, isLoading: isAssetLoading } = useGetOne<{ id: string, CREDIT: string[], COLLATERAL: string[] }>(
    `${AdminResourceName.CREDIT_ASSET}/allowed`,
    { id: 'shortcodes' }
  )
  if (isAssetLoading) return null;
  if (isLoading) return null;
  const filterForm = { ...FilterForm };
  // @ts-ignore
  filterForm.creditCurrency.filter = data?.CREDIT ? { shortcodes: data.CREDIT } : {}
  // @ts-ignore
  filterForm.collateralCurrency.filter = data?.COLLATERAL ? { shortcodes: data.COLLATERAL } : {}

  return (
    <List
      actions={<ListActions form={<Filter formFields={filterForm}/>} filterButton={<FilterButton/>}
                            withoutExport={true}/>}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}
    >
      <GridComponent bulkActionButtons={false} gridChildren={CreditsListFields} isGridClick={false}/>
    </List>
  )
}