import { CustomRoutes, Resource } from 'react-admin';
import React from 'react';
import {
  COMMON_RESOURCES,
  CREDIT_RESOURCES,
  IBAN_RESOURCES,
  INTERCASH_RESOURCES,
  REFERRAL_PROGRAM,
  SAVING_RESOURCES
} from '../resources';
import { AdminRole, RolePermissions } from '../components/role';
import {
  excludeContentByActionPermission,
  filterContentByPermissions,
  removeEmptyContentResource
} from '../@helpers/filter-content-by-role';
import { Route } from 'react-router-dom';
import { TwoFactorPage } from '../components/login';

export const MainView = (config: any, permissions: AdminRole) => {
  //TODO: add position property for resources
  const propsView = [
    ...COMMON_RESOURCES,
    ...(config.MODULES.IS_SAVING_INCLUDED ? SAVING_RESOURCES : []),
    ...(config.MODULES.IS_INTERCASH_CARD_INCLUDED ? INTERCASH_RESOURCES : []),
    ...(config.MODULES.IS_IBAN_SERVICE_INCLUDED ? IBAN_RESOURCES : []),
    ...(config.MODULES.IS_REFERRAL_PROGRAM_INCLUDED ? REFERRAL_PROGRAM : []),
    ...CREDIT_RESOURCES,

  ];
  const rolePermissions = RolePermissions[permissions] ? RolePermissions[permissions] : []

  const contentToDraw = removeEmptyContentResource(excludeContentByActionPermission(filterContentByPermissions(propsView, rolePermissions), rolePermissions));
  const resources = contentToDraw.map((props, index) => <Resource key={index} {...props}/>)
  return [
    <CustomRoutes noLayout>
      <Route path="/enable_2fa" element={<TwoFactorPage/>}/>
    </CustomRoutes>,
    ...resources,
  ]
}
