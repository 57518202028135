import React from 'react';
import { Admin } from 'react-admin';
import authProvider from './@services/auth.provider';
import { DataProvider } from './@services/data.provider';
import { config } from './config';
import { MainView } from './views/main-view';
import MyLayout from './views/my-layout';
import { CustomLogin } from './components/login';
import { theme } from './theme';

const dataProvider = new DataProvider(config.API_HOST);

function App() {
  return (
    <Admin
      loginPage={CustomLogin}
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={MyLayout}
      theme={theme}
      requireAuth>
      {permissions => MainView(config, permissions)}
    </Admin>
  );
}

export default App;
