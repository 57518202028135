export class UserProvider {
  _key = 'AP_USER';

  get user(): any {
    const userString = localStorage.getItem(this._key);
    return userString?JSON.parse(userString): { };
  }

  set user(user: any) {
    localStorage.setItem(this._key, JSON.stringify(user));
  }

  clear() {
    localStorage.removeItem(this._key);
  }
}