import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput
} from "react-admin";
import { AdminRolesChoices } from "./admin-role.enum";

export const RoleCreate = () => {
  return (
    <Create title="Create Role">
      <SimpleForm warnWhenUnsavedChanges>
        <SelectInput name="roleType" source="roleType" choices={AdminRolesChoices}/>
        <TextInput name="description" source="description"/>
      </SimpleForm>
    </Create>
  )
};