import { TextField, useRecordContext } from 'react-admin';

export const colorMap = new Map<string,string>([
  ['RED','red'],
  ['GREEN','green'],
])
export const fontWeightMap = new Map([
  ['RED','bold'],
  ])

export const ColoredInput = (props:any)=>{
const record = useRecordContext();
if(!record) return null;
const color = colorMap.get(record.indication) ||'primary'
  const fontWeight =fontWeightMap.get(record.indication)||'regular'

  return(<TextField {...props} sx={{ color:color, fontWeight: fontWeight}}/>)
}