import { BooleanField, EditButton, List, Pagination, TextField } from 'react-admin';
import { ListActions } from '../list-actions';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { GridComponent } from '../grid/grid-component';

const LocaleListFields = () => {
  return [
    <TextField key="code" label="Code" source="code" />,
    <TextField key="name" label="Name" source="name" />,
    <TextField key="flag" label="Flag" source="flag" />,
    <BooleanField key="isActive" label="Is active" source="isActive" />,
    <EditButton key="editBtn" />,
  ];
};

export const LocaleList = (props: any) => {
  return (
    <List actions={<ListActions />} pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />} {...props}>
      <GridComponent gridChildren={LocaleListFields()} bulkActionButtons={false} rowClick="show" />
    </List>
  );
};
