import { Button, useListContext } from "react-admin";

export const UserFilterButton = ()=>{
  const { showFilter } = useListContext();
  return (
    <Button
      size="small"
      color="primary"
      // @ts-ignore
      onClick={() => showFilter("main")}
    >
      <span>Filter</span>
    </Button>
  );
}