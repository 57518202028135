import { AssetCreate, AssetEdit, AssetList, AssetShow } from './components/asset';
import { AssetAliasCreate, AssetAliasEdit, AssetAliasList, AssetAliasShow } from './components/asset-alias';
import { RoleCreate, RoleEdit, RoleList, RoleShow } from './components/role';
import { AdminCreate, AdminEdit, AdminList, AdminShow } from './components/admin';
import { TransactionCreate, TransactionEdit, TransactionList, TransactionShow } from './components/transaction';
import { ApiLogList, ApiLogShow } from './components/api-log';
import { SavingPlanCreate, SavingPlanEdit, SavingPlanList, SavingPlanShow } from './components/saving-plan';
import { SavingList, SavingShow } from './components/saving';
import { BannerCreate, BannerEdit, BannerList, BannerShow } from './components/banner';
import { AppListsCreate, AppListsEdit, AppListsList, AppListsShow } from './components/app-lists';
import { TransactionHandlingList, TransactionHandlingShow } from './components/transaction-handling';
import { UserDeletionList } from './components/user-deletion';
import { DocumentCreate, DocumentEdit, DocumentList, DocumentShow } from './components/document';
import { NewsCreate, NewsEdit, NewsList, NewsShow } from './components/news';
import { IconCreate, IconEdit, IconList, IconShow } from './components/icon';
import {
  IntercashDeliveryCreate,
  IntercashDeliveryEdit,
  IntercashDeliveryList,
  IntercashDeliveryShow,
} from './components/inercash-delivery';
import { ResourceProps } from 'ra-core/src/types';
import { BusinessAccountEdit, BusinessAccountList, BusinessAccountShow } from './components/business-account';
import BalancesReportList from './components/reports/balances-report-list.component';
import { AdminResourceName } from './resource-name.enum';
import { WalletList, WalletShow } from './components/wallets';
import { SystemSettingsEdit, SystemSettingsList, SystemSettingsShow } from './components/system-settings';
import { CardList, CardShow } from './components/cards';
import { FaWallet } from 'react-icons/fa';
import { ImMenu, ImUsers } from 'react-icons/im';
import { TbTableAlias, TbTruckDelivery } from 'react-icons/tb';
import { MdOutlinedFlag, MdSavings, MdSettings, MdTextFields } from 'react-icons/md';
import { HiDocumentReport } from 'react-icons/hi';
import { GrTransaction, GrUserAdmin } from 'react-icons/gr';
import { BsFillCreditCard2BackFill } from 'react-icons/bs';
import { AiOutlineUnorderedList, AiOutlineUserDelete, AiTwotoneApi } from 'react-icons/ai';
import { RiBankCard2Line } from 'react-icons/ri';
import { IbanList, IbanShow } from './components/iban';
import { FBBalanceList } from './components/fireblocks-balance';
import {
  ReferralProgramCreate,
  ReferralProgramEdit,
  ReferralProgramList,
  ReferralProgramShow,
} from './components/referral-program';
import { BalanceReserveEdit, BalanceReserveList, BalanceReserveShow } from './components/balance-reserve-setings';
import { LocaleEdit, LocaleList, LocaleShow } from './components/locales';
import { TranslationCreate, TranslationEdit, TranslationList, TranslationShow } from './components/translations';
import { NotificationCreate, NotificationEdit, NotificationList, NotificationShow } from './components/notification';
import { SocialLinkCreate, SocialLinksEdit, SocialLinkShow, SocialLinksList } from './components/social-links';
import { KrakenBalanceList } from './components/kraken-balance';
import { ReferrersStatisticsList } from './components/referral-statistics';
import {
  CreateLtv,
  LtvList,
  LtvShow,
  LtvEdit,
  PeriodList,
  PeriodShow,
  PeriodEdit,
  CreatePeriod,
  CreditAssetsList,
  CreditAssetShow,
  CreditAssetEdit,
  CreditAssetCreate
} from './components/credit';
import { CreditPlanShow, CreditPlanList } from './components/credit/plans';

export interface AdminResource extends ResourceProps {
}

export const COMMON_RESOURCES: AdminResource[] = [
  {
    name: AdminResourceName.ASSET,
    options: { label: 'Asset List' },
    list: AssetList,
    edit: AssetEdit,
    show: AssetShow,
    create: AssetCreate,
    icon: FaWallet,
  },
  {
    name: AdminResourceName.ASSET_ALIAS,
    options: { label: 'Asset Alias List' },
    list: AssetAliasList,
    edit: AssetAliasEdit,
    show: AssetAliasShow,
    create: AssetAliasCreate,
    icon: TbTableAlias,
  },
  // { name: AdminResourceName.USER, options: { label: 'Users List' }, list: UserList, edit: UserEdit, show: UserShow, },
  {
    name: AdminResourceName.BUSINESS_ACCOUNT,
    options: { label: 'Users' },
    list: BusinessAccountList,
    show: BusinessAccountShow,
    edit: BusinessAccountEdit,
    icon: ImUsers,
  },
  {
    name: AdminResourceName.ROLE,
    options: { label: 'Role List' },
    list: RoleList,
    show: RoleShow,
    edit: RoleEdit,
    create: RoleCreate,
    icon: AiOutlineUnorderedList,
  },
  {
    name: AdminResourceName.ADMIN,
    options: { label: 'Admin accounts', className: '--grey-icon' },
    list: AdminList,
    show: AdminShow,
    edit: AdminEdit,
    create: AdminCreate,
    icon: GrUserAdmin,
  },
  {
    name: AdminResourceName.TRANSACTION_ALL,
    options: { label: 'All Transactions', className: '--grey-icon' },
    list: TransactionList,
    show: TransactionShow,
    edit: TransactionEdit,
    create: TransactionCreate,
    icon: GrTransaction,
  },
  {
    name: AdminResourceName.API_LOG,
    options: { label: 'Api Logs' },
    list: ApiLogList,
    show: ApiLogShow,
    icon: AiTwotoneApi,
  },
  {
    name: AdminResourceName.BANNER,
    options: { label: 'Content settings' },
    list: BannerList,
    show: BannerShow,
    edit: BannerEdit,
    create: BannerCreate,
    icon: MdSettings,
  },
  {
    name: AdminResourceName.APP_LISTS,
    options: { label: 'App lists Settings' },
    list: AppListsList,
    show: AppListsShow,
    edit: AppListsEdit,
    create: AppListsCreate,
    icon: MdSettings,
  },
  {
    name: AdminResourceName.TRANSACTION_APPROVAL,
    options: { label: 'Transactions Pre-approval', className: 'menu-link--sm --grey-icon' },
    list: TransactionHandlingList,
    show: TransactionHandlingShow,
    icon: GrTransaction,
  },
  {
    name: AdminResourceName.USER_DELETION,
    options: { label: 'Requests User Deletion' },
    list: UserDeletionList,
    icon: AiOutlineUserDelete,
  },
  {
    name: AdminResourceName.DOCUMENT,
    options: { label: 'Documents' },
    list: DocumentList,
    show: DocumentShow,
    edit: DocumentEdit,
    create: DocumentCreate,
    icon: HiDocumentReport,
  },
  {
    name: AdminResourceName.NEWS,
    options: { label: 'News' },
    list: NewsList,
    show: NewsShow,
    edit: NewsEdit,
    create: NewsCreate,
    icon: HiDocumentReport,
  },
  {
    name: AdminResourceName.ICON,
    options: { label: 'Icons' },
    list: IconList,
    show: IconShow,
    edit: IconEdit,
    create: IconCreate,
    icon: ImMenu,
  },
  // {
  //   name: AdminResourceName.USER_REPORT,
  //   options: { label: 'Accounts' },
  //   list: AllUsersReportListComponent,
  // },
  {
    name: AdminResourceName.BALANCES_REPORT,
    options: { label: 'Balances Report' },
    list: BalancesReportList,
    icon: HiDocumentReport,
  },
  {
    name: AdminResourceName.WALLET,
    options: { label: 'Accounts (wallets)' },
    list: WalletList,
    show: WalletShow,
    icon: FaWallet,
  },
  {
    name: AdminResourceName.SYSTEM_SETTINGS,
    options: { label: 'System settings' },
    list: SystemSettingsList,
    show: SystemSettingsShow,
    edit: SystemSettingsEdit,
    icon: MdSettings,
  },
  {
    name: AdminResourceName.BALANCE_RESERVE,
    options: { label: 'Balance Reserve' },
    list: BalanceReserveList,
    show: BalanceReserveShow,
    edit: BalanceReserveEdit,
    icon: MdSettings,
  },
  {
    name: AdminResourceName.FIREBLOCKS_BALANCE,
    options: { label: 'Fireblocks balance' },
    list: FBBalanceList,
    icon: MdSavings,
  },
  {
    name: AdminResourceName.KRAKEN_BALANCE,
    options: { label: 'Kraken balance' },
    list: KrakenBalanceList,
    icon: MdSavings,
  },
  {
    name: AdminResourceName.LOCALES,
    options: { label: 'Locales' },
    list: LocaleList,
    show: LocaleShow,
    edit: LocaleEdit,
    icon: MdOutlinedFlag,
  },
  {
    name: AdminResourceName.TRANSLATIONS,
    options: { label: 'Translations' },
    list: TranslationList,
    show: TranslationShow,
    edit: TranslationEdit,
    create: TranslationCreate,
    icon: MdTextFields,
  },
  {
    name: AdminResourceName.NOTIFICATION,
    options: { label: 'Push notifications' },
    list: NotificationList,
    show: NotificationShow,
    edit: NotificationEdit,
    create: NotificationCreate,
    icon: HiDocumentReport,
  }
];

export const INTERCASH_RESOURCES: AdminResource[] = [
  {
    name: AdminResourceName.INTERCASH_DELIVERY,
    options: { label: 'Delivery settings' },
    list: IntercashDeliveryList,
    show: IntercashDeliveryShow,
    edit: IntercashDeliveryEdit,
    create: IntercashDeliveryCreate,
    icon: TbTruckDelivery,
  },
  {
    name: AdminResourceName.CARD,
    options: { label: 'Cards' },
    list: CardList,
    show: CardShow,
    icon: BsFillCreditCard2BackFill,
  },
];

export const SAVING_RESOURCES: AdminResource[] = [
  {
    name: AdminResourceName.SAVING_PLAN,
    options: { label: 'Saving Plans' },
    list: SavingPlanList,
    show: SavingPlanShow,
    edit: SavingPlanEdit,
    create: SavingPlanCreate,
    icon: MdSavings,
  },
  {
    name: AdminResourceName.USER_SAVING,
    options: { label: 'Savings list' },
    list: SavingList,
    show: SavingShow,
    icon: MdSavings,
  },
];

export const IBAN_RESOURCES: AdminResource[] = [
  {
    name: AdminResourceName.IBAN,
    options: { label: 'CJ Iban' },
    list: IbanList,
    show: IbanShow,
    icon: RiBankCard2Line,
  },
];

export const REFERRAL_PROGRAM: AdminResource[] = [
  {
    name: AdminResourceName.REFERRAL_PROGRAM,
    options: { label: 'Referral Program' },
    list: ReferralProgramList,
    show: ReferralProgramShow,
    edit: ReferralProgramEdit,
    create: ReferralProgramCreate,
    icon: MdSettings,
  },
  {
    name: AdminResourceName.REFERRAL_PROGRAM_SOCIAL_LINKS,
    options: { label: 'Referral Links' },
    list: SocialLinksList,
    show: SocialLinkShow,
    create: SocialLinkCreate,
    edit: SocialLinksEdit,
    icon: MdSettings,
  },
  {
    name: AdminResourceName.REFERRAL_PROGRAM_STATISTICS,
    options: { label: 'Referral Statistics' },
    list: ReferrersStatisticsList,
    icon: MdSettings,
  },
];

export const CREDIT_RESOURCES: AdminResource[] = [
  {
    name: AdminResourceName.LTV_GRADATION,
    options: { label: 'LTV% Config' },
    list: LtvList,
    show: LtvShow,
    edit: LtvEdit,
    create: CreateLtv,
    icon: MdSettings,
  },
  {
    name: AdminResourceName.CREDIT_PERIOD,
    options: { label: 'Periods' },
    list: PeriodList,
    show: PeriodShow,
    edit: PeriodEdit,
    create: CreatePeriod,
    icon: MdSettings,
  },
  {
    name: AdminResourceName.CREDIT_ASSET,
    options: { label: 'Allowed Assets' },
    list: CreditAssetsList,
    show: CreditAssetShow,
    edit: CreditAssetEdit,
    create: CreditAssetCreate,
    icon: MdSettings,
  },
  {
    name: AdminResourceName.CREDIT_PLAN,
    options: { label: 'Credit Logs' },
    list: CreditPlanList,
    show: CreditPlanShow,
    icon: MdSettings,
  },
]
