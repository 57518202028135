import { ListActions } from '../list-actions';
import {
  CreateButton,
  Datagrid,
  List,
  EditButton,
  Pagination,
  TextField,
  DateField,
  usePermissions,
  ArrayField,
} from 'react-admin';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { RoleAction, RolePermissions } from '../role';
import { AdminResourceName } from '../../resource-name.enum';

export const ReferralProgramList = () => {
  const { permissions } = usePermissions();
  if (!permissions) return null;
  const resourcePermissions = RolePermissions[permissions].find((elem) => elem.resource === AdminResourceName.REFERRAL_PROGRAM || elem.resource===AdminResourceName.ALL);
  const allowedAll = resourcePermissions?.action === RoleAction.ALL
  const allowedCreate = resourcePermissions?.action === RoleAction.CREATE;
  const allowedEdit = resourcePermissions?.action === RoleAction.EDIT;
  return (
    <List
      actions={<ListActions createButton={(allowedCreate || allowedAll) && <CreateButton/>}/>}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id"/>
        <TextField source="title"/>
        <TextField source="description" defaultValue=""/>
        <TextField source="state"/>
        <TextField source="userTypeAffected"/>
        <ArrayField source="rewardAssetChoices">
          <Datagrid bulkActionButtons={false}>
            <TextField source="rewardAsset"/>
            <TextField source="rewardFollowerAmount"/>
            <TextField source="rewardOwnerAmount"/>
          </Datagrid>
        </ArrayField>
        <TextField source="rewardTriggerAsset"/>
        <TextField source="rewardTriggerAmount"/>
        <DateField source="startDateAt" showTime/>
        <DateField source="createdAt" showTime/>
        {(allowedEdit || allowedCreate || allowedAll) && <EditButton/>}
      </Datagrid>
    </List>
  )
}