import { BooleanInput, Edit, NumberInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import { DeliveryMethodChoices } from "./deliveryMethodChoices";

export const formatDeliveryFee = (value: number | null) => {
  if (value === null) {
    return null
  } else {
    return String(value)
  }
}

export const parseDeliveryFee = (value: number | string) => {
  if (value === '') {
    return null;
  } else {
    return String(value)
  }
}

export const IntercashDeliveryEdit = () => {
  return (
    <Edit>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput label="Id" name='id' source='id' disabled/>
        <TextInput label="Delivery title" source="title"/>
        <TextInput label="Delivery description" source="description"/>
        <SelectInput label="Delivery Method" name='deliveryMethod' source='deliveryMethod'
                     choices={DeliveryMethodChoices}/>
        <NumberInput label="Delivery Fee" source="deliveryFee" min={0} format={formatDeliveryFee} parse={parseDeliveryFee}/>
        <BooleanInput label="Is Enabled" source="isEnabled"/>
        {/*<TextInput label="icon" name='icon' source='icon'/>*/}
      </SimpleForm>
    </Edit>
  )
}
