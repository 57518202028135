import { choicesFromEnum } from "../../@helpers/choices-from-enum";

export enum UserStateFilter {
  hide = 'hide',
  only = 'only',
  all = 'all',
}

export enum UserStateFilterName {
  hide = 'hide deleted',
  only = 'only deleted',
  all = 'all existed',
}

export const UserStateFilterChoices = choicesFromEnum(UserStateFilterName);