import { DeleteButton, SaveButton } from 'react-admin';
import { Toolbar } from '@mui/material';

export const CustomToolbar = (props: any) => {
  const { saveButton, deleteButton, ...restProps } = props;
  return (
    <Toolbar {...restProps}>
      {saveButton && <SaveButton/>}
      {deleteButton && <DeleteButton/>}
    </Toolbar>
  )
}