import {
  CreateButton,
  DateField,
  List,
  Pagination,
  ShowButton,
  TextField,
  usePermissions
} from 'react-admin';
import { ROWS_PER_PAGE } from '../../pagination/pagination.const';
import { GridComponent } from '../../grid/grid-component';
import { ListActions } from '../../list-actions';

const CreditAssetsListFields=[
  <TextField key="id" source="id" />,
  //TODO: ReferenceField not worked
  // <ReferenceField
  //   key="assetTitle"
  //   label="Title"
  //   source="shortcode"
  //   reference={AdminResourceName.ASSET}
  //   link="show"
  // >
    <TextField key="shortcode" source="shortcode" />,
  // </ReferenceField>,
  // <ReferenceField
  //   key="shortcode"
  //   label="Asset"
  //   source="shortcode"
  //   reference={AdminResourceName.ASSET}
  //   link="show"
  // >
  //   <TextField source="shortcode" />
  // </ReferenceField>,
  <TextField key="type" source="type"/>,
  <DateField key="createdAt" source="createdAt" showTime/>,
  <ShowButton key="showBtn" />,
]

export const CreditAssetsList = ()=>{
  const { isLoading } = usePermissions();
  if (isLoading) return null;
  return(
    <List
      actions={<ListActions createButton={<CreateButton/>}/>}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
    >
      <GridComponent bulkActionButtons={false} gridChildren={CreditAssetsListFields} />
    </List>
  )
}