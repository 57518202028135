import * as React from 'react';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSidebarState, useTranslate } from 'react-admin';
import { theme } from '../theme';

const PREFIX = 'RaMenuItemLink';
const MenuItemLinkClasses = {
  icon: `${PREFIX}-icon`,
};

const minWidthIcon = '40px';

export const SubMenu = (props: SubMenuProps) => {
  const { isDropdownOpen = false, primaryText, leftIcon, children, ...rest } = props;
  const translate = useTranslate();
  const [open] = useSidebarState();
  const [isOpen, setIsOpen] = useState(isDropdownOpen);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <ListItem
        dense
        button
        onClick={handleToggle}
        sx={{
          paddingLeft: '1rem',
          color: theme.palette.secondary.dark,
        }}
      >
        {isOpen ?
          <ListItemIcon className={MenuItemLinkClasses.icon}
          sx={{minWidth: minWidthIcon}}
          >
            <ExpandMoreIcon/>
          </ListItemIcon>
         : <ListItemIcon className={MenuItemLinkClasses.icon}
                         sx={{minWidth: minWidthIcon}}>
          {leftIcon}
        </ListItemIcon>}
        <ListItemText
          disableTypography
          inset
          primary={translate(primaryText as string)}
          sx={{
            paddingLeft: 0,
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.secondary.dark,
          }}
        />
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={open ? {
            paddingLeft: '25px',
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
          } : {
            paddingLeft: 0,
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
          }}
        >
          {children}
        </List>
      </Collapse>
    </React.Fragment>
  )
}

export type SubMenuProps = {
  children?: React.ReactNode;
  isDropdownOpen?: boolean;
  leftIcon?: React.ReactElement;
  primaryText?: string;
};

export default SubMenu;
