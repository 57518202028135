import {
  CreateButton,
  Datagrid,
  DateField,
  List,
  Pagination,
  ReferenceField,
  TextField
} from 'react-admin';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { ListActions } from '../list-actions';

export const AssetAliasList = () => {
  return (
    <List
      actions={<ListActions createButton={<CreateButton/>}/>}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id"/>
        <TextField source="serviceName"/>
        <TextField source="serviceCode"/>
        <ReferenceField source="assetId" reference="asset">
          <TextField source="title"/>
        </ReferenceField>
        <DateField source="createdAt"/>
        <DateField source="updatedAt"/>
      </Datagrid>
    </List>
  )
}