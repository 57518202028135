import { DateInput, Edit, SimpleForm, TextInput } from 'react-admin';
import { CustomToolbar } from '../toolbar';
import { transformSystemSettingsEdit, validateSystemSettingsEdit } from '../system-settings';

export const BalanceReserveEdit = (props: any) => {
  return (
    <Edit
      transform={transformSystemSettingsEdit}
    >
      <SimpleForm warnWhenUnsavedChanges toolbar={<CustomToolbar saveButton={true}/>}
                  validate={validateSystemSettingsEdit}>
        <TextInput label="Reserve id" source="id" disabled/>
        <TextInput label="Reserve code" source="code" disabled/>
        <TextInput label="Reserve description" source="description" disabled/>
        <TextInput label="Reserve value" source="value"/>
        <DateInput name="createdAt" source="createdAt" disabled/>
        <DateInput name="updatedAt" source="updatedAt" disabled/>
      </SimpleForm>
    </Edit>
  );
}
