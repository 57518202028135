import {
  BooleanField,
  DateField,
  EmailField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  usePermissions,
  useRecordContext
} from 'react-admin';
import { AdminResourceName } from '../../resource-name.enum';
import { AdminRole } from '../role';

export const AdminTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>Admin "{record.email}"</span>;
};

export const AdminShow = () => {
  const { permissions } = usePermissions()
  return (<Show
    title={<AdminTitle/>}
  >
    <SimpleShowLayout>
      <TextField source="id"/>
      <EmailField source="email"/>
      <ReferenceField label="Role data" source="roleId" reference={AdminResourceName.ROLE} link={'show'}>
        <SimpleShowLayout>
          <TextField label="Role" source="roleType"/>
          <TextField source="description"/>
        </SimpleShowLayout>
      </ReferenceField>
      {permissions === AdminRole.SUPER_ADMIN &&
      <BooleanField label="Two factor auth" source="isTwoFactorAuthEnabled"/>
      }
      <DateField label="Created At" source="createdAt" showTime/>
      <DateField label="Updated At" source="updatedAt" showTime/>
    </SimpleShowLayout>
  </Show>)
}