import { EditButton, List, Pagination, TextField } from 'react-admin';
import { ListActions } from '../list-actions';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { GridComponent } from '../grid/grid-component';

const BalanceReserveListFields = () => {
  return [
    <TextField key="id" label="Reserve id" source="id"/>,
    <TextField key="code" label="Reserve code" source="code"/>,
    <TextField key="description" label="Reserve description" source="description"/>,
    <TextField key="value" label="Reserve value" source="value"/>,
    <EditButton key="editBtn"/>,
  ]
}


export const BalanceReserveList = (props: any) => {
  return (
    <List
      actions={<ListActions/>}
      filter={{ balanceReserve: true }}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <GridComponent gridChildren={BalanceReserveListFields()} bulkActionButtons={false} rowClick="show"/>
    </List>
  )
}