import { Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
import { FileField, FileInput, Form, useCreate, Button, Dispatch } from 'react-admin';
import { SubmitButton } from '../user';
import { SetStateAction, useState } from 'react';
import { AdminResourceName } from '../../resource-name.enum';
import { FieldValues } from 'react-hook-form';
import { JsonEditor } from 'json-edit-react';

interface DialogProps {
  title: string;
  open: boolean;
  handleCloseClick: () => void;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  error: Record<string, any> | null;
  setError: (value: Record<string, any>) => void;
  setSuccess: (value: Record<string, any>) => void;
  success: Record<string, any> | null;
}

interface ImportButtonProps {
  dialogTitle: string;
}

export const ImportForm = (props: DialogProps) => {
  const [create, { isLoading }] = useCreate();
  const { open, handleCloseClick, title, error, setError, success, setSuccess } = props;
  const handleSubmit = async (data: FieldValues) => {
    await create(
      AdminResourceName.TRANSLATIONS_IMPORT,
      { data }, {
        onError: (err: any) => {
          setError({
            message: err.message,
            messages: err?.body?.messages
          })
        },
        onSuccess: (data: any) => {
          setSuccess(data);
        },
      }
    );
  }
  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      aria-label={title}>
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <Form onSubmit={handleSubmit}>
        <DialogContent>
          <>
            {!error && !success &&
              <FileInput source="file" accept="application/json" maxSize={5000000} multiple={false}>
                <FileField source="src" title="title"/>
              </FileInput>
            }
            {!!error &&
            <Box>
                <Box>
                    <span>Error summary</span>
                </Box>
                <JsonEditor data={error}/>
            </Box>}
            {!!success && <Box>
                <Box>
                    <span>Success summary</span>
                </Box>
                <JsonEditor data={success}/>
            </Box>}
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseClick}
            disabled={isLoading}
          >
            <span>{success ? 'Close' : 'Cancel'}</span>
          </Button>
          <SubmitButton disabled={isLoading || !!error || !!success} label="Import"/>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export const ImportButton = (props: ImportButtonProps) => {
  const [openDialog, setDialogOpen] = useState(false)
  const [error, setError] = useState<null | Record<string, any>>(null);
  const [success, setSuccess] = useState<null | Record<string, any>>(null);

  const handleCloseClick = () => {
    setDialogOpen(false);
    setError(null)
    setSuccess(null)
  }
  const handleOpenClick = () => {
    setDialogOpen(true);
    setError(null);
    setSuccess(null)
  }
  return (
    <>
      <Button onClick={handleOpenClick} label="Import"/>
      <ImportForm
        title={props.dialogTitle}
        open={openDialog}
        error={error}
        setError={setError}
        setSuccess={setSuccess}
        success={success}
        handleCloseClick={handleCloseClick}
        setDialogOpen={setDialogOpen}
      />
    </>
  )
}