import { RichTextInput } from "ra-input-rich-text";
import { Create, SimpleForm, SelectInput, usePermissions } from "react-admin"
import { DocumentTypeChoices } from "../document";

export const DocumentCreate = () => {
  const { permissions } = usePermissions();
  return (
    <Create>
      <SimpleForm warnWhenUnsavedChanges>
        <SelectInput label='Document Type' name='type' source='type' choices={DocumentTypeChoices}/>
        <RichTextInput label='Document Content' source="content"/>
      </SimpleForm>
    </Create>
  )
}