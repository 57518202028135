import { choicesFromEnum } from '../../@helpers/choices-from-enum';

enum TransactionState {
  completed = 'COMPLETED',
  pending = 'PENDING',
  failed = 'FAILED',
}

enum AllowedToCreateAssets {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
}

enum CreateTxMode {
  ALL = 'all', // for any asset from user page;
  FIAT = 'fiat', // for only fiat asset;
}

const TransactionStateChoices = choicesFromEnum(TransactionState);
const AllowedToCreateAssetsChoices = choicesFromEnum(AllowedToCreateAssets)

export { TransactionStateChoices, TransactionState, AllowedToCreateAssets, AllowedToCreateAssetsChoices, CreateTxMode }