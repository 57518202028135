import { DateInput, Edit, SelectInput, SimpleForm, TextInput } from "react-admin";
import { RoleTitle } from "./role-show";
import { AdminRolesChoices } from "./admin-role.enum";
import { CustomToolbar } from '../toolbar';

export const RoleEdit = () => {
  return (
    <Edit title={<RoleTitle/>}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<CustomToolbar saveButton={true}/>}>
        <TextInput name="id" disabled source="id"/>
        <SelectInput name="roleType" source="roleType" choices={AdminRolesChoices}/>
        <TextInput name="description" source="description"/>
        <DateInput name="createdAt" source="createdAt"/>
        <DateInput name="updatedAt" source="updatedAt"/>
      </SimpleForm>
    </Edit>
  )
}