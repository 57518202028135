import { Show, TextField, NumberField, DateField, SimpleShowLayout, FunctionField } from 'react-admin';
import { Box } from '@mui/material';
import { LtvGradation } from '../interfaces';

const LtvShowFields = [
  <Box key="title">
    <p>Be sure that all Ltv grades are properly set to prevent incorrect handling of the credit health events</p>
  </Box>,
  <NumberField key="id" source="id"/>,
  <TextField key="type" source="type"/>,
  <FunctionField key="minValue" label="Minimal value" render={(record:LtvGradation)=>{return `${record.minValue}%`}}/>,
  <FunctionField key="maxValue" label="Maximal value" render={(record:LtvGradation)=>{return `${record.maxValue}%`}}/>,
  <DateField key="createdAt" source="createdAt" showTime/>,
  <DateField key="updatedAt" source="updatedAt" showTime/>,
]

export const LtvShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        {LtvShowFields}
      </SimpleShowLayout>
    </Show>
  )
}