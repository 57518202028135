import { Editor } from '@tinymce/tinymce-react';
import { config } from '../config';
import { useInput, TextField } from 'react-admin';
import * as React from 'react';
import { Box } from '@mui/material';

const TinyRichTextEditor = (props: any) => {
  const { source, ...rest } = props
  const {
    // id,
    // isRequired,
    field,
    fieldState: { isTouched, invalid, error },
  } = useInput({ source, ...rest });

  const handleEditorChange = (event: any, editor: any) => {
    field.onChange(editor.getContent());
  }

  return (<Box>
      <Editor
        // @ts-ignore
        // onInit={(evt, editor) => editorRef.current = editor}
        // there's a known issue with how tinymce works where the intialValue and value
        // come into conflict when using useState. tinymce recommend removing initialValue
        // and setting the initial value as the the default state value i.e. formData.description
        // is set to the placeholder text instead of just an empty string
        // initialValue="<p>This is the initial content of the editor.</p>"
        apiKey={config.TINYMCE_API_KEY}
        initialValue={field.value || null}
        init={{
          toolbar_mode: 'wrap',
          height: 500,
          menubar: true,
          images_upload_url: `${config.API_HOST}/images`,
          plugins: 'advlist autolink lists link image charmap preview anchor ' +
            'searchreplace visualblocks code fullscreen ' +
            'insertdatetime table code help wordcount'
          ,
          toolbar: 'undo redo | formatselect | ' +
            'bold italic underline backcolor | image | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
        // onChange={handleEditorChange}
        // onEditorChange={handleEditorChange}
        onFocusOut={handleEditorChange}
      />
      {invalid && <TextField
          sx={{ color: '#d32f2f', fontSize: '14px' }}
          record={{
            error: error?.message
          }}
          source="error"
      />}
    </Box>

  );
}

export default TinyRichTextEditor;