import { ReferenceField, TextField, useGetManyReference, useRecordContext } from "react-admin";

export interface AssetServiceProps {
  label: string;
  reference: string;
  target: string;
  filterTarget: string;
  filterValue: string;
  textField: string;
}

export const AssetService = (props: any) => {
  const record = useRecordContext();
  // console.log('AssetService-props')
  // console.log(props)
  // console.log('record.id')
  // console.log(record.id)
  const { data, isLoading, error } = useGetManyReference(
    props.reference,
    {
      target: props.target,
      id: record.id,
    }
  )
  if (isLoading || error) return null;
  // console.log('AssetService-data')
  // console.log(data);
  let chosenData;
  if (data) {
    chosenData = data.find(alias => alias[props.filterTarget] === props.filterValue);
  }
  return <ReferenceField label={props.label} record={chosenData} source="id" reference={props.reference}>
    <TextField source={props.textField}/>
  </ReferenceField>

}

