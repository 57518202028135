import { DateInput, Edit, SelectInput, SimpleForm, TextInput } from "react-admin"
import { AppListTitle } from "./appListsShow";
import { AppListNames } from "./appListNames.constants";


export const AppListsEdit = () => {
  return (
    <Edit title={<AppListTitle/>}>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput name="id" disabled source="id"/>
        <SelectInput source="listName" choices={AppListNames}/>
        <TextInput name="listValue" source="listValue"/>
        <DateInput name="createdAt" disabled source="createdAt"/>
        <DateInput name="updatedAt" disabled source="updatedAt"/>
      </SimpleForm>
    </Edit>
  )
}