import { BooleanField, Button, DateField, FunctionField, Show, SimpleShowLayout, Tab, TextField, useRedirect } from 'react-admin';
import { AdminResourceName } from '../../resource-name.enum';
import { uriStringify } from '../../@helpers/uri-stringify';

const SeeTranslationsButton = (props: any) => {
  const redirect = useRedirect();

  const record = props.record

  if (!record) return null;

  const handleClick = () => {
    redirect(`/${AdminResourceName.TRANSLATIONS}?filter=${uriStringify({ localeCode: record.code })}`);
  }

  return (<Button onClick={handleClick} children={<span>See translations</span>}/>)
}

export const LocaleShow = (props: any) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
          <TextField label="Code" source="code" />
          <TextField label="Name" source="name" />
          <TextField label="Flag" source="flag" />
          <BooleanField label="Is Active" source="isActive" />
          <DateField label="Created at" source="createdAt" />
          <DateField label="Updated at" source="updatedAt" />
          <FunctionField render={(record: any) => <SeeTranslationsButton record={record}/>}/>
      </SimpleShowLayout>
    </Show>
  );
};
