import PropTypes from 'prop-types';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button, CardContent, CircularProgress } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import {
  Form,
  required,
  useTranslate,
  useLogin,
  useNotify,
  useSafeSetState,
} from 'ra-core';
import { TextInput } from 'react-admin';
import { HTTP_BAD_REQUEST, HTTP_FORBIDDEN } from '../../@services/session.service';
import { dataParseOrAsIs } from '../../@helpers/data-parse-or-as-is.helpers';

export const CustomLoginForm = (props: LoginFormProps) => {
  const { redirectTo, is2faRequired, setIs2faRequired, setShow2faForm, ...rest } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();

  const handleError = (error: any) => {
    const data = dataParseOrAsIs(error.message);
    switch (data['status']) {
      case HTTP_FORBIDDEN:
        if (!is2faRequired) {
          notify('Please enter a two factor token', { type: 'info' })
          setIs2faRequired(true);
        } else {
          notify(data['message'], { type: 'error' })
        }
        break;
      case HTTP_BAD_REQUEST:
        if (typeof data['message'] === 'string') {
          notify(data['message'], { type: 'error' })
        } else {
          notify(JSON.stringify(data['message']), { type: 'error' })
        }
        break
      default:
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
          {
            type: 'warning',
            messageArgs: {
              _:
                typeof error === 'string'
                  ? error
                  : error && error.message
                    ? error.message
                    : undefined,
            },
          }
        );
        break;
    }
  }

  const submit = (values: FormData) => {
    setLoading(true);
    let submitData = values
    if (!is2faRequired) {
      delete submitData.token2fa
    }
    login(submitData, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (typeof error?.isTwoFactorAuthEnabled === 'boolean' && !error.isTwoFactorAuthEnabled) {
          setShow2faForm(true);
        } else {
          handleError(error);
        }
      });
  };


  return (
    <StyledForm
      // @ts-ignore
      onSubmit={submit}
      mode="onChange"
      noValidate
      {...rest}
    >
      <CardContent className={LoginFormClasses.content}>
        <TextInput
          autoFocus
          source="username"
          label={translate('ra.auth.username')}
          validate={required()}
          fullWidth
          disabled={is2faRequired}
        />
        <TextInput
          source="password"
          label={translate('ra.auth.password')}
          type="password"
          autoComplete="current-password"
          validate={required()}
          fullWidth
          disabled={is2faRequired}
        />
        {is2faRequired && <TextInput
            source="token2fa"
            label="token2fa"
            validate={required()}
            fullWidth
        />}
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.button}
        >
          {loading ? (
            <CircularProgress
              className={LoginFormClasses.icon}
              size={19}
              thickness={3}
            />
          ) : (
            translate('ra.auth.sign_in')
          )}
        </Button>
      </CardContent>
    </StyledForm>
  );
};

const PREFIX = 'RaCustomLoginForm';

export const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 300,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

export interface LoginFormProps {
  is2faRequired: boolean;
  setIs2faRequired: (value: (((prevState: boolean) => boolean) | boolean)) => void;
  setShow2faForm: (value: (((prevState: boolean) => boolean) | boolean)) => void;
  redirectTo?: string;
  className?: string;
}

interface FormData extends FieldValues {
  username: string;
  password: string;
  token2fa?: string;
}

CustomLoginForm.propTypes = {
  redirectTo: PropTypes.string,
};

CustomLoginForm.defaultProps = {
  is2faRequired: false,
}