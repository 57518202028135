import {
  DateField,
  Edit,
  ImageField,
  ImageInput,
  NumberInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { BannerTitle } from "./show";
import { EditToolbar } from "./edit-toolbar";
import { FileDescription } from "./file-description";

const formatVisualisationPosition = (value: number | null) => {
  if (value === null) {
    return ''
  } else {
    return value
  }
}

const parseVisualisationPosition = (value: number | string) => {
  if (value === '') {
    return null;
  } else {
    return value
  }
}

export const BannerEdit = () => {
  return (<Edit title={<BannerTitle/>}>
    <SimpleForm warnWhenUnsavedChanges toolbar={<EditToolbar/>}>
      <TextInput name="id" source="id" disabled/>
      <NumberInput max={10} min={1} step={1} label="Visualization position" name="visualisationPosition"
                   format={formatVisualisationPosition} parse={parseVisualisationPosition}
                   source="visualisationPosition" sx={{minWidth: '187px'}}/>
      <TextInput name="bannerName" source="bannerName"/>
      <TextInput name="fileName" source="fileName" disabled/>
      <DateField source="createdAt"/>
      <DateField source="updatedAt"/>
      <ImageField source="bannerUrl" title="banner"/>
      <ImageInput name='file' source="file" accept={["image/png", "image/jpg", "image/jpeg"]} maxSize={5000000}>
        <ImageField source="file" title="title"/>
      </ImageInput>
      <FileDescription/>
    </SimpleForm>
  </Edit>)
}