export class AuthTokenProvider {

  _key = 'AP_TOKEN';
  _preLoginKey = 'AP_PRE_LOGIN_TOKEN'

  get token(): string {
    return localStorage.getItem(this._key) || '';
  }

  set token(token: string) {
    localStorage.setItem(this._key, token);
  }

  clear() {
    localStorage.removeItem(this._key);
    localStorage.removeItem(this._preLoginKey);
  }

  get preLoginToken(): string {
    return localStorage.getItem(this._preLoginKey) || '';
  }

  set preLoginToken(token: string) {
    localStorage.setItem(this._preLoginKey, token);
  }
}
