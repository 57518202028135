import { DateField, Show, SimpleShowLayout, TextField, useRecordContext } from "react-admin";

export const RoleTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>"Role: {record.roleType}"</span>;
};

export const RoleShow = () => {
  return (<Show
    title={<RoleTitle/>}
  >
    <SimpleShowLayout>
      <TextField source="id"/>
      <TextField source="roleType"/>
      <TextField source="description"/>
      <DateField label="Created At" source="createdAt" showTime/>
      <DateField label="Updated At" source="updatedAt" showTime/>
    </SimpleShowLayout>
  </Show>)
}