export enum CreditPlanStatus {
  Active = 'ACTIVE',
  Failed = 'FAILED',
  Paid = 'PAID',
}

export interface CreditPlanModel {
  id: string;
  title: string;
  creditAmount: string;
  collateralAmount: string;
  ltv: string;
  ltvDate: Date;
  aprValue: string;
  maturityDate: Date;
  closeDate: Date | null;
  status: CreditPlanStatus;
  isAutoTopUp: boolean;
  collateralWalletId: string;
  creditWalletId: string;
  userId: string;
  accountId: string;
  createdAt: Date;
  updatedAt: Date;
}