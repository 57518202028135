import { isPositiveNumberString } from '../../../@helpers/is-positive-number-string.helper';
import { DocumentSystemCode, DocumentType } from '../../document';

function positiveNumberString(keyName: string, value: string, errorMessage = 'The Fee value must be a positive number'): object {
  return (!isPositiveNumberString(value) && { [keyName]: errorMessage }) || {}
}

function anyData(name: string, value: string, message?: string): object {
  return {}
}

export const ValidateSystemCodeValue = {
  CARD_DELIVERY_COUNTRY: anyData,
  CARD_DELIVERY_STATE: anyData,
  CARD_DELIVERY_CITY: anyData,
  CARD_DELIVERY_POSTAL_CODE: anyData,
  CARD_DELIVERY_ADDRESS_LINE_1: anyData,
  CARD_DELIVERY_ADDRESS_LINE_2: anyData,
  CARD_FEE_ISSUE: positiveNumberString,
  EXCHANGE_FEE: positiveNumberString,
  EXCHANGE_RATE_GAP: positiveNumberString,
  TRANSFER_CRYPTO_SEND_FEE: positiveNumberString,
  TRANSFER_CRYPTO_RECEIVE_FEE: positiveNumberString,
  TRANSFER_FIAT_SEND_FEE: positiveNumberString,
  TRANSFER_FIAT_RECEIVE_FEE: positiveNumberString,
  ACCOUNT_MAINTENANCE_FEE: positiveNumberString,
  TOP_UP_WITH_CARD_FEE: positiveNumberString,
  BALANCE_RESERVE_VALUE_FOR_ETH: positiveNumberString,
  BALANCE_RESERVE_VALUE_FOR_TRON: positiveNumberString,
  [DocumentSystemCode[DocumentType.termsAndConditions]]: anyData,
  [DocumentSystemCode[DocumentType.savingsTermsConditions]]: anyData,
  [DocumentSystemCode[DocumentType.privacyPolicy]]: anyData,
  [DocumentSystemCode[DocumentType.feeSchedule]]: anyData,
  [DocumentSystemCode[DocumentType.referralProgram]]: anyData,
  [DocumentSystemCode[DocumentType.creditTermsAndConditions]]: anyData,
  REFERRAL_PROGRAM_SOCIAL_LINK_FACEBOOK: anyData,
  REFERRAL_PROGRAM_SOCIAL_LINK_X: anyData,
  REFERRAL_PROGRAM_SOCIAL_LINK_INSTAGRAM: anyData,
  REFERRAL_PROGRAM_SOCIAL_LINK_YOUTUBE: anyData,
  REFERRAL_PROGRAM_SOCIAL_LINK_LINKEDIN: anyData,
  REFERRAL_PROGRAM_SOCIAL_LINK_WHATSAPP: anyData,
  REFERRAL_PROGRAM_SOCIAL_LINK_DISCORD: anyData,

}