import { useRecordContext, Button, useUpdate, useRefresh } from "react-admin";
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";
import { AdminResourceName } from '../../resource-name.enum';

export const DeleteUserButton = () => {
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  // const redirect = useRedirect();
  const refresh = useRefresh();
  const onSuccessDeletion = () => {
    // redirect('list', 'user-deletion');
    refresh();
  }
  const [update] = useUpdate(
    AdminResourceName.USER_DELETION,
    undefined,
    { onSuccess: onSuccessDeletion }
  );
  if (!record) return null;
  const handleClick = () => {
    setShowDialog(true);
  }
  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleDeleteClick = async () => {
    await update(AdminResourceName.USER_DELETION, {
      id: record.id,
    });
    setShowDialog(false);
  }

  return (<>
      <Button label="Close User" onClick={handleClick}/>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Close User"
      >
        <DialogTitle>User Deletion</DialogTitle>
        <DialogContent>
          <div>
            <p>You are now on the way to permanently delete the user Account</p>
            <p>The user: {record.profile.firstName} {record.profile.lastName}</p>
            <p>Approve your decision by choosing the further action</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseClick}
          >
            <span>Cancel</span>
          </Button>
          <Button
            onClick={handleDeleteClick}
          ><span>Deactivate user</span></Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
