import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { CreditPlanModel } from './interfaces';
import { differenceInCalendarDays } from 'date-fns';
import { AdminResourceName } from '../../../resource-name.enum';
import { BusinessAccountModel } from '../../business-account';
import { fullName } from '../../../@helpers/wallet.helpers';
import { owner } from '../../../@helpers/account.helpers';
import { CloseCreditButton } from './close-credit.button';

const CreditPlanShowFields = [
  <TextField key="id" source="id"/>,
  <ReferenceField key="collateralWallet" label="Collateral WalletId" reference={AdminResourceName.WALLET}
                  source="collateralWalletId">
    <TextField source="id"/>
  </ReferenceField>,
  <TextField key="title" source="title"/>,
  <FunctionField key="duration" label="Duration (days)" render={(record: CreditPlanModel) => {
    return differenceInCalendarDays(new Date(record.maturityDate), new Date(record.createdAt))
  }}/>,
  <FunctionField key="interest" label="Interest" render={(record: CreditPlanModel) => {
    return `${Number(record.aprValue) * 100}%`
  }}/>,
  <FunctionField key="collateralAmountAsset" label="Collateral Currency" render={(record: CreditPlanModel) => {
    const { collateralAmount } = record;
    return (
      <ReferenceField  record={record} key="collateralAsset" label="Collateral Currency" reference={AdminResourceName.WALLET}
                       source="collateralWalletId">
        <FunctionField render={(wallet: any) => `${collateralAmount} ${wallet.asset}`}/>
      </ReferenceField>
    )
  }}/>,
  <FunctionField key="creditAmountAsset" label="Credit Currency" render={(record: CreditPlanModel) => {
    const { creditAmount } = record;
    return (
      <ReferenceField  record={record} key="creditAsset" label="Credit Currency" reference={AdminResourceName.WALLET}
                       source="creditWalletId">
        <FunctionField render={(wallet: any) => `${creditAmount} ${wallet.asset}`}/>
      </ReferenceField>
    )
  }}/>,
  <FunctionField key="left" label="Days left" render={(record: CreditPlanModel) => {
    const left = differenceInCalendarDays(new Date(record.maturityDate), new Date())
    if(record.closeDate){
      return 'Closed'
    }
    return left < 0 ? 'Expired' : left;
  }}/>,
  <DateField key="startDate" label="Start Date" source="createdAt" showTime/>,
  <DateField key="finishDate" label="Finish Date" source="closeDate" showTime emptyText="--:--:--"/>,
  <DateField key="maturityDate" label="Maturity Date" source="maturityDate" showTime/>,
  <ReferenceField key="clientsName" label="Client`s Name" reference={AdminResourceName.BUSINESS_ACCOUNT}
                  source="accountId" link="show">
    <FunctionField render={(record: BusinessAccountModel) => fullName(owner(record))}/>
  </ReferenceField>,
  <CloseCreditButton key="closeButton"/>,
]


export const CreditPlanShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        {CreditPlanShowFields}
      </SimpleShowLayout>
    </Show>
  )
}