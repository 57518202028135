import { defaultTheme } from 'react-admin';
import { getAdminColors } from './@helpers/admin-theme.helpers';
import { config } from './config';

const COLORS = getAdminColors(config.APP_NAME);

const typography = {
  fontFamilySecondary: "'Poppins', sans-serif",
  fontFamily: "'Poppins', sans-serif",
  fontSize: 16,
  fontStyle: "normal",
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  color: COLORS.text,
};

const typographyBase = {
  fontFamily: typography.fontFamily,
  fontSize: typography.fontSize,
  fontStyle: typography.fontStyle,
  color: typography.color,
  fontWeight: typography.fontWeightRegular
};

const typographyHeader = {
  ...typographyBase,
  fontWeight: typography.fontWeightBold,
  fontFamily: typography.fontFamilySecondary,
  fontSize: 16,
};

const typographyBody = {
  ...typographyBase,
  fontWeight: typography.fontWeightRegular,
  fontFamily: typography.fontFamily,
  fontSize: 16,
};
export const theme = {
  ...defaultTheme,
  palette: {
    background: {
      default: COLORS.contrast,
    },
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      light: COLORS.primary,
      main: COLORS.primary,
      dark: COLORS.text,
      contrastText: COLORS.contrast,
    },
  },
  components: {
    ...defaultTheme.components,
    MuiButton: {
      styleOverrides: {
        root: {
          color: COLORS.contrast,
          height: 44,
          paddingTop: 0,
          paddingRight: 20,
          paddingBottom: 0,
          paddingLeft: 20,
          borderRadius: 10,
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: COLORS.text,
          height: 44,
          textWrap: 'nowrap',
          borderRadius: 10,

          "&.MuiButton-textPrimary": {
            color: COLORS.text
          },

          "&.MuiButton-textPrimary:hover": {
            color: COLORS.primary
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: COLORS.toImportant(COLORS.text),
          borderRadius: 10,
          "&.RaMenuItemLink-active": {
            borderLeftStyle: "none",
            color: COLORS.toImportant(COLORS.primary),
            fontWeight: typography.fontWeightBold
          },
        },
      }
    }
  },
  typography: {
    ...typographyBase,
    h1: {
      ...typographyHeader,
    },
    body1: {
      ...typographyBody,
    },
    body2: {
      ...typographyBody,
    }
  },
};