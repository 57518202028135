import {
  DateInput,
  Edit,
  FunctionField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin'
import { AdminResourceName } from '../../resource-name.enum';
import { receiverName, TransactionStateChoices, TransactionTypes } from '../transaction';
import { Box, Toolbar } from '@mui/material';
import { FiatTransactionType, ITransactionModel } from './domain/transaction.model';
import { fullName } from '../../@helpers/wallet.helpers';
import { owner } from '../../@helpers/account.helpers';

export function senderName(record: ITransactionModel) {
  const { senderId, payload } = record;
  if (senderId) {
    return (<ReferenceField label="Sender" source="senderId" reference={AdminResourceName.BUSINESS_ACCOUNT} link="show">
      <FunctionField render={(record: any) => fullName(owner(record))}/>
    </ReferenceField>)
  }
  if (payload && payload?.order?.transactionType === FiatTransactionType.Payin) {
    return payload?.order?.paymentDetails?.payerRequisite?.name ?
      <TextField label="Sender" record={payload} source="order.paymentDetails.payerRequisite.name"/> : null;
  }
  return null;
}

const TransactionEdiToolbar = (props:any) => (
  <Toolbar
    {...props}
    sx={{ display: 'flex', justifyContent: 'space-between' }}
  >
    <SaveButton />
  </Toolbar>
);

const transformEditForm =(data:any)=>{
  const {payload,sender,receiver,...rest} = data;
  return rest;
}

export const TransactionEdit = () => {
  return (
    <Edit transform={transformEditForm} mutationMode="pessimistic">
      <SimpleForm warnWhenUnsavedChanges toolbar={<TransactionEdiToolbar/>}>
        <SelectInput name="type" source="type" choices={TransactionTypes} disabled/>
        <ReferenceInput name="sendAsset" source="sendAsset" reference={AdminResourceName.ASSET} perPage={100}>
          <SelectInput label="Send Asset" optionText="title" optionValue="shortcode" disabled/>
        </ReferenceInput>
        <NumberInput name="sendAmountGross" source="sendAmountGross" disabled/>
        <NumberInput name="sendAmountNet" source="sendAmountNet" disabled/>
        <ReferenceInput name="receivedAsset" source="receivedAsset" reference={AdminResourceName.ASSET} perPage={100}>
          <SelectInput label="Receive Asset" optionText="title" optionValue="shortcode" disabled/>
        </ReferenceInput>
        <NumberInput name="receivedAmountGross" source="receivedAmountGross" disabled/>
        <NumberInput name="receivedAmountNet" source="receivedAmountNet" disabled/>
        <NumberInput name="fee" source="fee" disabled/>
        <SelectInput name="state" source="state" choices={TransactionStateChoices}/>
        {/*<TransactionPayloadEdit label="Payload" source="payload"/>*/}
        <TextInput name="source" source="source" disabled/>
        <TextInput name="destination" source="destination" disabled/>
        <TextInput name="reference" source="reference"/>
        <TextInput name="beneficiary" source="beneficiary"/>
        <Box m={1}>
          <div><span>Sender</span></div>
          <FunctionField label="Sender" render={(record: ITransactionModel) => senderName(record)}/>
        </Box>
        <Box m={1}>
          <div><span>Receiver</span></div>
          <FunctionField label="Receiver" render={(record: ITransactionModel) => receiverName(record)}/>
        </Box>

        {/*<ReferenceInput name="senderId" source="senderId" reference={AdminResourceName.BUSINESS_ACCOUNT} perPage={999}>*/}
        {/*  <SelectInput label="Sender" optionText="accountNumber" optionValue="id" disabled/>*/}
        {/*</ReferenceInput>*/}
        {/*<ReferenceInput name="receiverId" source="receiverId" reference={AdminResourceName.BUSINESS_ACCOUNT} perPage={999}>*/}
        {/*  <SelectInput label="Receiver" optionText="accountNumber" optionValue="id" disabled/>*/}
        {/*</ReferenceInput>*/}
        <DateInput name="createdAt" source="createdAt" defaultValue="" disabled/>
      </SimpleForm>
    </Edit>
  )
}