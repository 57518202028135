import {
  BooleanField,
  DateField,
  FunctionField,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  useGetOne,
  usePermissions,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { AccountTotalBalance, IdTypeEnum } from '../balance';
import { OrganizationName } from './business-account-list';
import { fullName } from '../../@helpers/wallet.helpers';
import { AdminRole } from '../role';
import { CountryShowByRecord } from '../country';
import { Button } from '@mui/material';
import { AdminResourceName } from '../../resource-name.enum';
import { AccountType, owner } from '../../@helpers/account.helpers';
import { InternalKycStatusEditChoices } from '../user';
import { uriStringify } from '../../@helpers/uri-stringify';
import { BusinessAccountModel, User } from './interfaces';

export const BusinessAccountTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>Account "{record.legalName}" :: accountNumber "{record.accountNumber}"</span>;
};

export const SeeWalletsButton = (props: any) => {
  const redirect = useRedirect();
  const record = props.record
  if (!record) return null;
  const handleSeeAccountsClick = () => {
    redirect(`/${AdminResourceName.WALLET}?filter=${uriStringify({ accountId: record.id })}`);
  }
  return (<Button onClick={handleSeeAccountsClick} children={<span>See accounts</span>}/>)
}

export const ReferralLeader = (props: any) => {
  const record = useRecordContext();
  const user = owner(record as BusinessAccountModel);
  if (user.referralLeaderId) return <GetLeader {...props} user={user}/>
  return (<TextField source="data" record={{data:'No data'}}/>);
}

export const GetLeader = (props: any & { user: User }) => {
  const { user, ...rest } = props
  const { data, isLoading, isError } = useGetOne(AdminResourceName.USER, { id: user.referralLeaderId })
  if (isLoading || isError) return null;

  return (<ReferenceField {...rest} record={{ id: data.individualAccId || data.currentAccountId }}
                          reference={AdminResourceName.BUSINESS_ACCOUNT} source="id" link="show">
    <FunctionField render={(record: any) => owner(record)?.referralCode}/>
  </ReferenceField>)
}

export const ReferralFollowers = (props: any) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const user = owner(record as BusinessAccountModel);
  if (!record) return null;
  const followers = user?.referralFollowers;
  if (!followers || followers.length === 0) return null;
  const followerAccountIds = followers.map((follower: any) => {
    return follower.individualAccId || follower.currentAccountId;
  });

  const handleClick = () => {
    redirect(`/${AdminResourceName.BUSINESS_ACCOUNT}?filter=${uriStringify({ ids: followerAccountIds })}`)
  }

  return (<Button {...props} onClick={handleClick} children={<span>{followers.length}</span>}/>)
}


export const BusinessAccountShow = () => {
  const { permissions } = usePermissions()

  return (
    <Show title={<BusinessAccountTitle/>}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <TextField source="accountNumber"/>
        <FunctionField label="Account Type" render={(record: any) => AccountType(record)}/>
        <AccountTotalBalance label="Balance" idType={IdTypeEnum.BUSINESS_ACCOUNT}/>
        <FunctionField label="Account Email" render={(record: any) => owner(record)?.email || 'No data'}/>
        <FunctionField label="Legal name" render={(record: any) => <OrganizationName record={record}/>}/>
        <FunctionField label="User Name" render={(record: any) => fullName(owner(record)) || 'No data'}/>
        <FunctionField label="User Email" render={(record: any) => owner(record)?.profile?.email|| 'No data'}/>
        <FunctionField label="User status" render={(record: any) => owner(record)?.status}/>
        <FunctionField label="User role" render={(record: any) => record?.users[0]?.userRole || 'No data'}/>
        {
          permissions === AdminRole.SUPER_ADMIN &&
            <FunctionField label="isTwoFactorAuthEnabled" render={(record: any) => <BooleanField record={owner(record)}
                                                                                                 source="isTwoFactorAuthEnabled"/>}/>
        }
        <FunctionField label="User Birthday" render={(record: any) => <DateField label="Birthday" record={owner(record)}
                                                                                 source="profile.birthDate" emptyText="No data"/>}/>
        <FunctionField label="User Country" render={(record: any) => <CountryShowByRecord record={owner(record)} />}/>
        <FunctionField label="User City" render={(record: any) => owner(record)?.profile?.city || 'No data'}/>
        <FunctionField label="User Address"
                       render={(record: any) => [owner(record)?.profile?.addressLine1,owner(record)?.profile?.addressLine2].filter((el)=>!!el).join(', ') || 'No data'}/>
        <FunctionField label="User Postal Code" render={(record: any) => owner(record)?.profile?.postalCode ||'No data'}/>
        <FunctionField label="User Reference currency"
                       render={(record: any) => <TextField record={owner(record)} source="profile.currency" emptyText="No data"/>}/>
        {
          permissions === AdminRole.SUPER_ADMIN &&
            <FunctionField label="User deletion Requested"
                           render={(record: any) => <DateField record={owner(record)} source="deletionRequestedAt"
                                                               showTime emptyText="No data"/>}/>
        }
        {
          permissions === AdminRole.SUPER_ADMIN &&
            <FunctionField label="User deletion state"
                           render={(record: any) => owner(record)?.deletedAt ? 'Deactivated' : 'Active'}/>
        }
        {
          permissions === AdminRole.SUPER_ADMIN &&
            <FunctionField label="User deactivated date"
                           render={(record: any) => <DateField record={owner(record)} source="deletedAt" showTime emptyText="No data"/>}/>
        }
        <TextField source="legalCountryCode" emptyText="No data"/>
        <TextField source="legalCity" emptyText="No data"/>
        <TextField source="legalAddress" emptyText="No data"/>
        <TextField source="postalCode" emptyText="No data"/>
        <TextField source="companyUrl" emptyText="No data"/>
        <TextField source="phone" emptyText="No data"/>
        <TextField source="registrationNumber" emptyText="No data"/>
        <DateField source="formationDate" emptyText="No data"/>
        <SelectField source="internalKycStatus" choices={InternalKycStatusEditChoices} emptyText="DISABLED_INTERNAL"/>
        <TextField source="kycStatus"/>
        <TextField source="sumsubApplicantId" emptyText="No data"/>
        <TextField source="fireblocksVaultId" emptyText="No data"/>
        <TextField source="intId"/>
        <ReferenceField label="BusinessType" source="businessTypeId" reference={AdminResourceName.APP_LISTS} emptyText="No data">
          <TextField source="listValue"/>
        </ReferenceField>
        <ReferenceField label="BusinessCategory" source="businessCategoryId" reference={AdminResourceName.APP_LISTS} emptyText="No data">
          <TextField source="listValue"/>
        </ReferenceField>
        <FunctionField label="Personal referral code"
                       render={(record: any) => <TextField record={owner(record)} source="referralCode"/>}/>
        <ReferralFollowers label="User referral followers"/>
        <ReferralLeader label="Received referral code"/>
        <FunctionField label="Reward`s asset" render={(record: any) => {
          return <TextField record={owner(record)} source="rewardAsset"/>
        }}/>
        <DateField source="createdAt" showTime/>
        <DateField source="updatedAt" showTime/>
        <FunctionField render={(record: any) => <SeeWalletsButton record={record}/>}/>
      </SimpleShowLayout>
    </Show>
  )
}