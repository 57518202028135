import React from 'react';
import { AppBar } from 'react-admin';
import { Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getAdminColors, getAdminLogo } from '../@helpers/admin-theme.helpers';
import { config } from '../config';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: -10
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxWidth: "40px",
    marginLeft: -35
  },
  toolbar: {
    paddingLeft: '10px!important'
  }
});

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  const { sectionText: color, sectionBackground: background } = getAdminColors(config.APP_NAME);
  return (
    <AppBar {...props} color='secondary' sx={{ color, background }}>
      <div>
        <Toolbar className={classes.toolbar}>
          <img src={getAdminLogo(config.APP_NAME)} width={'146'} height={'auto'} alt="logo" />
        </Toolbar>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
        ></Typography>
      </div>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
    </AppBar >
  );
};

export default CustomAppBar;