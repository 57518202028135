import { Datagrid, List, Pagination, TextField } from 'react-admin';
import { ListActions } from '../list-actions';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';

const BalancesReportList = (props: any) => {

  return (
    <List {...props}
          actions={<ListActions/>}
          pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="asset"/>
        <TextField source="balance"/>
      </Datagrid>
    </List>
  );
}

export default BalancesReportList;
