import { Button, Form, useRecordContext, useRefresh, useUpdate } from 'react-admin';
import { useNotify } from 'ra-core';
import { useState } from 'react';
import { AdminResourceName } from '../../../resource-name.enum';
import { CreditPlanStatus } from './interfaces';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const CloseCreditButton = (props: any) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);
  const onSuccessUpdate = () => {
    setShowDialog(false);
    refresh();
  };
  const onErrorUpdate = (err: any) => {
    notify(err.message, { type: 'error' });
    setShowDialog(false);
  }

  const [update] = useUpdate();

  if (!record) return null;

  const handleClick = () => {
    setShowDialog(true);
  };
  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async () => {
    await update(AdminResourceName.CREDIT_PLAN, {
      id: record.id,
      data: {
        status: CreditPlanStatus.Failed,
      },
      previousData: record,
    }, {
      onSuccess: onSuccessUpdate,
      onError: onErrorUpdate,
    });

    setShowDialog(false);
  };

  return (
    <>
      <Button {...props} label="Close credit" onClick={handleClick}
              disabled={record.status !== CreditPlanStatus.Active}/>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Confirmation">
        <DialogTitle>Credit Close Confirmation</DialogTitle>
        <Form onSubmit={handleSubmit}>
          <DialogContent>
            <p>!WARNING!</p>
            <p>You on the way to force close Credit {record.id}</p>
            <p>User collateral will be alienated</p>
            <p>Please. Confirm the action</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseClick}>
              <span>Cancel</span>
            </Button>
            <Button onClick={handleSubmit}>
              <span>Ok</span>
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}