import { Create,SelectInput, SimpleForm, NumberInput } from 'react-admin'
import { LtvGradationChoices } from './constants';

export const CreateLtv =()=>{
  return(
    <Create redirect="list">
      <SimpleForm warnWhenUnsavedChanges>
        <SelectInput name="type" source="type" choices={LtvGradationChoices}/>
        <NumberInput name="minValue" source="minValue" min={1}/>
        <NumberInput name="maxValue" source="maxValue"  min={2}/>
      </SimpleForm>
    </Create>
  )
}