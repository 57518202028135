import { TextField, useGetOne, useRecordContext } from "react-admin";

export const AccountTotalBalance = (props: any) => {
  const record = useRecordContext();
  // console.log('record');
  // console.log(record);
  const { idType } = props;
  const { data, isLoading, error } = useGetOne(`balance/${idType}`, {
    id: record.id
  })
  if (isLoading || error || !data) return null
  return (
    <TextField label="Balance" record={data} source="totalBalance"/>
  )
}