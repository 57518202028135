export function diffFromPrevious(tgt: any, base: any) {
  const res: any = {};
  for (const k in tgt) {
    if (typeof tgt[k] === 'object') {
      const diffRes = diffFromPrevious(tgt[k], base[k])
      if (diffRes) {
        res[k] = diffRes;
      }
    } else if (base[k] !== tgt[k]) {
      if(tgt[k] || typeof tgt[k] === 'boolean') {
        res[k] = tgt[k];
      }
    }
  }
  if (Object.keys(res).length === 0) return;
  return res;
}