import {
  BooleanField,
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext
} from "react-admin";
import { AssetIcon } from "./assetIcon";
import { AliasServices } from "../asset-alias";
import { AssetService } from "./assetService";

export const AssetTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>Asset "{record.title}"</span>;
};

export const AssetShow = () => {
  return (
    <Show title={<AssetTitle/>}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <TextField source="title"/>
        <TextField source="shortcode"/>
        <TextField source="contractAddress"/>
        <TextField source="baseCurrency"/>
        <TextField source="decimals"/>
        {
          AliasServices.map(service => {
            return <AssetService key={service.id} label={service.name} reference="asset_alias" target="assetId"
                                 filterTarget="serviceName"
                                 filterValue={service.id} textField="serviceCode"/>
          })
        }
        <TextField source="color"/>
        <AssetIcon label="Icon"/>
        <TextField source="assetType"/>
        <BooleanField source="isDarkText"/>
        <TextField source="addressType"/>
        <TextField source="availableTopUpTypes"/>
        <TextField source="availableTransferTypes"/>
        <TextField source="blockExplorer"/>
        <BooleanField source="isIbanSupported"/>
        <BooleanField source="isExchangeSupported"/>
        <DateField source="createdAt" showTime/>
        <DateField source="updatedAt" showTime/>
      </SimpleShowLayout>
    </Show>
  )
}