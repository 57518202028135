import {
  AutocompleteInput,
  DeleteButton,
  Edit,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  useEditContext,
} from 'react-admin'
import {
  SavingPlanTitle,
  SavingPlanType,
  SavingTypeChoices,
  transformFormData
} from '../saving-plan';
import { transformNumberToString } from '../../@helpers/transform-number-to-string';
import { Toolbar } from '@mui/material';
import { useState } from 'react';
import { AdminResourceName } from '../../resource-name.enum';

export const validateSavingPlanForm = ({
                                         assetId,
                                         yearPercentage,
                                         duration,
                                         minimumAmount,
                                         type,
                                         bonusPercent
                                       }: any) => {
  const errors = {};
  if (!assetId) {
    Object.assign(errors, {
      assetId: 'Asset is required',
    });
  }
  if (!yearPercentage) {
    Object.assign(errors, {
      yearPercentage: 'Year percentage is required',
    });
  }
  if (type !== SavingPlanType.Flexible) {
    if (!duration) {
      Object.assign(errors, {
        duration: 'Duration is required',
      });
    }
  }
  if (type === SavingPlanType.Combo) {
    if (!bonusPercent) {
      Object.assign(errors, {
        bonusPercent: 'Initial Bonus percent is required',
      });
    }
  }
  if (!minimumAmount) {
    Object.assign(errors, {
      minimumAmount: 'Minimum amount is required',
    });
  }
  if (yearPercentage === 0) {
    Object.assign(errors, {
      yearPercentage: 'Year percentage must be non zero value',
    });
  }
  if (minimumAmount === '0') {
    Object.assign(errors, {
      minimumAmount: 'Minimum amount must be non zero value',
    });
  }
  return errors;
}

export const SavingPlanEditToolBar = (props: any) => {
  return (
    <Toolbar {...props} sx={{ justifyContent: 'space-between' }}>
      <SaveButton/>
      <DeleteButton/>
    </Toolbar>
  )
}

const EditForm = (props: any) => {
  const { record, isLoading } = useEditContext();
  const [type, setType] = useState(record.type ? record.type : SavingPlanType.Limited)

  const handleTypeChange = (event: any) => {
    setType(event.target.value);
  }
  if (isLoading) return null;

  return (<SimpleForm {...props} validate={validateSavingPlanForm} toolbar={<SavingPlanEditToolBar/>}>
    <TextInput name="title" source="title"/>
    <SelectInput label="Type" source="type" choices={SavingTypeChoices} onChange={handleTypeChange}/>
    <ReferenceInput source="assetId" reference={AdminResourceName.ASSET}>
      <AutocompleteInput name="assetId" helperText={false} label="Asset"
                         optionText={(choice: any) => `${choice.title}: ${choice.shortcode}`} optionValue="id"
                         filterToQuery={(value: any) => ({ title: value })}/>
    </ReferenceInput>
    <NumberInput label="Interest (APY)" source="yearPercentage" min={0} parse={transformNumberToString}/>
    <NumberInput label="Initial Bonus" source="bonusPercent" min={0} parse={transformNumberToString}
                 disabled={type !== SavingPlanType.Combo}/>
    <NumberInput label="Duration" source="duration" min={1} disabled={type === SavingPlanType.Flexible}/>
    <NumberInput label="Minimum amount" source="minimumAmount" min={0} parse={transformNumberToString}/>
  </SimpleForm>)
}

export const SavingPlanEdit = () => {
  return (
    <Edit title={<SavingPlanTitle/>} transform={transformFormData} mutationMode="pessimistic">
      <EditForm/>
    </Edit>
  )
}