import { DateField, FunctionField, NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';

const PeriodShowFields = [
  <NumberField key="id" source="id"/>,
  <TextField key="title" source="title"/>,
  <NumberField key="duration" source="duration"/>,
  <TextField key="aprValue" source="aprValue"/>,
  <FunctionField key="allowedRefMinAmount"
                 label="allowedRefMinAmount"
                 render={(record: any) => `${record.allowedRefMinAmount} ${record.refAsset}`}/>,
  <DateField key="createdAt" source="createdAt" showTime/>,
  <DateField key="updatedAt" source="updatedAt" showTime/>,
]

export const PeriodShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        {PeriodShowFields}
      </SimpleShowLayout>
    </Show>
  )
}