import { transformSystemSettingsEdit, validateSystemSettingsEdit } from '../system-settings';
import { DateInput, Edit, SimpleForm, TextInput } from 'react-admin';
import { CustomToolbar } from '../toolbar';

export const SocialLinksEdit=(props: any)=>{
  return (
    <Edit
      transform={transformSystemSettingsEdit}
    >
      <SimpleForm warnWhenUnsavedChanges toolbar={<CustomToolbar saveButton={true}/>}
                  validate={validateSystemSettingsEdit}>
        <TextInput label="Link id" source="id" disabled/>
        <TextInput label="Link code" source="code" disabled/>
        <TextInput label="Link description" source="description"/>
        <TextInput label="Link value" source="value"/>
        <DateInput name="createdAt" source="createdAt" disabled/>
        <DateInput name="updatedAt" source="updatedAt" disabled/>
      </SimpleForm>
    </Edit>
  )
}