import { DateInput, TextInput, useListContext } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from 'react';

const formDefaultValues = {
  id: '',
  name: '',
};

export const CardFilterForm = () => {
  const { displayedFilters, filterValues, setFilters, hideFilter } = useListContext();

  const form = useForm({
    defaultValues: filterValues,
  });
  useEffect(() => {
    // reset the entire form after component mount or form defaultValues is ready
    form.reset(formDefaultValues);
  }, [form.reset]);

  if (!displayedFilters.main) return null;

  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      // @ts-ignore
      setFilters(values);
    } else {
      hideFilter('main');
    }
  };
  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="flex-end" mb={1}>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="id"
              label="Search by Id"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="accountNumber"
              label="Search by account number (numeric)"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="cardNumber"
              label="Search by card number"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="referenceId"
              label="Search by SAN"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="name"
              label="Search by name"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="email"
              label="Search by email"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <DateInput label="Date From" helperText={false} name="dateFrom" source="dateFrom" defaultValue=""/>
          </Box>
          <Box component="span" mr={2}>
            <DateInput label="Date To" helperText={false} name="dateTo" source="dateTo" defaultValue=""/>
          </Box>
          <Box component="span" mr={2} mb={1.5}>
            <Button variant="outlined" color="primary" type="submit">
              Filter
            </Button>
          </Box>
          <Box component="span" mb={1.5}>
            <Button variant="outlined" onClick={resetFilter}>
              Close
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};
