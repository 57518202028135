import { Edit, NumberInput, SelectInput, SimpleForm } from 'react-admin'
import { LtvGradationChoices } from './constants';
import { CustomToolbar } from '../../toolbar';

export const LtvEdit =()=>{

  return(
    <Edit redirect="list">
      <SimpleForm warnWhenUnsavedChanges toolbar={<CustomToolbar saveButton={true}/>}>
        <SelectInput name="type" source="type" choices={LtvGradationChoices}/>
        <NumberInput name="minValue" label="Minimal value in %" source="minValue" min={1}/>
        <NumberInput name="maxValue" label="Maximal value in %" source="maxValue"  min={2}/>
      </SimpleForm>
    </Edit>
  )
}