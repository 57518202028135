import { CreateButton, EditButton, List, Pagination, TextField } from 'react-admin';
import { GridComponent } from '../grid/grid-component';
import { ListActions } from '../list-actions';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';

const SocialLinksListFields = ()=>{
  return [
    <TextField key="id" label="Link id" source="id"/>,
    <TextField key="code" label="Link code" source="code"/>,
    <TextField key="description" label="Link description" source="description"/>,
    <TextField key="value" label="Link value" source="value"/>,
    <EditButton key="editBtn"/>,
  ]
}

export const SocialLinksList =(props:any)=>{
  return(
    <List
      actions={<ListActions createButton={<CreateButton/>}/>}
      filter={{ refSocialLinks: true }}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <GridComponent gridChildren={SocialLinksListFields()} bulkActionButtons={false} rowClick="show"/>
    </List>
  )
}