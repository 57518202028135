import {
  ArrayInput,
  Create,
  DateTimeInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput
} from 'react-admin';
import {
  CreateProgramValidate,
  ReferralProgramAccessibilityAssetChoices, ReferralProgramState,
  ReferralProgramStateChoices,
  ReferralProgramUserTypeChoices, ReferralRewardAssetChoices
} from './edit';

export const TransformCreateFormData = (data: any) => {
  const { rewardAssetChoices, ...rest } = data;
  const assetChoices = rewardAssetChoices.reduce((acc:string[],v:any)=>[...acc,v.rewardAsset],[])
  return {
    ...rest,
    rewardAssetChoices:assetChoices,
    rewardFollowerAmounts: rewardAssetChoices.reduce((acc:string[],v:any)=>[...acc,v.rewardFollowerAmount],[]),
    rewardOwnerAmounts:rewardAssetChoices.reduce((acc:string[],v:any)=>[...acc,v.rewardOwnerAmount],[]),
  }
}


export const ReferralProgramCreate = () => {
  return (
    <Create title="Create Referral Program" redirect="list" transform={TransformCreateFormData}>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput name="title" source="title" validate={CreateProgramValidate.title}/>
        <TextInput name="description" source="description" validate={CreateProgramValidate.description}/>
        <SelectInput name="state" source="state" choices={ReferralProgramStateChoices}
                     validate={CreateProgramValidate.state} defaultValue={ReferralProgramState.DEACTIVATED}/>
        <SelectInput name="userTypeAffected" source="userTypeAffected" choices={ReferralProgramUserTypeChoices}
                     validate={CreateProgramValidate.userTypeAffected} defaultValue={ReferralProgramUserTypeChoices[2].id}/>
        <SelectInput name="accessibilityAsset" source="accessibilityAsset"
                     choices={ReferralProgramAccessibilityAssetChoices}
                     validate={CreateProgramValidate.accessibilityAsset} defaultValue={ReferralProgramAccessibilityAssetChoices[0].id}/>
        <TextInput name="accessibilityAmount" source="accessibilityAmount" validate={CreateProgramValidate.amount}/>
        <TextInput disabled name="maxActivationsAllowed" source="maxActivationsAllowed" defaultValue="infinity"/>
        <ArrayInput name="rewardAssetChoices" source="rewardAssetChoices" validate={CreateProgramValidate.rewardAssetChoices} defaultValue={[{rewardAsset:'',rewardFollowerAmount:'',rewardOwnerAmount:''}]}>
          <SimpleFormIterator >
            <SelectInput source="rewardAsset" choices={ReferralRewardAssetChoices} validate={CreateProgramValidate.choices}/>
            <TextInput source="rewardFollowerAmount" validate={CreateProgramValidate.amount}
                       helperText={false}/>
            <TextInput source="rewardOwnerAmount" validate={CreateProgramValidate.amount}
                       helperText={false}/>
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput name="rewardTriggerAsset" source="rewardTriggerAsset"
                     choices={ReferralProgramAccessibilityAssetChoices}
                     defaultValue={ReferralProgramAccessibilityAssetChoices[0].id}
                     validate={CreateProgramValidate.rewardTriggerAsset}/>
        <TextInput name="rewardTriggerAmount" source="rewardTriggerAmount" validate={CreateProgramValidate.amount}/>
        <DateTimeInput name="startDateAt" source="startDateAt" defaultValue={new Date()}/>
      </SimpleForm>
    </Create>
  )
};