import {
  Datagrid,
  useListContext
} from 'react-admin';

export const GridComponent = (props: any) => {
  const { gridChildren, isGridClick, ...otherProps } = props;
  const { data, total, isLoading, isFetching, sort } = useListContext(otherProps);
  if (isLoading || isFetching) return null;
  return (
    <Datagrid {...otherProps} children={gridChildren} data={data} total={total} sort={sort} />
  )
}