import { useRecordContext } from "react-admin";
import { config } from "../../config";


export const AssetIcon = (props: any) => {
  const record = useRecordContext();
  if (!record) return null;
  const url = `${config.API_HOST}/assets/${record.shortcode}/icons/default?format=png&size=34`
  // console.log('url')
  // console.log(url)
  return (<img alt="image" src={url} title={url} style={{ borderRadius: "50%", backgroundColor: record.color }}/>)
}