import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const SystemSettingsShow=(props:any)=>{
  return (
    <Show>
      <SimpleShowLayout>
        <TextField label="Setting id" source="id"/>
        <TextField label="Setting code" source="code"/>
        <TextField label="Setting description" source="description"/>
        <TextField label="Setting value" source="value"/>
        <DateField label="Created at" source="createdAt"/>
        <DateField label="Updated at" source="updatedAt"/>
      </SimpleShowLayout>
    </Show>
  );
}